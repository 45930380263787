import Master from "../../layouts/master";
import { useState } from "react";
import Fragment from "./components/fragment";
import Sinki from "./components/sinki";
import { useLangContext } from "../../../context";
export default function Treasure(props) {
  const { langValue } = useLangContext();
  const [selectedCategory, setSelectCategory] = useState("treasure");

  return (
    <Master>
      <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
        <div class="treasures flex h-screen flex-col">
          <div class="flex flex-1 flex-col overflow-hidden">
            <div class="bg-black bg-opacity-20 p-3 text-xl tracking-wider text-white">
              {langValue === "ja" && <>トレジャー</>}
              {langValue === "en" && <>Treasures</>}
            </div>

            <div class="overflow-auto pb-28">
              <div class="mx-2 mb-8 mt-4 text-white">
                <ul class="flex border-b border-white text-white">
                  <li
                    class={`flex-1 p-3 text-center ${selectedCategory === "treasure" && "bg-white bg-opacity-10"}`}
                    onClick={() => setSelectCategory("treasure")}
                  >
                    {langValue === "ja" && <>フラグメント</>}
                    {langValue === "en" && <>Fragments</>}
                  </li>
                  <li
                    class={`flex-1 p-3 text-center ${selectedCategory === "sinki" && "bg-white bg-opacity-10"}`}
                    onClick={() => setSelectCategory("sinki")}
                  >
                    SINKI
                  </li>
                </ul>
                {selectedCategory === "treasure" && <Fragment />}
                {selectedCategory === "sinki" && <Sinki />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
