import region_05 from "../images/region_thumbnail/region_05.png";
import region_0 from "../images/region_thumbnail/region_0.png";

import fragment0 from "../images/fragment/ordina/1.png";
import fragment1 from "../images/fragment/ordina/2.png";
import fragment2 from "../images/fragment/ordina/3.png";
import fragment3 from "../images/fragment/ordina/4.png";
import fragment4 from "../images/fragment/ordina/5.png";
import fragment5 from "../images/fragment/ordina/6.png";
import fragment6 from "../images/fragment/ordina/7.png";

import fragment7 from "../images/fragment/kadav/1.png";
import fragment8 from "../images/fragment/kadav/2.png";
import fragment9 from "../images/fragment/kadav/3.png";
import fragment10 from "../images/fragment/kadav/4.png";
import fragment11 from "../images/fragment/kadav/5.png";
import fragment12 from "../images/fragment/kadav/6.png";
import fragment13 from "../images/fragment/kadav/7.png";

import sinki1 from "../images/fragment/ordina/8.png";

export const MAX_ATK = 110;
export const MAX_DEF = 110;
export const MAX_LUK = 100;

export const fragmentImageIds = {
  0: fragment0,
  1: fragment1,
  2: fragment2,
  3: fragment3,
  4: fragment4,
  5: fragment5,
  6: fragment6,

  7: fragment7,
  8: fragment8,
  9: fragment9,
  10: fragment10,
  11: fragment11,
  12: fragment12,
  13: fragment13,
};

const _fragmentList = [
  {
    id: 0,
    name_ja: "フェルノ石",
    name_en: "Ferno Stone",
    type: "Stone",
  },
  {
    id: 1,
    name_ja: "ラーズ石",
    name_en: "Raz Stone",
    type: "Stone",
  },
  {
    id: 2,
    name_ja: "ゴルディア石",
    name_en: "Goldia Stone",
    type: "Stone",
  },
  {
    id: 3,
    name_ja: "ナチュラ石",
    name_en: "Natura Stone",
    type: "Stone",
  },
  {
    id: 4,
    name_ja: "テラーン石",
    name_en: "Terrarn Stone",
    type: "Stone",
  },
  {
    id: 5,
    name_ja: "アイゾーマ石",
    name_en: "Aizoma Stone",
    type: "Stone",
  },
  {
    id: 6,
    name_ja: "シオン石",
    name_en: "Sion Stone",
    type: "Stone",
  },
];

const _fragmentInfos = () => {
  const obj = {};
  for (let i = 0; i < _fragmentList.length; i++) {
    const fragment = _fragmentList[i];
    obj[fragment.id] = fragment;
    obj[fragment.id].image = fragmentImageIds[fragment.id];
  }
  return obj;
};

export const fragmentInfos = _fragmentInfos();

const _mapLists = [
  {
    id: "dagur_cave",
    id_number: "0.5",
    name_ja: "ダグー洞窟",
    name_en: "Dagur Cave",
    image: region_05,
    status: "public",
    text_ja:
      "ノアの北西、オーゼ連峰を流れる川の急流に閉ざされし洞窟。\n川の水量が少ない季節のみ入口が現れる。\n洞窟の青く澄んだ水底には、何処からか流れ着いた種や武具が沈んでいる。",
    text_en:
      "Northwest of Noa, a cave closed off by the rapids of the river flowing through the Ohze mountains. \nOnly during the season when the river is low, the entrance shows itself.\nWeapons, Armor, and Seeds, drifted down from elsewhere can be found at the clear, blue bottom of the cave.",
    regionId: 0,
    isTreasure: false,
    treasure: {
      fragmentIds: [],
    },
    rewards: { treasure: false, sword: true, shield: true, seed: true },
    x: 1100,
    y: 830,
    pinMap: true,
  },
  {
    id: "ordina_planes",
    id_number: "01",
    name_ja: "オルディナ平原",
    name_en: "Ordina Planes",
    image: region_0,
    status: "public",
    text_ja:
      "ノアの北、巨大な世界樹のさらに北方に広がる大平原。\n街から近いため、冒険初心者が最初の腕試しに訪れることが多い。\nビギナーズラック？ フラグメントを見つけた者も！",
    text_en:
      "The great plains stretch further north of the giant SEKAIJU, which is in the north of Noa.\nBecause of being close to the city, it is often visited by rookie adventurers to test their skills for the first time.\nBeginner's luck? Some even found Fragments!",
    regionId: 1,
    isTreasure: true,
    treasure: {
      fragmentIds: [0, 1, 2, 3, 4, 5, 6],
      image: sinki1,
      name_en: "FRAGSTN",
      name_ja: "フラグストン",
      sinkiId: 0,
      creatable: true,
    },
    rewards: { treasure: true, sword: true, shield: true, seed: true },
    x: 1330,
    y: 515,
    pinMap: true,
  },
];

export const mapLists = _mapLists;

const _regionIdToInfos = () => {
  const obj = {};
  for (let index = 0; index < _mapLists.length; index++) {
    obj[_mapLists[index].regionId] = _mapLists[index];
  }
  return obj;
};

export const regionIdToInfos = _regionIdToInfos();

const _mapIdToInfos = () => {
  const obj = {};
  for (let index = 0; index < _mapLists.length; index++) {
    obj[_mapLists[index].id] = _mapLists[index];
  }
  return obj;
};

export const mapIdToInfos = _mapIdToInfos();

const _mapIdToExploreNumbers = () => {
  const objJa = {
    attack: "攻撃",
    defence: "守備",
  };
  const objEn = {
    attack: "Attack",
    defence: "Defence",
  };
  for (let index = 0; index < _mapLists.length; index++) {
    objJa[_mapLists[index].id] = `探索${_mapLists[index].id_number}`;
    objEn[_mapLists[index].id] = `Exploration ${_mapLists[index].id_number}`;
  }
  return {
    ja: objJa,
    en: objEn,
  };
};

export const mapIdToExploreNumbers = _mapIdToExploreNumbers();
