import { useGameContext } from "../../../../context";

export default function GameResult(props) {
  const { matchStatus } = useGameContext();

  return (
    <>
      {/* <!-- WIN / LOSE --> */}
      <div class="absolute top-0 h-full w-full">
        {matchStatus === "WIN" ? (
          <>
            <div class="h-full w-full bg-black bg-opacity-50"></div>
            <img
              src="/images/battle/win_bg.svg"
              alt=""
              class="battle__result-bg absolute left-1/2 top-1/2 mt-3 w-full -translate-x-1/2 -translate-y-1/2 transform"
            />

            <div class="absolute left-1/2 top-1/2 mt-3 -translate-x-1/2 -translate-y-1/2 transform">
              <img
                src="/images/battle/win_text.svg"
                alt=""
                class="battle__result-text-win"
              />
            </div>
          </>
        ) : (
          <>
            <div class="h-full w-full bg-black bg-opacity-50"></div>
            <img
              src="/images/battle/lose_bg.svg"
              alt=""
              class="battle__result-bg absolute left-1/2 top-1/2 mt-3 w-full -translate-x-1/2 -translate-y-1/2 transform"
            />
            <div class="absolute left-1/2 top-1/2 mt-3 -translate-x-1/2 -translate-y-1/2 transform">
              <img
                src="/images/battle/lose_text.svg"
                alt=""
                class="battle__result-text-lose"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
