import { useLangContext } from "../../../../context";

export default function MdBox(props) {
  const { langValue } = useLangContext();

  return (
    <div className="md-box">
      <div className="md-content">
        <div className="md-header">
          <div className="title">{props.title}</div>
        </div>
        <div className="md-footer">
          <div className="btn" onClick={props.onYes}>
            {langValue === "ja" && <>はい</>}
            {langValue === "en" && <>Yes</>}
          </div>
          <div className="btn" onClick={props.onNo}>
            {langValue === "ja" && <>いいえ</>}
            {langValue === "en" && <>No</>}
          </div>
        </div>
      </div>
    </div>
  );
}
