import { useEffect, useMemo, useState } from "react";
import ItemSeed from "./components/item-seed";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../context";
import ItemSeedSelectCharacter from "./components/item-seed-select-character";
import { sound_effect } from "../../common/SoundEffect";
import Web3 from "web3/dist/web3.min";
import ItemSeedSelectSeed from "./components/item-seed-select-seed";
import ItemSeedSelectConfirm from "./components/items-seed-select-confirm";
import ItemSeedSelectFinish from "./components/item-seed-select-finish";
import ItemSeedSynthesis from "./components/item-seed-synthesis";
import Loading from "../../common/loading";

export default function Seed(props) {
  const { selectedCategory } = props;
  const { isekaiBattleStatusManager } = useGameContext();
  const { account, Web3Api, chainId, getFeeData } = useApiContext();
  const { langValue } = useLangContext();
  const [isOpenSeeds, setIsOpenSeeds] = useState(false);
  const [isOpenSeedsSynthesis, setIsOpenSeedsSynthesis] = useState(false);
  const [selectedSeed, setSelectedSeed] = useState();
  const [selectedCharacter, setSelectedCharacter] = useState();
  const [openSeedCategory, setOpenSeedCategory] = useState("character");
  const [openSeedConfirm, setOpenSeedConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usedSeedSuccess, setUsedSeedSuccess] = useState(false);

  const openSeeds = (seed) => {
    sound_effect("positive");
    setIsOpenSeeds(true);
    setSelectedSeed(seed);
  };

  const openSeedsSynthesis = (seed) => {
    sound_effect("positive");
    setIsOpenSeedsSynthesis(true);
    setSelectedSeed(seed);
    setOpenSeedConfirm(false);
  };

  const closeSeeds = (reload = false) => {
    if (reload === true) {
      window.location.reload();
      return;
    }
    sound_effect("negative");
    setIsOpenSeeds(false);
    setSelectedSeed(null);
    setOpenSeedConfirm(false);
    setUsedSeedSuccess(false);
    setSelectedCharacter(null);
    setIsOpenSeedsSynthesis(false);
  };

  const [useSeedPriceBN, setUseSeedPriceBN] = useState(null);

  useEffect(() => {
    if (!selectedCharacter) {
      setUseSeedPriceBN(null);
      return;
    }
    (async () => {
      const price = await isekaiBattleStatusManager.methods
        .getUseSeedPrice(selectedCharacter.id)
        .call({ from: account });
      setUseSeedPriceBN(price);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCharacter]);

  const ethPrice = useMemo(() => {
    if (useSeedPriceBN) {
      return Web3.utils.fromWei(useSeedPriceBN, "ether");
    } else {
      return "???";
    }
  }, [useSeedPriceBN]);

  const onUsedSeedConfirm = async () => {
    setLoading(true);
    if (loading) return;
    const tokenId = selectedCharacter && selectedCharacter.id;
    if (tokenId == null) {
      setLoading(false);
      throw new Error("Missing tokenId");
    }
    const seedId = selectedSeed && selectedSeed.id;
    if (seedId == null) {
      setLoading(false);
      throw new Error("Missing seedId");
    }
    if (useSeedPriceBN == null) {
      setLoading(false);
      throw new Error("Missing price");
    }

    try {
      const methodData = isekaiBattleStatusManager.methods.useSeed(
        tokenId,
        seedId,
      );
      const { executionGasFee, maxPriorityFeePerGas } = await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
        })
        .on("confirmation", async (confirmationNumber) => {
          if (confirmationNumber >= 2) {
            await Web3Api.account.usedSeed({
              account,
              chainId: parseInt(chainId),
            });
            setOpenSeedConfirm(false);
            setUsedSeedSuccess(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <>
      {!isOpenSeeds && !isOpenSeedsSynthesis && (
        <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
          <div class="bg-gradient-to-r from-[#648B93] to-[#6F8A93] p-3 text-xl tracking-wider text-white">
            {langValue === "ja" && <>アイテム</>}
            {langValue === "en" && <>Items</>}
          </div>

          <div class="flex flex-col overflow-hidden">
            <ul class="flex">
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "weapons" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("weapons")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>武器</>}
                    {langValue === "en" && <>Weapons</>}
                  </div>
                </button>
              </li>
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "armor" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("armor")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>防具</>}
                    {langValue === "en" && <>Armor</>}
                  </div>
                </button>
              </li>
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "seeds" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("seeds")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>種</>}
                    {langValue === "en" && <>Seeds</>}
                  </div>
                </button>
              </li>
            </ul>
            <div class="overflow-scroll p-4 text-black">
              <ItemSeed
                selectedCategory={selectedCategory}
                openSeeds={openSeeds}
                openSeedsSynthesis={openSeedsSynthesis}
              />
            </div>
          </div>
        </div>
      )}

      {isOpenSeeds && (
        <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
          {openSeedCategory === "character" ? (
            <ItemSeedSelectCharacter
              ethPrice={ethPrice}
              closeSeeds={closeSeeds}
              setSelectedCharacter={setSelectedCharacter}
              selectedCharacter={selectedCharacter}
              selectedSeed={selectedSeed}
              setOpenSeedCategory={setOpenSeedCategory}
              onConfirm={() => setOpenSeedConfirm(true)}
            />
          ) : (
            <ItemSeedSelectSeed
              ethPrice={ethPrice}
              closeSeeds={closeSeeds}
              setSelectedSeed={setSelectedSeed}
              selectedCharacter={selectedCharacter}
              selectedSeed={selectedSeed}
              setOpenSeedCategory={setOpenSeedCategory}
              onConfirm={() => setOpenSeedConfirm(true)}
            />
          )}
          {openSeedConfirm && selectedCharacter && selectedSeed && (
            <ItemSeedSelectConfirm
              ethPrice={ethPrice}
              onClose={() => setOpenSeedConfirm(false)}
              onConfirm={onUsedSeedConfirm}
              selectedCharacter={selectedCharacter}
              selectedSeed={selectedSeed}
            />
          )}
          {usedSeedSuccess && selectedCharacter && selectedSeed && (
            <ItemSeedSelectFinish
              selectedCharacter={selectedCharacter}
              selectedSeed={selectedSeed}
              onClose={() => closeSeeds(true)}
            />
          )}
        </div>
      )}

      {isOpenSeedsSynthesis && (
        <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
          <ItemSeedSynthesis
            setSelectedCharacter={setSelectedCharacter}
            selectedCharacter={selectedCharacter}
            setSelectedSeed={setSelectedSeed}
            selectedSeed={selectedSeed}
            closeSeeds={closeSeeds}
          />
        </div>
      )}

      {loading && <Loading />}
    </>
  );
}
