import settings from "../../../../config/settings";
import { useItemContext } from "../../../../context";

import ico_opensea_dark from "../../../../images/ico_opensea_dark.svg";
import { useMemo, useState } from "react";
import ItemSeedDetail from "./item-seed-detail";
import { sound_effect } from "../../../common/SoundEffect";
import { seedImages } from "../../../../data/itemInfo";

function getSeedImage(type) {
  return seedImages[type];
}

export default function ItemSeed(props) {
  const { seeds } = useItemContext();
  const [selectedSeed, setSelectedSeed] = useState();

  const items = useMemo(() => {
    const sortedSeeds = seeds.sort(
      (a, b) => Number(a.token_id) - Number(b.token_id),
    );
    return sortedSeeds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeds]);

  const seedCount = useMemo(() => {
    return seeds.reduce((sum, element) => {
      return sum + Number(element.amount);
    }, 0);
  }, [seeds]);

  const onSelect = (item) => {
    sound_effect("click");
    setSelectedSeed(item);
  };

  const onCancel = () => {
    sound_effect("nagative");
    setSelectedSeed(null);
  };

  return (
    <div>
      <h2 class="flex items-center justify-between">
        <span class="flex gap-2">{seedCount > 0 && seedCount}</span>
        <span>
          <a href={settings.opensea.IsekaiBattleSeeds} target="_blank">
            <img src={ico_opensea_dark} alt="" class="w-4 opacity-70" />
          </a>
        </span>
      </h2>
      <ul class="mt-4 grid grid-cols-5 items-start gap-2">
        {items.map((item, key) => {
          return (
            <li
              class="relative rounded-lg bg-gradient-to-b from-[#9A733F] to-[#D0A265]"
              key={key}
              onClick={() => onSelect(item)}
            >
              <img
                src={getSeedImage(item.type)}
                alt=""
                class="w-18 -mb-1 -mt-1 pb-2"
              />
              <div class="absolute bottom-0 flex w-full justify-between rounded-b-lg bg-[#50350A] px-2 text-xs">
                <span class="text-yellow-300">Lv {item.lv}</span>
                <span class="text-white">{item.amount}</span>
              </div>
            </li>
          );
        })}
      </ul>
      {selectedSeed && (
        <ItemSeedDetail
          seedImage={getSeedImage(selectedSeed.type)}
          openSeeds={props.openSeeds}
          openSeedsSynthesis={props.openSeedsSynthesis}
          selectedSeed={selectedSeed}
          onClose={onCancel}
        />
      )}
    </div>
  );
}
