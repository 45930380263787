const address = {
  901: {
    IsekaiBattleContractRegistry: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
    IsekaiBattleCharacterStorage: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
    IsekaiBattleSafe: "0x82e01223d51Eb87e16A03E24687EDF0F294da6f1",
    IsekaiBattle: "0xc351628EB244ec633d5f21fBD6621e1a683B1181",
    IsekaiBattleMinter: "0xB0D4afd8879eD9F52b28595d31B441D079B2Ca07",
    IsekaiBattleArmor: "0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6",
    IsekaiBattleWeapon: "0x172076E0166D1F9Cc711C77Adf8488051744980C",
    IsekaiBattleSeeds: "0x720472c8ce72c2A2D711333e064ABD3E6BbEAdd3",
    TrashIsekaiBattleItems: "0xF32D39ff9f6Aa7a7A64d7a4F00a54826Ef791a55",
    IsekaiBattleBag: "0x5FeaeBfB4439F3516c74939A9D04e95AFE82C4ae",
    Random: "0xfcDB4564c18A9134002b9771816092C9693622e3",
    Staking: "0x02b0B4EFd909240FCB2Eb5FAe060dC60D112E3a4",
    IsekaiBattleStake: "0xa6e99A4ED7498b3cdDCBB61a6A607a4925Faa1B7",
    IsekaiBattleMagicCircle: "0xD49a0e9A4CD5979aE36840f542D2d7f02C4817Be",
    IsekaiBattleStatusManager: "0x66F625B8c4c635af8b74ECe2d7eD0D58b4af3C3d",
  },
  31337: {
    IsekaiBattleContractRegistry: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
    IsekaiBattleCharacterStorage: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
    IsekaiBattleSafe: "0x82e01223d51Eb87e16A03E24687EDF0F294da6f1",
    IsekaiBattle: "0xc351628EB244ec633d5f21fBD6621e1a683B1181",
    IsekaiBattleMinter: "0xB0D4afd8879eD9F52b28595d31B441D079B2Ca07",
    IsekaiBattleArmor: "0xdbC43Ba45381e02825b14322cDdd15eC4B3164E6",
    IsekaiBattleWeapon: "0x172076E0166D1F9Cc711C77Adf8488051744980C",
    IsekaiBattleSeeds: "0x720472c8ce72c2A2D711333e064ABD3E6BbEAdd3",
    TrashIsekaiBattleItems: "0xF32D39ff9f6Aa7a7A64d7a4F00a54826Ef791a55",
    IsekaiBattleBag: "0x5FeaeBfB4439F3516c74939A9D04e95AFE82C4ae",
    Random: "0xfcDB4564c18A9134002b9771816092C9693622e3",
    Staking: "0x02b0B4EFd909240FCB2Eb5FAe060dC60D112E3a4",
    IsekaiBattleStake: "0xa6e99A4ED7498b3cdDCBB61a6A607a4925Faa1B7",
    IsekaiBattleMagicCircle: "0xD49a0e9A4CD5979aE36840f542D2d7f02C4817Be",
    IsekaiBattleStatusManager: "0x66F625B8c4c635af8b74ECe2d7eD0D58b4af3C3d",
    IsekaiBattleClash: "0xB06c856C8eaBd1d8321b687E188204C1018BC4E5",
    IsekaiBattleFragment: "0x02df3a3F960393F5B349E40A599FEda91a7cc1A7",
    IsekaiBattleSinki: "0xaC9fCBA56E42d5960f813B9D0387F3D3bC003338",
    Airdrop: "0xE8addD62feD354203d079926a8e563BC1A7FE81e",
    GameDataAggregator: "0xD6b040736e948621c5b6E0a494473c47a6113eA8",
  },
  11155420: {
    IsekaiBattleContractRegistry: "0x2AAc04cF7006974A0f4f467255520117bB19bCB2",
    IsekaiBattleCharacterStorage: "0x822E7D9392eb8f470eA7D1e0BEEC8cf52c1524ec",
    IsekaiBattleSafe: "0x917C11B0D364C589b0ebACd62d2D3Cd16F676234",
    IsekaiBattle: "0xFaFA78B98622F823F37d9F550C2ee1Aa6529eE53",
    IsekaiBattleMinter: "0x51Cb10B5f37d715aa5240d8da911Fb4878161D4c",
    IsekaiBattleArmor: "0xbF101BE2f8f60eaf672C04027064BD365f04f365",
    IsekaiBattleWeapon: "0x1937077e22B322b9CBF3C11d21E0259c3BC34299",
    IsekaiBattleSeeds: "0xC30417EEf7aAae2C2d2447acdBfaABc53a3c2DBB",
    TrashIsekaiBattleItems: "0x56C48019D250AA7d4776678be229B524deE8f34e",
    IsekaiBattleBag: "0xacC2BFB2D45dBC9C538c9452DbC8DDAdF66601E8",
    Random: "0x762200170cAC2b55AA33a8694dDe5E10f7289204",
    Staking: "0xE5De24F0640F8d679Ca69DFd5CC9EEb463Ae8821",
    IsekaiBattleStake: "0x3ae3dE2aF95EFbE9dd6d89CCBE98b6D86AD7e2d8",
    IsekaiBattleMagicCircle: "0x5b7aceF213A9dC664DAeD4bb1ec6707F89D9dDA4",
    IsekaiBattleStatusManager: "0x574AD182Cf69548221C5F0ed45878aBD4046C637",
    IsekaiBattleClash: "0x66985a8b28d3F383F40FF5137d1896F3b47F7E8d",
    IsekaiBattleFragment: "0xbD266a2c9e3839d4544aB404d37A22fb1875B6A8",
    IsekaiBattleSinki: "0xc0afdEb9D8A505930905F17AE6C1D499AB9e9B63",
    Airdrop: "0x0Aa93870058d3995D67F5D3080969b42e100FE68",
    GameDataAggregator: "0x52b4bDe3C7D98Cdd261641a80104492C11E296F5",
  },
};

module.exports = {
  address,
};
