import Bow from "../images/items/weapons/bow.png";
import Fists from "../images/items/weapons/knuckle.png";
import Staff from "../images/items/weapons/wand.png";
import Sword from "../images/items/weapons/sword.png";
import TwoHand from "../images/items/weapons/halberd.png";

import HeavyArmor from "../images/items/armor/platearmor_silver.png";
import LightArmor from "../images/items/armor/cloth_blue.png";
import Robe from "../images/items/armor/robe_white.png";

import DEF from "../images/seeds/defence.png";
import ATK from "../images/seeds/power.png";
import LUK from "../images/seeds/luck.png";

export const weaponImages = {
  Bow,
  Fists,
  Staff,
  Sword,
  TwoHand,
};

export const armorImages = {
  HeavyArmor,
  LightArmor,
  Robe,
};

export const seedImages = {
  DEF,
  ATK,
  LUK,
};
