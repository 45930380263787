import { useEffect } from "react";

export default function useInterval(interval, callback) {
  useEffect(() => {
    const timer = setInterval(() => {
      callback();
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [interval, callback]);
}
