import { useVolumeContext } from "../../context";

import se_positive from "../../sound/1.mp3";
import se_negative from "../../sound/2.mp3";
import se_click from "../../sound/3.mp3";
import se_claim from "../../sound/4.mp3";
import se_character_set from "../../sound/5.mp3";
import se_character_remove from "../../sound/6.mp3";
import se_formation_fix from "../../sound/7.mp3";

let vol, mute;

export function sound_effect(name) {
  if (!mute && Number(vol) > 0) {
    if (name === "positive") {
      const se = new Audio(se_positive);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "negative") {
      const se = new Audio(se_negative);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "click") {
      const se = new Audio(se_click);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "claim") {
      const se = new Audio(se_claim);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "character_set") {
      const se = new Audio(se_character_set);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "character_remove") {
      const se = new Audio(se_character_remove);
      se.volume = vol / 100;
      se.play();
    }
    if (name === "formation_fix") {
      const se = new Audio(se_formation_fix);
      se.volume = vol / 100;
      se.play();
    }
  }
}

export default function SoundEffect(props) {
  const { seVolume, isMute } = useVolumeContext();
  vol = seVolume;
  mute = isMute;
}
