import React, { useState, useEffect, useMemo, useRef } from "react";
// モバイル
import RegionList from "./RegionList";
import ItemFoundModal from "./ItemFoundModal";
// ============================

import Master from "../../layouts/master";
import {
  useApiContext,
  useFormationContext,
  useGameContext,
  useMapContext,
} from "../../../context";
import Fragment from "./Fragment";
import ItemFoundClaimed from "./ItemFoundClaimed";
import MapExplanation from "./MapExplanation";
import Map from "./Map";

export default function Explore(props) {
  const {
    isekaiBattleStake,
    isekaiBattleFragment,
    setBgmName,
    regionStakeInfos,
    setRegionStakeInfos,
    lastGameTime,
  } = useGameContext();
  const { account, user, Web3Api } = useApiContext();
  const { setSelectedMapObject } = useMapContext();
  /* eslint-disable no-unused-vars */
  const [claimed, setClaimed] = useState({
    status: false,
    weapon: [],
    armor: [],
    seed: [],
    fragment: [],
  });
  const [selectedRegion, setSelectedRegion] = useState(false);
  const [eventRegions, setEventRegions] = useState([]);

  const { savedFormation, getSavedFormationCharactersByAreaId } =
    useFormationContext();
  const [stakedCharacters, setStakedCharacters] = useState({
    dagur_cave: [],
    ordina_planes: [],
  });
  const [fragmentStolen, setFragmentStolen] = useState([]);
  const mapChildRef = useRef(null);

  useEffect(() => {
    (async () => {
      loadFragmentStolen();
      const _eventRegions = await Web3Api.game.getEvents();
      setEventRegions(_eventRegions);
    })();
  }, []);

  const loadFragmentStolen = async () => {
    const stolen = await user.get("fragmentStolen");
    setFragmentStolen((stolen ?? []).map((id) => Number(id)));
  };

  const removeFragmentStolen = async (id) => {
    fragmentStolen.splice(fragmentStolen.indexOf(id), 1);
    await user.set("fragmentStolen", fragmentStolen);
  };

  useEffect(() => {
    loadStakeInfo();
  }, [isekaiBattleStake]);

  const loadStakeInfo = async () => {
    if (isekaiBattleStake && isekaiBattleStake.methods) {
      const _regionStakeInfos = await isekaiBattleStake.methods
        .getAllRegionStakeInfo()
        .call({ from: account });
      setRegionStakeInfos(_regionStakeInfos);

      const stakedFormation = Object.entries(_regionStakeInfos)
        .reverse()
        .find(([, o]) => o.stakingCharacterCounts > 0);
      const keys = [
        "stakedFormation",
        "stakedClaimTimes",
        "lastGameTimes",
        "fragmentTimes",
      ];

      const values = [
        stakedFormation !== undefined,
        stakedFormation ? stakedFormation[1].claimTimes : 0,
        lastGameTime,
        stakedFormation !== undefined ? stakedFormation[1].fragmentTimes : 0,
      ];
      await user.setValues(keys, values);
    }
  };

  const activeEvent = useMemo(() => {
    let activeReg = false;
    const now = Math.round(Date.now() / 1000);
    for (let i = 0; i < eventRegions.length; i++) {
      const event = eventRegions[i];
      if (event.start < now && event.end > now) {
        activeReg = true;
        break;
      }
    }
    return activeReg;
  }, [eventRegions]);

  useEffect(() => {
    const staked = {};
    for (const regId of Object.keys(stakedCharacters)) {
      staked[regId] = getSavedFormationCharactersByAreaId(regId);
    }
    setStakedCharacters(staked);
  }, [savedFormation]);

  const onSelectedRegion = (status) => {
    setSelectedRegion(status);
    mapChildRef.current?.center();
  };
  return (
    <>
      <Master>
        {claimed.status && (
          <ItemFoundClaimed
            claimed={claimed}
            onClose={() =>
              setClaimed((prev) => ({
                status: false,
              }))
            }
            loadStakeInfo={loadStakeInfo}
          />
        )}
        {!claimed.status && (
          <div class="map flex h-screen flex-col overflow-hidden">
            <div class="relative flex-1 overflow-hidden">
              <ItemFoundModal
                regionStakeInfos={regionStakeInfos}
                isekaiBattleStake={isekaiBattleStake}
                isekaiBattleFragment={isekaiBattleFragment}
                switchBgm={setBgmName}
                setClaimed={setClaimed}
                activeEvent={activeEvent}
                loadStakeInfo={loadStakeInfo}
              />
              <RegionList setSelectedRegion={onSelectedRegion} />
              <Map
                ref={mapChildRef}
                stakedCharacters={stakedCharacters}
                setSelectedMapObject={setSelectedMapObject}
                setSelectedRegion={setSelectedRegion}
              />

              {activeEvent !== null && (
                <Fragment
                  fragmentStolen={fragmentStolen}
                  removeFragmentStolen={removeFragmentStolen}
                />
              )}
              {selectedRegion && (
                <MapExplanation
                  setSelectedRegion={setSelectedRegion}
                  fragmentStolen={fragmentStolen}
                  removeFragmentStolen={removeFragmentStolen}
                />
              )}
            </div>
          </div>
        )}
      </Master>
    </>
  );
}
