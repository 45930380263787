import { useMemo, useState } from "react";
import { useItemContext, useLangContext } from "../../../../context";
import SeedSelectItem from "./seed-select-item";
import btn_close from "../../../../images/btn_close.svg";
import ItemSeedItemInfo from "./item-seed-select-info";

export default function ItemSeedSelectSeed(props) {
  const { seeds } = useItemContext();
  const { langValue } = useLangContext();
  const [sort, setSort] = useState("");

  const items = useMemo(() => {
    if (sort) {
      return seeds.sort((a, b) => {
        if (sort === "lv") return b[sort] - a[sort];
        if (sort === a.type && sort === b.type) return b.lv - a.lv;
        if (sort === a.type) return -1;
        return 0;
      });
    }
    const sortedSeeds = seeds.sort(
      (a, b) => Number(a.token_id) - Number(b.token_id),
    );
    return sortedSeeds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seeds, sort]);

  const onChangeSort = (e) => {
    setSort(e.target.value);
  };

  return (
    <div className="items-seed-select flex h-screen flex-col">
      <div class="relative flex flex-1 flex-col overflow-auto">
        <div class="flex-1 overflow-hidden">
          <div class="z-5 absolute left-0 top-0 h-full w-full">
            <button class="absolute right-4 top-5" onClick={props.closeSeeds}>
              <img src={btn_close} alt="" />
            </button>
            <select
              class="absolute right-14 top-6 bg-transparent text-xs text-white"
              style={{
                background: "none",
                padding: 0,
                appearance: "none",
              }}
              onChange={onChangeSort}
            >
              <option value="lv">Lv</option>
              <option value="ATK">ATK</option>
              <option value="DEF">DEF</option>
              <option value="LUK">LUK</option>
            </select>
            <div class="px-3 py-5 text-xl tracking-wider text-white">
              {langValue === "ja" && <>種の使用</>}
              {langValue === "en" && <>Use seed</>}
            </div>

            <div
              class="overflow-auto"
              style={{
                height: "calc(100% - 68px)",
              }}
            >
              <ul class="m-2 mb-72 flex flex-col gap-1 text-white">
                {items.map((item, key) => {
                  return (
                    <SeedSelectItem
                      item={item}
                      key={key}
                      setSelectedSeed={props.setSelectedSeed}
                      selectedSeed={props.selectedSeed}
                      onClose={props.onClose}
                    />
                  );
                })}
              </ul>
            </div>

            <ItemSeedItemInfo
              selectedCharacter={props.selectedCharacter}
              selectedSeed={props.selectedSeed}
              ethPrice={props.ethPrice}
              closeSeeds={props.closeSeeds}
              onConfirm={props.onConfirm}
              setOpenSeedCategory={props.setOpenSeedCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
