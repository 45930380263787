import { useEffect, useState } from "react";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../../context";

import sinki1 from "../../../../images/fragment/ordina/8.png";
import settings from "../../../../config/settings";
import { sound_effect } from "../../../common/SoundEffect";
import { formatDateTime } from "../../../../util";
import Loading from "../../../common/loading";

export default function Sinki(props) {
  const { langValue } = useLangContext();
  const { Web3Api, chainName, targetChainId, account, getFeeData } =
    useApiContext();
  const { isekaiBattleSinki } = useGameContext();
  const [sinkies, setSinkies] = useState([]);
  const [soldSinkies, setSoldSinkies] = useState({
    items: [],
    soldAmount: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadSinki();
  }, []);

  const loadSinki = async () => {
    const data = await Web3Api.account.getNFTsForContract({
      token_address: settings.addresses.IsekaiBattleSinki,
      address: account,
      chain: chainName(targetChainId),
    });
    const { result } = data;
    const list = await Promise.all(
      result.map(async (obj) => {
        const metadata = JSON.parse(obj.metadata);
        return {
          ...obj,
          name: metadata.attributes.find((o) => o.trait_type === "Name").value,
          metadata,
        };
      }),
    ).then((result) => {
      return result;
    });
    setSinkies(list);

    const soldData = await Web3Api.game.getSoldSinkies({
      account: account,
      page: 1,
      limit: 50,
    });
    setSoldSinkies({
      items: soldData.result,
      soldAmount: soldData.pagination.total * 0.005,
    });
  };

  const onClickSell = async (ids) => {
    try {
      if (loading) return;
      sound_effect("click");
      setLoading(true);
      let receipted = false;

      const methodData = isekaiBattleSinki.methods.sellSinki(ids);
      const { executionGasFee, maxPriorityFeePerGas } = await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          gasLimit,
        })
        .on("receipt", async function (receipt) {
          console.log(receipt);
          for (const [name, log] of Object.entries(receipt.events)) {
            if (name === "SoldSinki" && !receipted) {
              receipted = true;
              await Web3Api.game.createSoldSinki({
                tx: log.transactionHash,
                account: log.returnValues.account,
                tokenIds: log.returnValues.tokenIds,
                amount: 0.005,
              });
              window.location.reload();
            }
          }
        })
        .once("confirmation", async () => {
          sound_effect("positive");
          setLoading(false);
        })
        .catch((x) => {
          console.error(x);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const onClickSellAll = async () => {
    const ids = sinkies.map((o) => o.token_id);
    onClickSell(ids);
  };

  return (
    <div class="mt-4">
      <div class="text-right">
        {sinkies.length > 0 && (
          <button
            class="rounded-full bg-white px-3 py-1 text-xs text-black"
            onClick={onClickSellAll}
          >
            {langValue === "ja" && <>一括売却</>}
            {langValue === "en" && <>Bulk sale</>}
          </button>
        )}
      </div>

      <ul class="mt-4 flex flex-col gap-1">
        {sinkies.map((sinki, key) => {
          return (
            <li
              class="flex items-center justify-between bg-black bg-opacity-30 p-2"
              key={key}
            >
              <div class="flex gap-2">
                <img src={sinki.metadata.image} alt="" class="w-8" />
                <div class="text-xs">
                  <span class="text-xl">0.005</span> ETH
                </div>
              </div>
              <button
                class="rounded-full bg-white px-3 py-1 text-xs text-black"
                onClick={() => onClickSell([sinki.token_id])}
              >
                {langValue === "ja" && <>売却</>}
                {langValue === "en" && <>Sale</>}
              </button>
            </li>
          );
        })}
      </ul>

      <div class="mt-8 text-right">
        <span class="text-sm">
          {langValue === "ja" && <>合計売却額</>}
          {langValue === "en" && <>Total sales </>}
        </span>
        <span class="mr-1 text-2xl">{soldSinkies.soldAmount}</span>
        <span class="mr-1 text-sm">ETH</span>
      </div>

      <ul class="mt-4 flex flex-col gap-1">
        {soldSinkies.items.map((item, key) => {
          return (
            <li
              class="flex items-center justify-between bg-black bg-opacity-30 p-2"
              key={key}
            >
              <div class="flex gap-2">
                <img src={sinki1} alt="" class="w-8" />
                <div class="text-xs">
                  <span class="text-xl">{item.amount}</span> ETH
                  <span class="ml-1">{formatDateTime(item.created_at)}</span>
                </div>
              </div>
              <div class="rounded-full bg-gray-500 px-3 py-1 text-xs text-white">
                {langValue === "ja" && <>売却済</>}
                {langValue === "en" && <>Sold</>}
              </div>
            </li>
          );
        })}
      </ul>

      {loading && <Loading />}
    </div>
  );
}
