import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./context";
import Page404 from "./components/Page404";
import Units from "./components/modules/units";
import Explore from "./components/modules/explore";
import Items from "./components/modules/items";
import Characters from "./components/modules/characters";
import Treasures from "./components/modules/treasures";
import Battle from "./components/modules/battle";
import Top from "./components/modules/toppage";
import Start from "./components/modules/toppage/start";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import settings from "./config/settings";
import BattleHistory from "./components/modules/battle/history";
import BattleLoading from "./components/modules/battle/battle-loading";
import Ranking from "./components/modules/battle/ranking";

// 1. Get projectId
const projectId = process.env.REACT_APP_WEB3_MODAL_APP_ID;

// 3. Create modal
const metadata = {
  name: "Isekai",
  description: "Isekai",
  url: "https://isekai-battle.xyz/",
  icons: ["https://isekai-battle.xyz/logo192.png"],
};

const chains = [
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
  {
    chainId: 10,
    name: "OP Mainnet",
    currency: "ETH",
    explorerUrl: "https://optimistic.etherscan.io",
    rpcUrl:
      "https://opt-mainnet.g.alchemy.com/v2/CcKSKBwV9zYAp6oJaf_ejYDc3gs28Tr8",
  },
  {
    chainId: 11155420,
    name: "OP Sepolia",
    currency: "ETH",
    explorerUrl: "https://sepolia-optimistic.etherscan.io",
    rpcUrl:
      "https://opt-sepolia.g.alchemy.com/v2/CcKSKBwV9zYAp6oJaf_ejYDc3gs28Tr8",
  },
  {
    chainId: 31337,
    name: "localhost",
    currency: "ETH",
    explorerUrl: "http://localhost",
    rpcUrl: "http://localhost:8545",
  },
];

const defaultChain = chains.find(
  (o) => o.chainId === Number(settings.defaultChainId),
);

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: settings.defaultChainId,
    enableEIP6963: true,
    enableInjected: false,
    enableCoinbase: false,
    // rpcUrl: '...' // used for the Coinbase SDK
  }),
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  includeWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  ],
  chains: [defaultChain],
  projectId,
});

function App() {
  return (
    <ContextProvider apiUrl={process.env.REACT_APP_API_URL}>
      <BrowserRouter>
        <div className="flex h-screen flex-col">
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="start" element={<Start />} />
            <Route path="explore" element={<Explore />} />
            <Route path="units" element={<Units />} />
            <Route path="characters" element={<Characters />} />
            <Route path="items" element={<Items />} />
            <Route path="treasures" element={<Treasures />} />
            <Route path="battle/:fragmentId" element={<Battle />} />
            <Route path="battle/history" element={<BattleHistory />} />
            <Route
              path="battle/history/:fragmentId"
              element={<BattleHistory />}
            />
            <Route path="battle/ranking" element={<Ranking />} />
            <Route path="battle/ranking/:fragmentId" element={<Ranking />} />
            <Route
              path="battle/:fragmentId/:defender"
              element={<BattleLoading />}
            />
            <Route path="/*" element={<Page404 />} /> {/* not foundの時 */}
          </Routes>
        </div>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
