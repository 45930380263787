import { useEffect, useMemo } from "react";
import { useLangContext } from "../../../../context";
import loading1 from "../../../../images/battle/loading1.jpg";
import loading2 from "../../../../images/battle/loading2.jpg";
import loading3 from "../../../../images/battle/loading3.jpg";
import { fragmentInfos } from "../../../../data/regionInfo";

const imagesLoading = [loading1, loading2, loading3];
export default function GameLoading(props) {
  const { langValue } = useLangContext();
  const { fragmentId } = props;

  useEffect(() => {
    setTimeout(() => {
      props.setStep(props.step + 1);
    }, 4000);
  }, []);

  const loading = useMemo(() => {
    return imagesLoading[Math.floor(Math.random() * 3)];
  }, []);

  return (
    <div class="battle flex flex-1 flex-col overflow-auto">
      <div class="flex h-full flex-col justify-center">
        <div class="text-center text-white">
          <img
            src={fragmentInfos[fragmentId]?.image}
            alt=""
            class="mx-auto w-20"
          />
          <p class="text-xs">
            {langValue === "ja" && <>争奪するフラグメント</>}
            {langValue === "en" && <>Target Fragment</>}
          </p>
          <p class="text-xl">
            {langValue === "ja" && <>{fragmentInfos[fragmentId]?.name_ja}</>}
            {langValue === "en" && <>{fragmentInfos[fragmentId]?.name_en}</>}
          </p>
        </div>

        <div class="mt-6 flex h-2/5 items-center justify-center overflow-hidden">
          <img
            src={loading}
            alt=""
            class="battle__loading-image h-full max-w-none"
          />
          <img
            src={loading}
            alt=""
            class="battle__loading-image h-full max-w-none"
          />
        </div>
        <div class="overflow-hidden">
          <div class="relative flex h-[25vh] items-center justify-between"></div>
        </div>
      </div>
    </div>
  );
}
