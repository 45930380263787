import { useEffect, useState } from "react";
import {
  useApiContext,
  useFormationContext,
  useGameContext,
} from "../../../context";
import { useParams } from "react-router-dom";
import Master from "../../layouts/master";
import GameLoading from "./components/game-loading";
import GameIntroduction from "./components/game-introduction";
import GameBattle1 from "./components/game-battle1";
import settings from "../../../config/settings";

export default function BattleLoading(props) {
  const { fragmentId, defender } = useParams();
  const { formation, getFormationCharacter } = useFormationContext();
  const { isekaiBattleStake } = useGameContext();
  const { Web3Api, chainName, targetChainId } = useApiContext();
  const [step, setStep] = useState(1);
  const [attack, setAttack] = useState([]);
  const [defense, setDefense] = useState([]);

  useEffect(() => {
    const atks = [];
    for (let i = 0; i < 5; i++) {
      const chara = getFormationCharacter("attack", i);
      atks.push(chara);
    }
    setAttack(atks);
  }, [formation]);

  useEffect(() => {
    if (defender && isekaiBattleStake.methods) {
      (async () => {
        const stakingInfoIds = await isekaiBattleStake.methods
          .getAllStakingTokenIds(defender)
          .call({ from: defender });

        if (stakingInfoIds[2] && stakingInfoIds[2].length > 0) {
          const _defenses = await Web3Api.token.getBatchTokenIdMetadata({
            address: settings.addresses.IsekaiBattle,
            token_ids: stakingInfoIds[2],
            chain: chainName(targetChainId),
          });
          setDefense(
            stakingInfoIds[2].map((id) => {
              const chara = _defenses.find((o) => o.token_id === Number(id));
              const metadata = JSON.parse(chara.metadata);
              chara.id = chara.token_id;
              chara.name = metadata.name;
              chara.image = metadata.image;
              chara.attributes = metadata.attributes;
              return chara;
            }),
          );
        }
      })();
    }
  }, [isekaiBattleStake]);

  return (
    <Master>
      {step === 1 && (
        <GameLoading fragmentId={fragmentId} step={step} setStep={setStep} />
      )}
      {step === 2 && (
        <GameIntroduction
          fragmentId={fragmentId}
          step={step}
          setStep={setStep}
          attack={attack}
          defense={defense}
        />
      )}
      {step === 3 && (
        <GameBattle1
          fragmentId={fragmentId}
          attack={attack}
          defense={defense}
        />
      )}
    </Master>
  );
}
