import { useMemo } from "react";
import { useLangContext } from "../../../../context";
import ico_ethereum from "../../../../images/ico_ethereum.svg";
import ico_arrow_blue from "../../../../images/ico_arrow_blue.svg";
import { seedImages } from "../../../../data/itemInfo";

function getSeedImage(type) {
  return seedImages[type];
}

export default function ItemSeedSelectConfirm(props) {
  const { ethPrice, selectedSeed, selectedCharacter } = props;
  const { langValue } = useLangContext();

  const effectType = useMemo(() => {
    if (!selectedSeed) return "";
    return selectedSeed.type;
  }, [selectedSeed]);

  const charaEffectValue = useMemo(() => {
    if (!selectedCharacter || !effectType) return 0;
    return selectedCharacter.attributes.find((x) => x.trait_type === effectType)
      .value;
  }, [selectedCharacter, effectType]);

  return (
    <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
      <div
        class="relative h-full w-full overflow-auto p-5"
        style={{
          background: "#eaeaea",
        }}
      >
        <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
          {langValue === "ja" && <>確認</>}
          {langValue === "en" && <>Confirmation</>}
        </h1>

        <p class="mt-6 border-t text-center text-sm">
          {langValue === "ja" && <>選択したキャラクターに種を使用しますか？</>}
          {langValue === "en" && (
            <>Do you want to use the Seed for the selected Character?</>
          )}
        </p>

        <div class="mt-6">
          <div class="border border-neutral-300 bg-cardBg">
            <div class="bg-itemBlue p-1 text-center text-sm text-white">
              {langValue === "ja" && <>種を使用するキャラクター</>}
              {langValue === "en" && <>Character to use the Seed</>}
            </div>
            <div class="px-6 py-4">
              <div class="text-center">
                <img
                  src={selectedCharacter.image}
                  alt=""
                  class="mx-auto w-16"
                />
                <p>
                  <span class="text-3xl">
                    {
                      selectedCharacter.attributes.find(
                        (o) => o.trait_type === "Name",
                      ).value
                    }
                  </span>{" "}
                  #{selectedCharacter.id}
                  <span class="relative -top-1 ml-2 rounded bg-black px-2 py-1 font-sans text-xs font-bold text-white">
                    {selectedCharacter.gen}
                  </span>
                </p>
              </div>
              <div class="mt-4 flex gap-2">
                <div class="flex-1 text-center text-[#676D9B]">
                  <div class="whitespace-nowrap text-2xl">
                    {effectType}{" "}
                    <span class="text-3xl">{charaEffectValue}</span>
                  </div>
                </div>
                <div class="flex-shrink-0 flex-grow-0 self-center">
                  <img src={ico_arrow_blue} alt="" class="mx-auto" />
                </div>
                <div class="flex-1 text-center text-[#2F3879]">
                  <div class="whitespace-nowrap text-2xl">
                    {effectType}{" "}
                    <span class="text-3xl">
                      {charaEffectValue + selectedSeed.lv}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="mt-6 border border-neutral-300 bg-cardBg">
            <div class="bg-itemBlue p-1 text-center text-sm text-white">
              {langValue === "ja" && <>消費</>}
              {langValue === "en" && <>Cost</>}
            </div>
            <div class="px-2 py-4">
              <div class="flex justify-between gap-2">
                <div class="flex-1">
                  <div class="flex items-center justify-center text-center">
                    <img
                      src={getSeedImage(selectedSeed.type)}
                      alt=""
                      class="-my-2 w-14"
                    />
                    {selectedSeed.type} Seed
                    <br />
                    Lv{selectedSeed.lv} × 1
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center justify-center text-center">
                    <img src={ico_ethereum} alt="" class="w-10" />
                    {ethPrice} ETH
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onConfirm}
          >
            {langValue === "ja" && <>使用する</>}
            {langValue === "en" && <>Use</>}
          </button>
        </div>
      </div>
    </div>
  );
}
