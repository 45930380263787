import { useMemo } from "react";
import { useLangContext } from "../../../../context";
import btn_close from "../../../../images/btn_close.svg";
import power from "../../../../images/seeds/power.png";
import defence from "../../../../images/seeds/defence.png";
import luck from "../../../../images/seeds/luck.png";

const seedImages = {
  ATK: power,
  DEF: defence,
  LUK: luck,
};

export default function ItemSeedSynthesisFinish(props) {
  const { selectedSeed, mixSeed } = props;
  const { langValue } = useLangContext();

  const mixSeedLv = useMemo(() => {
    if (!mixSeed) return 1;
    return Number(mixSeed.mixedSeedId) - selectedSeed.id + 1;
  }, [mixSeed]);

  return (
    <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70">
      <button class="absolute right-4 top-5" onClick={props.onClose}>
        <img src={btn_close} alt="" />
      </button>
      <div class="relative w-full overflow-auto">
        <div class="bg-gradient-to-br from-[#FFE7AA] via-[#A77EFF] to-[#78CFFD] p-4 text-center">
          <div class="text-3xl">
            {langValue === "ja" && <>{selectedSeed.type}の種</>}
            {langValue === "en" && <>{selectedSeed.type} Seed</>}
          </div>
          <div class="text-5xl font-bold [text-shadow:_0_1px_10px_rgb(255_255_255_/_100%)]">
            Lv {mixSeedLv}
          </div>
          <img
            src={seedImages[selectedSeed.type]}
            alt=""
            class="mx-auto w-28"
          />
        </div>
        <div class="sticky bottom-0 z-10 m-6 flex gap-2">
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
