import { useLangContext } from "../../../../context";
import ico_arrow_brown from "../../../../images/ico_arrow_brown.svg";
import ico_ethereum from "../../../../images/ico_ethereum.svg";

const levelBags = {
  120: "0",
  160: "1",
  200: "2",
  300: "2",
};

export default function ItemExtendConfirm(props) {
  const { langValue } = useLangContext();
  const { upgradeItemBag, itemBagQty } = props;
  return (
    <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
      <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
        <div
          class="relative h-full w-full overflow-auto p-5"
          style={{
            background: "#eaeaea",
          }}
        >
          <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
            {langValue === "ja" && <>バッグの拡張</>}
            {langValue === "en" && <>Bag expansion</>}
          </h1>

          <div class="mt-6">
            <div class="mt-6 border border-neutral-300 bg-cardBg">
              <div class="bg-itemBlue p-1 text-center text-sm text-white">
                {langValue === "ja" && <>バッグの最大容量の増加</>}
                {langValue === "en" && <>Increased maximum bag capacity</>}
              </div>
              <div class="px-6 py-4">
                <div class="mt-4 flex gap-2">
                  <div class="flex-1 text-center text-[#676D9B]">
                    <div class="text-sm">Level {levelBags[itemBagQty]}</div>
                    <div class="text-4xl">{itemBagQty}</div>
                  </div>
                  <div class="flex-shrink-0 flex-grow-0 self-center">
                    <img src={ico_arrow_brown} alt="" class="mx-auto" />
                  </div>
                  <div class="flex-1 text-center text-[#2F3879]">
                    <div class="text-sm">Level {upgradeItemBag.level}</div>
                    <div class="text-4xl">{upgradeItemBag.bag}</div>
                  </div>
                </div>

                <div class="mx-auto mt-2 text-center">
                  <div>
                    {langValue === "ja" && <>消費</>}
                    {langValue === "en" && <>Consumption</>}
                  </div>
                  <div class="flex items-center justify-center gap-2 text-lg">
                    <img src={ico_ethereum} alt="" />
                    {upgradeItemBag.price} ETH
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
            <button
              class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
              onClick={props.onClose}
            >
              Cancel
            </button>
            <button
              class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
              onClick={props.onConfirm}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
