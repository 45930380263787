import { useMemo } from "react";
import btn_close from "../../../../images/btn_close.svg";
import img_dragon_dark_alpha from "../../../../images/img_dragon_dark_alpha.svg";
import { useCharacterContext, useLangContext } from "../../../../context";

export default function ItemSeedDetail(props) {
  const { characters } = useCharacterContext();
  const { langValue } = useLangContext();
  const { selectedSeed, seedImage } = props;

  const metadata = useMemo(() => {
    return selectedSeed.metadata ? JSON.parse(selectedSeed.metadata) : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeed]);

  const canUseSeed = useMemo(
    () => {
      return characters.length;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [characters],
  );

  return (
    <div class="absolute left-0 top-0 z-0 h-full w-full bg-black bg-opacity-80 p-6 text-white">
      <div class="w-full bg-gradient-to-b from-[#9B7440] to-[#C2965C] text-white">
        <div class="w-full bg-gradient-to-tr from-[#8B5D1F] to-[#4B2E07] p-0.5 text-white">
          <div class="flex justify-between border border-neutral-300 px-2 py-1 text-sm">
            <span>{metadata.name}</span>
            <button class="" onClick={props.onClose}>
              <img src={btn_close} alt="" class="w-3" />
            </button>
          </div>
        </div>
        <div
          class="p-3"
          style={{
            background: `url(${img_dragon_dark_alpha}) 120% 0 no-repeat`,
            backgroundSize: "50%",
          }}
        >
          <div class="flex">
            <div class="flex-1">
              <div>
                {langValue === "ja" && <span>効果</span>}
                {langValue === "en" && <span>Effect</span>}
              </div>
              <div class="text-3xl text-yellow-200">
                {
                  JSON.parse(selectedSeed.metadata).attributes.find(
                    (x) => x.trait_type === "Type",
                  ).value
                }{" "}
                +{selectedSeed.lv}
              </div>
            </div>
            <div class="flex-1">
              <img
                src={seedImage}
                alt=""
                class="-mt-4 ml-auto mr-0 max-w-[80%]"
              />
            </div>
          </div>

          <div class="mb-6 mt-4 border-t border-white pt-4">
            <button
              class={`mx-auto mt-2 block w-full rounded-full p-3 text-center text-lg tracking-widest text-gray-700 drop-shadow-sm ${canUseSeed ? "bg-white" : "bg-gray"}`}
              onClick={() => {
                if (canUseSeed) {
                  props.openSeeds(selectedSeed);
                }
              }}
            >
              {langValue === "ja" && <>使用する</>}
              {langValue === "en" && <>Use</>}
            </button>
            <button
              class={`mx-auto mt-2 block w-full rounded-full p-3 text-center text-lg tracking-widest text-gray-700 drop-shadow-sm ${canUseSeed && selectedSeed.lv === 1 ? "bg-white" : "bg-gray"}`}
              onClick={() => {
                if (canUseSeed && selectedSeed.lv === 1) {
                  props.openSeedsSynthesis(selectedSeed);
                }
              }}
            >
              {langValue === "ja" && <>合成する</>}
              {langValue === "en" && <>Fuse</>}
            </button>
            <button
              class="mx-auto mt-2 block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-gray-700 drop-shadow-sm"
              onClick={props.onClose}
            >
              {langValue === "ja" && <>閉じる</>}
              {langValue === "en" && <>Close</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
