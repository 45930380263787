import IsekaiBattle from "isekai-battle-sdk/abi/IsekaiBattle.json";
import IsekaiBattleArmor from "isekai-battle-sdk/abi/IsekaiBattleArmor.json";
import IsekaiBattleSeeds from "isekai-battle-sdk/abi/IsekaiBattleSeeds.json";
import IsekaiBattleStake from "isekai-battle-sdk/abi/IsekaiBattleStake.json";
import IsekaiBattleWeapon from "isekai-battle-sdk/abi/IsekaiBattleWeapon.json";
import TrashIsekaiBattleItems from "isekai-battle-sdk/abi/TrashIsekaiBattleItems.json";
import Staking from "isekai-battle-sdk/abi/Staking.json";
import IsekaiBattleMagicCircle from "isekai-battle-sdk/abi/IsekaiBattleMagicCircle.json";
import IsekaiBattleStatusManager from "isekai-battle-sdk/abi/IsekaiBattleStatusManager.json";
import IsekaiBattleClash from "isekai-battle-sdk/abi/IsekaiBattleClash.json";
import IsekaiBattleFragment from "isekai-battle-sdk/abi/IsekaiBattleFragment.json";
import IsekaiBattleSinki from "isekai-battle-sdk/abi/IsekaiBattleSinki.json";
import IsekaiBattleBag from "isekai-battle-sdk/abi/IsekaiBattleBag.json";

import { address } from "isekai-battle-sdk";

import logo_test from "../images/logo_test.png";
// import logo from "../images/logo.png";

const commonSettings = {
  screenWidth: 800,
  screenHeight: 600,
  modalBgClose: false,
  debug: false,
  debugMap: false,
  dropAreaSize: [50, 40],
  abis: {
    IsekaiBattle,
    IsekaiBattleArmor,
    IsekaiBattleSeeds,
    IsekaiBattleStake,
    IsekaiBattleWeapon,
    TrashIsekaiBattleItems,
    Staking,
    IsekaiBattleMagicCircle,
    IsekaiBattleStatusManager,
    IsekaiBattleClash,
    IsekaiBattleFragment,
    IsekaiBattleSinki,
    IsekaiBattleBag,
  },
  defaultChainId: process.env.REACT_APP_CHAIN_ID,
};

const networkSettings = {
  /* NOTE new upgradeable contracts does not deploy on public networks
  // mainnet
  1: {
    addresses: {
      IsekaiBattle: "0x35883f8EB8c3Fc9403eDf3B84Cc76b0e8a39dC85",
      IsekaiBattleArmor: "0x88433D2E3fF6AAbC458C7d367DdD490b7E605C7F",
      IsekaiBattleSeeds: "0x0e31FE807EFF9b2EF07cDdE1Bf86Ac4a7cA2dA89",
      IsekaiBattleStake: "0xd5B0D0d612EF0E2a07032a06Fed4cF8900B5DD93",
      IsekaiBattleWeapon: "0x708c1620C566292CeaA9580F7AD7F341534DfaC4",
      TrashIsekaiBattleItems: "0xa8ED0AC90709F8CcC6B42754A356272200EC3137",
      IsekaiBattleStakeUpgradeable:
        "0x0851AF7f06Ab4d774c4440152506E8Bc309Ae9e2",
      Staking: "0xaEb5388C88Ef2BbC5E44610606cD6a0203Ef62Ca",
      IsekaiBattleMagicCircle: "0xcbd5f86fe4b86dc316311bf602a43f9de6d7dc34",
      IsekaiBattleStatusManager: "0x97066104B8162B76B628E48563C7080408B6856e",
    },
    opensea: {
      IsekaiBattle: "https://opensea.io/collection/isekai-battle",
      IsekaiBattleWeapon: "https://opensea.io/collection/weapons-isekai-battle",
      IsekaiBattleArmor: "https://opensea.io/collection/armor-isekai-battle",
      IsekaiBattleSeeds: "https://opensea.io/collection/seeds-isekai-battle",
    },
    logo,
    claimIntervalHours: 167,
    twitterShareUrl: "https://isekai-battle.xyz/",
  },

  // goerli
  5: {
    addresses: {
      IsekaiBattle: "0x35B0a9D54ef55761bC6f9e92B2C2eAdD27Fe4f3f",
      IsekaiBattleArmor: "0xcE5e673082495f37fc0C403EfaD67Db90aF33109",
      IsekaiBattleSeeds: "0x3321B7d703F337065F3F6a0c2b286303B6AFC4FD",
      IsekaiBattleStake: "0xAD1B72659403b5cC1048a4D4C4490bE15fdC0E1B",
      IsekaiBattleStakeRegion0: "0xCeDf0b85F919b2eBBf5fE594e89c79Dad3635616",
      IsekaiBattleWeapon: "0xA0E1b46e99459e45B2AC1122Bc0594A585c481e2",
      TrashIsekaiBattleItems: "0x81D0caF80E9bFfD9bF9c641ab964feB9ef69069e",
      IsekaiBattleStakeUpgradeable:
        "0xb9cfD7D73B407444e508c28Cc506c97A44d202ea",
      Staking: "0x54D483466421859480a220bCfCc4fa0B91B2C02E",
      IsekaiBattleMagicCircle: "0x2d3152212a74c381033f8d1b842ae81ebf9577a9",
      IsekaiBattleStatusManager: "0xa40d2B3301FF2F437140E2EE331B7415E2DA6FC1",
    },
    opensea: {
      IsekaiBattle:
        "https://testnets.opensea.io/collection/isekai-battle-goerli",
      IsekaiBattleWeapon:
        "https://testnets.opensea.io/collection/isekai-battle-weapons-goerli",
      IsekaiBattleArmor:
        "https://testnets.opensea.io/collection/isekai-battle-armor-goerli",
      IsekaiBattleSeeds:
        "https://testnets.opensea.io/collection/isekai-battle-seeds-goerli",
    },
    logo: logo_test,
    claimIntervalHours: 23,
    twitterShareUrl: "https://op-sepo.isekai-battle.xyz/",
  },
  */

  // hardhat
  31337: {
    addresses: address[31337],
    opensea: {
      IsekaiBattle:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xDEdbf7Ce09bef4beE6d49E81Ede20489FE81033d",
      IsekaiBattleWeapon:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xf33a0a14a4a183654C3fBA8B73B0052F633B18A0",
      IsekaiBattleArmor:
        "https://testnets.opensea.io/assets/optimism-sepolia/0x2cFeC84275830296d5dCaD322FBc95b2EA99f8b9",
      IsekaiBattleSeeds:
        "https://testnets.opensea.io/assets/optimism-sepolia/0x2Bbb050f1c8a5FD1516F09167e4eD6bFDc9464Af",
      IsekaiBattleSinki:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xe38dC663A048F749A4bf982060152a8849f53551",
    },
    logo: logo_test,
    claimIntervalHours: 23,
    twitterShareUrl: "https://op-sepo.isekai-battle.xyz/",
    blockExplorerUrl: "https://sepolia-optimistic.etherscan.io/",
  },
  11155420: {
    addresses: address[11155420],
    opensea: {
      IsekaiBattle:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xDEdbf7Ce09bef4beE6d49E81Ede20489FE81033d",
      IsekaiBattleWeapon:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xf33a0a14a4a183654C3fBA8B73B0052F633B18A0",
      IsekaiBattleArmor:
        "https://testnets.opensea.io/assets/optimism-sepolia/0x2cFeC84275830296d5dCaD322FBc95b2EA99f8b9",
      IsekaiBattleSeeds:
        "https://testnets.opensea.io/assets/optimism-sepolia/0x2Bbb050f1c8a5FD1516F09167e4eD6bFDc9464Af",
      IsekaiBattleSinki:
        "https://testnets.opensea.io/assets/optimism-sepolia/0xe38dC663A048F749A4bf982060152a8849f53551",
    },
    logo: logo_test,
    claimIntervalHours: 23,
    twitterShareUrl: "https://op-sepo.isekai-battle.xyz/",
    blockExplorerUrl: "https://sepolia-optimistic.etherscan.io/",
  },
};

// geth dev mode
networkSettings[1337] = networkSettings[31337];

const settings = Object.assign(
  {},
  commonSettings,
  networkSettings[commonSettings.defaultChainId],
);
export default settings;
