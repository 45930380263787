import { useMemo } from "react";
import { seedImages } from "../../../../data/itemInfo";

function getSeedImage(type) {
  return seedImages[type];
}

export default function SeedSelectItem(props) {
  const { item, selectedSeed } = props;

  const metadata = useMemo(() => {
    return item.metadata ? JSON.parse(item.metadata) : {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <li
      class={`flex items-center justify-between gap-2 py-2 pr-3 ${selectedSeed && selectedSeed.id === item.id ? "bg-indigo-950 bg-opacity-90" : "bg-opacity-70"}`}
      onClick={() => props.setSelectedSeed(item)}
    >
      <div class="flex items-center gap-2">
        <span>
          <img src={getSeedImage(item.type)} alt="" class="-my-2 w-20" />
        </span>
        <span class="text-2xl">{metadata.name}</span>
      </div>
      <span class="text-right text-2xl">{item.amount}</span>
    </li>
  );
}
