import {
  useFormationContext,
  useLangContext,
  useMapContext,
} from "../../../../context";
import blankImg from "../../../../images/characters/blank.png";
import icoSwordWhite from "../../../../images/ico_sword_white.svg";
import icoShieldWhite from "../../../../images/ico_shield_white.svg";
import icoSeedWhite from "../../../../images/ico_seed_white.svg";
import icoCrystalWhite from "../../../../images/ico_crystal_white.svg";
import { useEffect, useState } from "react";

export default function ExploreTab(props) {
  const { areaData } = useMapContext();
  const { langValue } = useLangContext();
  const { formation, getFormationCharacter } = useFormationContext();
  const [areaHash, setAreaHash] = useState();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setAreaHash(hash);
    }
  }, []);

  useEffect(() => {
    if (areaHash) {
      const target = document.querySelector(areaHash);
      if (target) {
        target.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [areaHash]);

  return (
    <div>
      {areaData
        .filter((area) => area.status === "public")
        .reverse()
        .map((area, i) => {
          return (
            <div key={i} class="mb-10" id={area.id}>
              <div class="relative">
                <img
                  src={area.image}
                  alt=""
                  class="mt-2 w-full rounded-md brightness-50"
                />
                <span class="absolute top-1/2 mt-0.5 w-full -translate-y-1/2 transform px-4 text-center text-lg font-bold leading-5 text-white [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]">
                  {langValue === "en" && (
                    <>
                      Region {area.id_number} {area.name_en}
                    </>
                  )}
                  {langValue === "ja" && (
                    <>
                      Region {area.id_number} {area.name_ja}
                    </>
                  )}
                </span>
                <span class="absolute bottom-1 right-2 flex gap-2">
                  <img src={icoSwordWhite} alt="" class="w-3" />
                  <img src={icoShieldWhite} alt="" class="w-3" />
                  <img src={icoSeedWhite} alt="" class="w-3" />
                  {area.isTreasure && (
                    <img src={icoCrystalWhite} alt="" class="w-5" />
                  )}
                </span>
              </div>
              <ul class="mt-4 grid grid-cols-5 items-start gap-2">
                {Array.from(
                  { length: formation[area.id].length },
                  (_, k) => k,
                ).map((index) => {
                  const chara = getFormationCharacter(area.id, index);
                  return (
                    <li
                      class="rounded bg-gradient-to-br from-[#88814B] via-[#CDC986] to-[#88814B] p-0.5"
                      key={`${area.id}-${index}`}
                      group={area.id}
                      index={index}
                      onClick={() => {
                        window.location.hash = area.id;
                        props.onSelectUnits(area.id, index, chara);
                      }}
                    >
                      <div class="flex items-center justify-center rounded-sm bg-gradient-to-b from-[#8A834F] via-[#DBD499] to-[#EFEDD7]">
                        {chara ? (
                          <img
                            src={chara.image}
                            alt={chara.name}
                            class="w-18"
                          />
                        ) : (
                          <img src={blankImg} alt="" class="w-18" />
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
    </div>
  );
}
