import {
  useCharacterContext,
  useFormationContext,
  useItemContext,
  useLangContext,
  useMapContext,
} from "../../../../context";
import icoArrowBrown from "../../../../images/ico_arrow_brown.svg";
import icoArrowUp from "../../../../images/ico_arrow_up.svg";
import icoArrowDown from "../../../../images/ico_arrow_down.svg";
import { chunkArray } from "../../../../util";

export default function UnitsConfirm(props) {
  const { langValue } = useLangContext();
  const { areaData } = useMapContext();
  const {
    normalizedSavedFormation,
    normalizedFormation,
    formation,
    savedFormation,
    changedFormations,
  } = useFormationContext();
  const { armor, weapons } = useItemContext();
  const { characters } = useCharacterContext();

  function currentWeapons() {
    let a = 0;
    let b = 0;

    return weapons
      .sort((x, y) => {
        const a = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(x.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        const b = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(y.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        if (
          JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value !==
          JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value
        ) {
          return (
            JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value -
            JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value
          );
        }
        if (a !== b) {
          return b - a;
        }
        return 0;
      })
      .filter((x) => {
        const res =
          a <
          30 +
            10 * formation.attack.filter((x) => x).length +
            formation.attack.filter((x) => x).length;
        a += Number.parseInt(x.amount);
        return res;
      })
      .map((el, index) => {
        b += Number.parseInt(el.amount);
        const res =
          b <
          30 +
            10 * formation.attack.filter((x) => x).length +
            formation.attack.filter((x) => x).length;
        return {
          ...el,
          amount: res
            ? Number.parseInt(el.amount)
            : 30 +
              10 * formation.attack.filter((x) => x).length +
              formation.attack.filter((x) => x).length -
              (b - Number.parseInt(el.amount)),
        };
      });
  }
  function savedCurrentWeapons() {
    let a = 0;
    let b = 0;

    return weapons
      .sort((x, y) => {
        const a = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(x.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        const b = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(y.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        if (
          JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value !==
          JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value
        ) {
          return (
            JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value -
            JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value
          );
        }
        if (a !== b) {
          return b - a;
        }
        return 0;
      })
      .filter((x) => {
        const res =
          a <
          30 +
            10 * savedFormation.attack.filter((x) => x).length +
            savedFormation.attack.filter((x) => x).length;
        a += Number.parseInt(x.amount);
        return res;
      })
      .map((el, index) => {
        b += Number.parseInt(el.amount);
        const res =
          b <
          30 +
            10 * savedFormation.attack.filter((x) => x).length +
            savedFormation.attack.filter((x) => x).length;
        return {
          ...el,
          amount: res
            ? Number.parseInt(el.amount)
            : 30 +
              10 * savedFormation.attack.filter((x) => x).length +
              savedFormation.attack.filter((x) => x).length -
              (b - Number.parseInt(el.amount)),
        };
      });
  }

  function currentArmor() {
    let a = 0;
    let b = 0;

    return armor
      .sort((x, y) => {
        const a = characters
          .filter((x) => formation.defence.includes(x.id))
          .map((x) => x.armorType)
          .filter(
            (z) =>
              z ===
              JSON.parse(x.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        const b = characters
          .filter((x) => formation.defence.includes(x.id))
          .map((x) => x.armorType)
          .filter(
            (z) =>
              z ===
              JSON.parse(y.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        if (
          JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value !==
          JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value
        ) {
          return (
            JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value -
            JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value
          );
        }
        if (a !== b) {
          return b - a;
        }
        return 0;
      })
      .filter((x) => {
        const res =
          a <
          30 +
            10 * formation.defence.filter((x) => x).length +
            formation.defence.filter((x) => x).length;
        a += Number.parseInt(x.amount);
        return res;
      })
      .map((el, index) => {
        b += Number.parseInt(el.amount);
        const res =
          b <
          30 +
            10 * formation.defence.filter((x) => x).length +
            formation.defence.filter((x) => x).length;
        return {
          ...el,
          amount: res
            ? Number.parseInt(el.amount)
            : 30 +
              10 * formation.defence.filter((x) => x).length +
              formation.defence.filter((x) => x).length -
              (b - Number.parseInt(el.amount)),
        };
      });
  }

  function savedCurrentArmor() {
    let a = 0;
    let b = 0;

    return armor
      .sort((x, y) => {
        const a = characters
          .filter((x) => savedFormation.defence.includes(x.id))
          .map((x) => x.armorType)
          .filter(
            (z) =>
              z ===
              JSON.parse(x.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        const b = characters
          .filter((x) => savedFormation.defence.includes(x.id))
          .map((x) => x.armorType)
          .filter(
            (z) =>
              z ===
              JSON.parse(y.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        if (
          JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value !==
          JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value
        ) {
          return (
            JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value -
            JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value
          );
        }
        if (a !== b) {
          return b - a;
        }
        return 0;
      })
      .filter((x) => {
        const res =
          a <
          30 +
            10 * savedFormation.defence.filter((x) => x).length +
            savedFormation.defence.filter((x) => x).length;
        a += Number.parseInt(x.amount);
        return res;
      })
      .map((el, index) => {
        b += Number.parseInt(el.amount);
        const res =
          b <
          30 +
            10 * savedFormation.defence.filter((x) => x).length +
            savedFormation.defence.filter((x) => x).length;
        return {
          ...el,
          amount: res
            ? Number.parseInt(el.amount)
            : 30 +
              10 * savedFormation.defence.filter((x) => x).length +
              savedFormation.defence.filter((x) => x).length -
              (b - Number.parseInt(el.amount)),
        };
      });
  }

  function getScore(lv) {
    switch (lv) {
      case 1:
        return 150;
      case 2:
        return 175;
      case 3:
        return 200;
      default:
        break;
    }
  }
  function getATKFromWeaponType(type) {
    const target = characters
      .filter((x) => formation.attack.includes(x.id))
      .filter((x) => x.weaponType === type)
      .map((x) => x.attack);
    return target.length > 0 ? target.reduce((x, y) => x + y) : 0;
  }
  function getSavedATKFromWeaponType(type) {
    const target = characters
      .filter((x) => savedFormation.attack.includes(x.id))
      .filter((x) => x.weaponType === type)
      .map((x) => x.attack);
    return target.length > 0 ? target.reduce((x, y) => x + y) : 0;
  }

  function getWeaponBonus() {
    const counts = {};
    characters
      .filter((x) => formation.attack.includes(x.id))
      .map((x) => x.weaponType)
      .forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
    const max = Math.max(...Object.values(counts));
    switch (max) {
      case 1:
        return 5.0;
      case 2:
        return 2.5;
      case 3:
        return 1.67;
      case 4:
        return 1.25;
      case 5:
        return 1.0;
      default:
        break;
    }
  }

  function getSavedWeaponBonus() {
    const counts = {};
    characters
      .filter((x) => savedFormation.attack.includes(x.id))
      .map((x) => x.weaponType)
      .forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
    const max = Math.max(...Object.values(counts));
    switch (max) {
      case 1:
        return 5.0;
      case 2:
        return 2.5;
      case 3:
        return 1.67;
      case 4:
        return 1.25;
      case 5:
        return 1.0;
      default:
        break;
    }
  }

  function currentAttack() {
    const target = currentWeapons();
    return (
      (target.length > 0
        ? target
            .map(
              (x) =>
                Number.parseInt(x.amount) *
                getScore(
                  JSON.parse(x.metadata).attributes.find(
                    (z) => z.trait_type === "Lv",
                  ).value,
                ),
            )
            .reduce((x, y) => x + y)
        : 0) +
      (target.length > 0 && formation.attack.filter((x) => x).length > 0
        ? Math.floor(
            target
              .map((x) => {
                const metadata = JSON.parse(x.metadata);
                const atk = getATKFromWeaponType(
                  metadata.attributes.find((y) => y.trait_type === "Type")
                    .value,
                );
                const score = getScore(
                  metadata.attributes.find((z) => z.trait_type === "Lv").value,
                );
                return Math.floor(score * atk * 0.005 * x.amount);
              })
              .reduce((x, y) => x + y) * getWeaponBonus(),
          )
        : 0)
    );
  }

  function savedCurrentAttack() {
    const target = savedCurrentWeapons();
    return (
      (target.length > 0
        ? target
            .map(
              (x) =>
                Number.parseInt(x.amount) *
                getScore(
                  JSON.parse(x.metadata).attributes.find(
                    (z) => z.trait_type === "Lv",
                  ).value,
                ),
            )
            .reduce((x, y) => x + y)
        : 0) +
      (target.length > 0 && savedFormation.attack.filter((x) => x).length > 0
        ? Math.floor(
            target
              .map((x) => {
                const metadata = JSON.parse(x.metadata);
                const atk = getSavedATKFromWeaponType(
                  metadata.attributes.find((y) => y.trait_type === "Type")
                    .value,
                );
                const score = getScore(
                  metadata.attributes.find((z) => z.trait_type === "Lv").value,
                );
                return Math.floor(score * atk * 0.005 * x.amount);
              })
              .reduce((x, y) => x + y) * getSavedWeaponBonus(),
          )
        : 0)
    );
  }
  function getDEFFromWeaponType(type) {
    const target = characters
      .filter((x) => formation.defence.includes(x.id))
      .filter((x) => x.armorType === type)
      .map((x) => x.defence);
    return target.length > 0 ? target.reduce((x, y) => x + y) : 0;
  }
  function getSavedDEFFromWeaponType(type) {
    const target = characters
      .filter((x) => savedFormation.defence.includes(x.id))
      .filter((x) => x.armorType === type)
      .map((x) => x.defence);
    return target.length > 0 ? target.reduce((x, y) => x + y) : 0;
  }

  function getArmorBonus() {
    const counts = {};
    characters
      .filter((x) => formation.defence.includes(x.id))
      .map((x) => x.armorType)
      .forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
    const max = Math.max(...Object.values(counts));
    switch (max) {
      case 1:
        return 5.0;
      case 2:
        return 2.5;
      case 3:
        return 1.67;
      case 4:
        return 1.25;
      case 5:
        return 1.0;
      default:
        break;
    }
  }

  function getSavedArmorBonus() {
    const counts = {};
    characters
      .filter((x) => savedFormation.defence.includes(x.id))
      .map((x) => x.armorType)
      .forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
    const max = Math.max(...Object.values(counts));
    switch (max) {
      case 1:
        return 5.0;
      case 2:
        return 2.5;
      case 3:
        return 1.67;
      case 4:
        return 1.25;
      case 5:
        return 1.0;
      default:
        break;
    }
  }

  function currentDef() {
    const target = currentArmor();
    return (
      (target.length > 0
        ? target
            .map(
              (x) =>
                Number.parseInt(x.amount) *
                getScore(
                  JSON.parse(x.metadata).attributes.find(
                    (z) => z.trait_type === "Lv",
                  ).value,
                ),
            )
            .reduce((x, y) => x + y)
        : 0) +
      (target.length > 0 && formation.defence.filter((x) => x).length > 0
        ? Math.floor(
            target
              .map((x) => {
                const metadata = JSON.parse(x.metadata);
                const def = getDEFFromWeaponType(
                  metadata.attributes.find((y) => y.trait_type === "Type")
                    .value,
                );
                const score = getScore(
                  metadata.attributes.find((z) => z.trait_type === "Lv").value,
                );
                return Math.floor(score * def * 0.005 * x.amount);
              })
              .reduce((x, y) => x + y) * getArmorBonus(),
          )
        : 0)
    );
  }

  function savedCurrentDef() {
    const target = savedCurrentArmor();
    return (
      (target.length > 0
        ? target
            .map(
              (x) =>
                Number.parseInt(x.amount) *
                getScore(
                  JSON.parse(x.metadata).attributes.find(
                    (z) => z.trait_type === "Lv",
                  ).value,
                ),
            )
            .reduce((x, y) => x + y)
        : 0) +
      (target.length > 0 && savedFormation.defence.filter((x) => x).length > 0
        ? Math.floor(
            target
              .map((x) => {
                const metadata = JSON.parse(x.metadata);
                const def = getSavedDEFFromWeaponType(
                  metadata.attributes.find((y) => y.trait_type === "Type")
                    .value,
                );
                const score = getScore(
                  metadata.attributes.find((z) => z.trait_type === "Lv").value,
                );
                return Math.floor(score * def * 0.005 * x.amount);
              })
              .reduce((x, y) => x + y) * getSavedArmorBonus(),
          )
        : 0)
    );
  }

  function sumLuk(chars) {
    let luck = 0;
    if (chars) {
      for (const c of chars) {
        luck += c.luck;
      }
    }
    return luck;
  }

  return (
    <div class="relative flex flex-1 flex-col overflow-auto">
      <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
        <div
          class="relative h-full w-full overflow-auto p-5"
          style={{
            background: "#eaeaea",
          }}
        >
          <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
            {langValue === "ja" && <>確認</>}
            {langValue === "en" && <>Confirmation</>}
          </h1>
          <p class="mt-4 text-center">
            {langValue === "ja" && <>編成を変更しますか？</>}
            {langValue === "en" && (
              <>Do you want to change the formation of Units?</>
            )}
          </p>

          {JSON.stringify(normalizedFormation.explore) !==
            JSON.stringify(normalizedSavedFormation.explore) && (
            <div class="mt-3 border border-neutral-300 bg-cardBg">
              <div class="bg-exploreDarkGreen p-1 text-center text-sm text-white">
                {langValue === "ja" && <>探索部隊</>}
                {langValue === "en" && <>Explorer Unit</>}
              </div>
              {
                // eslint-disable-next-line array-callback-return
                areaData.map((region, idx) => {
                  if (changedFormations.explore[region.id]) {
                    return (
                      <div class="p-6 pt-4" key={idx}>
                        <div class="units__linethrough text-center text-xs">
                          <span>
                            {langValue === "ja" && (
                              <>
                                Region {region.id_number} {region.name_ja}
                              </>
                            )}
                            {langValue === "en" && (
                              <>
                                Region {region.id_number} {region.name_en}
                              </>
                            )}
                          </span>
                        </div>
                        <div class="mt-4 flex gap-2">
                          <div class="flex-1 text-center">
                            <div class="text-xs">
                              {langValue === "ja" && <>合計LUK値</>}
                              {langValue === "en" && <>Total LUK</>}
                            </div>
                            <div class="text-4xl">
                              {sumLuk(
                                normalizedSavedFormation.explore[region.id],
                              )}
                            </div>
                            {normalizedSavedFormation.explore[region.id]
                              ?.length > 0 &&
                              chunkArray(
                                normalizedSavedFormation.explore[region.id],
                                3,
                              ).map((charas, key) => {
                                return (
                                  <div class="flex justify-center" key={key}>
                                    {charas.map((chara, index) => {
                                      return (
                                        <img
                                          src={chara.image}
                                          alt=""
                                          class="-ml-3 w-10"
                                          key={index}
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                          </div>
                          <div class="flex-shrink-0 flex-grow-0 self-center">
                            <img src={icoArrowBrown} alt="" class="mx-auto" />
                          </div>
                          <div class="flex-1 text-center">
                            <div class="text-xs">
                              {langValue === "ja" && <>合計LUK値</>}
                              {langValue === "en" && <>Total LUK</>}
                            </div>
                            <div class="text-4xl">
                              {sumLuk(normalizedFormation.explore[region.id])}
                            </div>
                            {normalizedFormation.explore[region.id]?.length >
                              0 &&
                              chunkArray(
                                normalizedFormation.explore[region.id],
                                3,
                              ).map((charas, key) => {
                                return (
                                  <div class="flex justify-center" key={key}>
                                    {charas.map((chara, index) => {
                                      return (
                                        <img
                                          src={chara.image}
                                          alt=""
                                          class="-ml-3 w-10"
                                          key={index}
                                        />
                                      );
                                    })}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          )}

          {/* 攻撃部隊 */}
          {JSON.stringify(normalizedFormation.attack) !==
            JSON.stringify(normalizedSavedFormation.attack) && (
            <div class="mt-6 border border-neutral-300 bg-cardBg">
              <div class="bg-attackDarkRed p-1 text-center text-sm text-white">
                {langValue === "ja" && <>攻撃部隊</>}
                {langValue === "en" && <>Attack Unit</>}
              </div>
              {changedFormations.attack && (
                <div class="p-6 pt-4">
                  <div class="flex gap-2">
                    <div class="flex-1 text-center">
                      <div class="text-xs">
                        {langValue === "ja" && <>総合攻撃力</>}
                        {langValue === "en" && <>Total attack power</>}
                      </div>
                      <div class="text-4xl">{savedCurrentAttack()}</div>
                      {chunkArray(normalizedSavedFormation.attack, 3).map(
                        (charas, key) => {
                          return (
                            <div class="flex justify-center" key={key}>
                              {charas.map((chara, index) => {
                                return (
                                  <img
                                    src={chara.image}
                                    alt=""
                                    class="-ml-3 w-10"
                                    key={index}
                                  />
                                );
                              })}
                            </div>
                          );
                        },
                      )}
                    </div>
                    <div class="flex-shrink-0 flex-grow-0 self-center">
                      <img src={icoArrowBrown} alt="" class="mx-auto" />
                      <div class="mt-2 flex gap-1 text-sm font-bold text-yellow-600">
                        {currentAttack() - savedCurrentAttack() === 0
                          ? ""
                          : currentAttack() - savedCurrentAttack() >= 0
                            ? "+"
                            : "-"}
                        {currentAttack() - savedCurrentAttack() === 0
                          ? ""
                          : currentAttack() - savedCurrentAttack()}
                        {currentAttack() - savedCurrentAttack() === 0 ? (
                          ""
                        ) : currentAttack() - savedCurrentAttack() >= 0 ? (
                          <img src={icoArrowUp} alt="" />
                        ) : (
                          <img src={icoArrowDown} alt="" />
                        )}
                      </div>
                    </div>
                    <div class="flex-1 text-center">
                      <div class="text-xs">
                        {langValue === "ja" && <>総合攻撃力</>}
                        {langValue === "en" && <>Total attack power</>}
                      </div>
                      <div class="text-4xl text-yellow-600">
                        {currentAttack()}
                      </div>
                      {chunkArray(normalizedFormation.attack, 3).map(
                        (charas, key) => {
                          return (
                            <div class="flex justify-center" key={key}>
                              {charas.map((chara, index) => {
                                return (
                                  <img
                                    src={chara.image}
                                    alt=""
                                    class="-ml-3 w-10"
                                    key={index}
                                  />
                                );
                              })}
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* 守備部隊 */}
          {JSON.stringify(normalizedFormation.defence) !==
            JSON.stringify(normalizedSavedFormation.defence) && (
            <div class="mt-6 border border-neutral-300 bg-cardBg">
              <div class="bg-defenceDarkBlue p-1 text-center text-sm text-white">
                {langValue === "ja" && <>守備部隊</>}
                {langValue === "en" && <>Defence Unit</>}
              </div>
              <div class="p-6 pt-4">
                <div class="flex gap-2">
                  <div class="flex-1 text-center">
                    <div class="text-xs">
                      {langValue === "ja" && <>総合防御力</>}
                      {langValue === "en" && <>Total defence power</>}
                    </div>
                    <div class="text-4xl">{savedCurrentDef()}</div>
                    {chunkArray(normalizedSavedFormation.defence, 3).map(
                      (charas, key) => {
                        return (
                          <div class="flex justify-center" key={key}>
                            {charas.map((chara, index) => {
                              return (
                                <img
                                  src={chara.image}
                                  alt=""
                                  class="-ml-3 w-10"
                                  key={index}
                                />
                              );
                            })}
                          </div>
                        );
                      },
                    )}
                  </div>
                  <div class="flex-shrink-0 flex-grow-0 self-center">
                    <img src={icoArrowBrown} alt="" class="mx-auto" />
                    <div class="mt-2 flex gap-1 text-sm font-bold text-blue-700">
                      {currentDef() - savedCurrentDef() === 0
                        ? ""
                        : currentDef() - savedCurrentDef() >= 0
                          ? "+"
                          : "-"}
                      {currentDef() - savedCurrentDef() === 0
                        ? ""
                        : currentDef() - savedCurrentDef()}
                      {currentDef() - savedCurrentDef() === 0 ? (
                        ""
                      ) : currentDef() - savedCurrentDef() >= 0 ? (
                        <img src={icoArrowUp} alt="" />
                      ) : (
                        <img src={icoArrowDown} alt="" />
                      )}
                    </div>
                  </div>
                  <div class="flex-1 text-center">
                    <div class="text-xs">
                      {langValue === "ja" && <>総合防御力</>}
                      {langValue === "en" && <>Total defence power</>}
                    </div>
                    <div class="text-4xl text-blue-700">{currentDef()}</div>
                    {chunkArray(normalizedFormation.defence, 3).map(
                      (charas, key) => {
                        return (
                          <div class="flex justify-center" key={key}>
                            {charas.map((chara, index) => {
                              return (
                                <img
                                  src={chara.image}
                                  alt=""
                                  class="-ml-3 w-10"
                                  key={index}
                                />
                              );
                            })}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
            <button
              class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
              onClick={props.okConfirm}
            >
              {langValue === "ja" && <>変更する</>}
              {langValue === "en" && <>Yes</>}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
