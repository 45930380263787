// icon
import ico_explore from "../../images/navigation/ico_explore.png";
import ico_units from "../../images/navigation/ico_units.png";
import ico_character from "../../images/navigation/ico_characters.png";
import ico_item from "../../images/navigation/ico_items.png";
import ico_treasure from "../../images/navigation/ico_treasures.png";
import ico_battle from "../../images/navigation/ico_battle.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Bgm from "../common/Bgm";

export default function Navigation() {
  const location = useLocation();
  const [selected, setSelected] = useState("");
  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  return (
    <>
      <nav className="navigation z-[99] w-full bg-brown">
        <ul className="flex">
          <li
            className={`${selected === "/explore" && "selected relative z-10"}`}
          >
            <Link to="/explore">
              <img src={ico_explore} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
          <li
            className={`${selected === "/units" && "selected relative z-10"}`}
          >
            <Link to="/units">
              <img src={ico_units} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
          <li
            className={`${selected === "/characters" && "selected relative z-10"}`}
          >
            <Link to="/characters">
              <img src={ico_character} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
          <li
            className={`${selected === "/items" && "selected relative z-10"}`}
          >
            <Link to="/items">
              <img src={ico_item} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
          <li
            className={`${selected === "/treasures" && "selected relative z-10"}`}
          >
            <Link to="/treasures">
              <img src={ico_treasure} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
          <li
            className={`${selected.startsWith("/battle") && "selected relative z-10"}`}
          >
            <Link to="/battle/history">
              <img src={ico_battle} alt="" class="relative z-10 px-1" />
            </Link>
          </li>
        </ul>
      </nav>
      <Bgm bgm={selected ? selected.split("/")[1] : "bgm1"} />
    </>
  );
}
