import { useMemo } from "react";
import { useFormationContext, useLangContext } from "../../../../context";
import { chunkArray } from "../../../../util";

export default function UnitsTransaction(props) {
  const { staking } = props;
  const { langValue } = useLangContext();
  const { normalizedSavedFormation, normalizedFormation } =
    useFormationContext();

  const saveExplores = useMemo(() => {
    let explores = [];
    for (const [_, explore] of Object.entries(
      normalizedSavedFormation.explore,
    )) {
      explores = explores.concat(explore);
    }
    return chunkArray(explores, 6);
  }, [normalizedSavedFormation.explore]);

  const currentExplores = useMemo(() => {
    let explores = [];
    for (const [_, explore] of Object.entries(normalizedFormation.explore)) {
      explores = explores.concat(explore);
    }
    return chunkArray(explores, 6);
  }, [normalizedFormation.explore]);

  return (
    <div class="relative flex flex-1 flex-col overflow-auto">
      <div class="z-5 absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
        <div
          class="relative h-full w-full overflow-auto p-5"
          style={{
            background: "#eaeaea",
          }}
        >
          <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
            {langValue === "ja" && <>探索の変更</>}
            {langValue === "en" && <>Exploration changes</>}
          </h1>

          <div class="mt-6 flex flex-col gap-10">
            <div
              class={`min-h-32 text-center ${staking.step !== 1 && "opacity-30"}`}
            >
              <div class="units__magiccircle-bg">1</div>
              <div class="mt-2">
                {langValue === "ja" && <>冒険者たちが帰ってきています</>}
                {langValue === "en" && <>The adventurers are returning</>}
              </div>
              {staking.step === 1 &&
                saveExplores.map((charas, key) => {
                  return (
                    <div class="mt-2 flex justify-center" key={key}>
                      {charas.map((chara, index) => {
                        return (
                          <img
                            src={chara.image}
                            alt=""
                            class="scale-x w-14"
                            key={index}
                          />
                        );
                      })}
                    </div>
                  );
                })}
            </div>

            <div
              class={`min-h-32 text-center ${staking.step !== 2 && "opacity-30"}`}
            >
              <div class="units__magiccircle-bg">2</div>
              <div class="mt-2">
                {langValue === "ja" && (
                  <>冒険者が新たなリージョンへ移動します</>
                )}
                {langValue === "en" && (
                  <>The adventurers are moving to a new region</>
                )}
              </div>
              {staking.step === 2 &&
                currentExplores.map((charas, key) => {
                  return (
                    <div class="mt-2 flex justify-center" key={key}>
                      {charas.map((chara, index) => {
                        return (
                          <img
                            src={chara.image}
                            alt=""
                            class="scale-x w-14"
                            key={index}
                          />
                        );
                      })}
                    </div>
                  );
                })}
            </div>

            <div
              class={`min-h-32 text-center ${staking.step !== 3 && "opacity-30"}`}
            >
              <div class="units__magiccircle-bg">3</div>
              <div class="mt-2">
                {langValue === "ja" && (
                  <>冒険者が新たなリージョンに移動できました</>
                )}
                {langValue === "en" && (
                  <>The adventurers have moved to a new region</>
                )}
              </div>
              {staking.step === 3 &&
                currentExplores.map((charas, key) => {
                  return (
                    <div class="mt-2 flex justify-center" key={key}>
                      {charas.map((chara, index) => {
                        return (
                          <img
                            src={chara.image}
                            alt=""
                            class="scale-x w-14"
                            key={index}
                          />
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
          {staking.step === 3 && (
            <div class="sticky bottom-0 z-10">
              <button
                class="mt-6 block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
                onClick={props.onClose}
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
