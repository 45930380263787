import { useLangContext } from "../../../../context";

import ico_ethereum from "../../../../images/ico_ethereum.svg";
import ico_arrow_blue from "../../../../images/ico_arrow_blue.svg";
import power from "../../../../images/seeds/power.png";
import defence from "../../../../images/seeds/defence.png";
import luck from "../../../../images/seeds/luck.png";

const seedImages = {
  ATK: power,
  DEF: defence,
  LUK: luck,
};

export default function ItemSeedSynthesisConfirm(props) {
  const { langValue } = useLangContext();
  const { selectedSeed, percentage, ethPrice, selectedCount, levels } = props;

  return (
    <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
      <div
        class="relative h-full w-full overflow-auto p-5"
        style={{
          background: "#eaeaea",
        }}
      >
        <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
          {langValue === "ja" && <>確認</>}
          {langValue === "en" && <>Confirmation</>}
        </h1>

        <p class="mt-6 border-t text-center text-sm">
          {langValue === "ja" && <>下記条件で種を合成しますか？</>}
          {langValue === "en" && <>Do you want to fuse the Seeds as below?</>}
        </p>

        <div class="mt-6">
          <div class="border border-neutral-300 bg-cardBg">
            <div class="bg-itemBlue p-1 text-center text-sm text-white">
              {langValue === "ja" && <>合成確率</>}
              {langValue === "en" && <>Probability of fusion results</>}
            </div>
            <div class="px-6 py-4">
              <div class="text-center">
                <img
                  src={seedImages[selectedSeed.type]}
                  alt=""
                  class="mx-auto w-20"
                />
              </div>
              <div class="mt-4 flex items-center gap-2">
                <div class="flex-1 text-center text-[#676D9B]">
                  <div class="text-1xl whitespace-nowrap">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[2]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[2]}
                      </>
                    )}
                  </div>
                </div>
                <div class="flex-shrink-0 flex-grow-0 self-center">
                  <img
                    src={ico_arrow_blue}
                    alt=""
                    class="mx-auto mx-auto w-6"
                  />
                </div>
                <div class="flex-1 text-center text-[#2F3879]">
                  <div class="text-1xl whitespace-nowrap">
                    <span class="text-2xl">{percentage[0]}%</span>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex items-center gap-2">
                <div class="flex-1 text-center text-[#676D9B]">
                  <div class="text-1xl whitespace-nowrap">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[1]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[1]}
                      </>
                    )}
                  </div>
                </div>
                <div class="flex-shrink-0 flex-grow-0 self-center">
                  <img
                    src={ico_arrow_blue}
                    alt=""
                    class="mx-auto mx-auto w-6"
                  />
                </div>
                <div class="flex-1 text-center text-[#2F3879]">
                  <div class="text-1xl whitespace-nowrap">
                    <span class="text-2xl">{percentage[1]}%</span>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex items-center gap-2">
                <div class="flex-1 text-center text-[#676D9B]">
                  <div class="text-1xl whitespace-nowrap">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[0]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[0]}
                      </>
                    )}
                  </div>
                </div>
                <div class="flex-shrink-0 flex-grow-0 self-center">
                  <img
                    src={ico_arrow_blue}
                    alt=""
                    class="mx-auto mx-auto w-6"
                  />
                </div>
                <div class="flex-1 text-center text-[#2F3879]">
                  <div class="text-1xl whitespace-nowrap">
                    <span class="text-2xl">{percentage[2]}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="mt-6 border border-neutral-300 bg-cardBg">
            <div class="bg-itemBlue p-1 text-center text-sm text-white">
              {langValue === "ja" && <>消費</>}
              {langValue === "en" && <>Cost</>}
            </div>
            <div class="px-2 py-4">
              <div class="flex justify-between gap-2">
                <div class="flex-1">
                  <div class="flex items-center justify-center text-center">
                    <img
                      src={seedImages[selectedSeed.type]}
                      alt=""
                      class="-my-2 w-14"
                    />
                    ATK Seed
                    <br />
                    Lv{selectedSeed.lv} × {selectedCount}
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex items-center justify-center text-center">
                    <img src={ico_ethereum} alt="" class="w-10" />
                    {ethPrice} ETH
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onConfirm}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
