import logo from "../../images/logo_white.svg";
import coin from "../../images/ico_ethereum.svg";
import { useApiContext, useGameContext } from "../../context";
import { Link } from "react-router-dom";
import MyPage from "./my-page";
import icoMypage from "../../images/ico_mypage.png";
import { useEffect, useState } from "react";
import { formatBalance, shortAddress } from "../../util";
import settings from "../../config/settings";

export default function Header() {
  const { accountBalance, isekaiBattle } = useGameContext();
  const { account, ens, user } = useApiContext();
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    if (account && !userName && !loading) {
      setLoading(true);
      if (ens) {
        setUserName(ens);
      } else {
        setUserName(shortAddress(account));
      }
    }
  }, [account, ens, userName, loading]);
  const [isOpenMypage, setIsOpenMypage] = useState(false);
  const [userImage, setUserImage] = useState();

  useEffect(() => {
    const setUserImageByContract = async () => {
      if (!account || !isekaiBattle.methods) return;

      const userImage = await user.get("userImage");
      if (
        !userImage ||
        userImage.contractAddress !== settings.addresses.IsekaiBattle
      ) {
        setUserImage(icoMypage);
        return;
      }

      const tokenURI = await isekaiBattle.methods
        .tokenURI(userImage.tokenId)
        .call();
      if (tokenURI.indexOf("base64,")) {
        const metadataBase64 = tokenURI.substr(tokenURI.indexOf("base64,") + 7);
        const metadata = JSON.parse(window.atob(metadataBase64));
        const image = metadata.image;
        setUserImage(image);
      } else {
        setUserImage(icoMypage);
      }
    };
    setUserImageByContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isekaiBattle]);

  function openMypage() {
    setIsOpenMypage(true);
  }

  function closeMypage() {
    setIsOpenMypage(false);
  }

  return (
    <>
      <header class="header flex justify-between bg-brown px-2 pb-0 pt-8">
        <h1>
          <Link to="/">
            <img src={logo} alt="Isekai Battle" />
          </Link>
        </h1>
        <div class="relative -top-2 flex gap-2">
          <div class="user-status__coin">
            <img src={coin} alt="" class="relative" />
            <span>{formatBalance(accountBalance)}</span>
          </div>
          <div>
            <a href="#" onClick={openMypage}>
              <img src={userImage} alt="" class="w-8" />
            </a>
          </div>
        </div>
      </header>
      {isOpenMypage && (
        <MyPage
          onClose={closeMypage}
          userName={userName}
          userImage={userImage}
          account={account}
          setUserImage={setUserImage}
        />
      )}
    </>
  );
}
