import Master from "../../layouts/master";
import ExploreTab from "./components/explore-tab";
import AttackTab from "./components/attack-tab";
import DefenceTab from "./components/defence-tab";
import { useEffect, useState } from "react";
import {
  useApiContext,
  useFormationContext,
  useGameContext,
  useLangContext,
} from "../../../context";
import ButtonTab from "./components/button-tab";
import UnitsSelect from "./components/units-select";
import UnitsConfirm from "./components/units-confirm";
import { sound_effect } from "../../common/SoundEffect";
import settings from "../../../config/settings";
import MdBox from "./components/md-box";
import UnitsTransaction from "./components/units-transaction";
import Loading from "../../common/loading";

export default function Units(props) {
  const [currentTab, setCurrentTab] = useState("explore");
  const { langValue } = useLangContext();
  const {
    formation,
    normalizedFormation,
    saveFormation,
    normalizedSavedFormation,
    setFormationCharacter,
  } = useFormationContext();
  const { account, getFeeData } = useApiContext();
  const { isekaiBattle, isekaiBattleStake } = useGameContext();
  const [unitSelecting, setUnitSelecting] = useState(false);
  const [formationTarget, setFormationTarget] = useState({});
  const [stakeApproved, setStakeApproved] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [unitConifrm, setUnitConifrm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [removeUnit, setRemoveUnit] = useState({
    remove: false,
    chara: null,
    group: null,
    index: null,
  });
  const [staking, setStaking] = useState({
    step: 1,
    status: false,
  });
  const [currentScroll, setCurrentScroll] = useState(0);

  function isNotChanged() {
    return (
      JSON.stringify(normalizedFormation.explore) ===
        JSON.stringify(normalizedSavedFormation.explore) &&
      JSON.stringify(normalizedFormation.attack) ===
        JSON.stringify(normalizedSavedFormation.attack) &&
      JSON.stringify(normalizedFormation.defence) ===
        JSON.stringify(normalizedSavedFormation.defence) &&
      JSON.stringify(normalizedFormation.attack) ===
        JSON.stringify(normalizedSavedFormation.attack) &&
      JSON.stringify(normalizedFormation.defence) ===
        JSON.stringify(normalizedSavedFormation.defence)
    );
  }

  async function openClick() {
    if (isNotChanged()) {
      return;
    }
    sound_effect("positive");
    setFormationTarget({});
    if (approveLoading) {
      return;
    }

    setStakeApproved(false);
    setApproveLoading(true);

    if (
      JSON.stringify(normalizedFormation.explore.ordina_planes) !==
        JSON.stringify(normalizedSavedFormation.explore.ordina_planes) ||
      JSON.stringify(normalizedFormation.explore.dagur_cave) !==
        JSON.stringify(normalizedSavedFormation.explore.dagur_cave) ||
      JSON.stringify(normalizedFormation.attack) !==
        JSON.stringify(normalizedSavedFormation.attack) ||
      JSON.stringify(normalizedFormation.defence) !==
        JSON.stringify(normalizedSavedFormation.defence)
    ) {
      const approve = await isekaiBattle.methods
        .isApprovedForAll(account, settings.addresses.Staking)
        .call();
      if (approve) {
        setStakeApproved(true);
      } else {
        const methodData = isekaiBattle.methods.setApprovalForAll(
          settings.addresses.Staking,
          true,
        );
        const { executionGasFee, maxPriorityFeePerGas, maxFeePerGas } =
          await getFeeData();
        const gasLimit = executionGasFee(
          await methodData.estimateGas({
            from: account,
          }),
        );

        await methodData
          .send({
            from: account,
            maxPriorityFeePerGas,
            maxFeePerGas,
            gasLimit,
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
          })
          .once("confirmation", function () {
            setStakeApproved(true);
          })
          .catch((x) => {
            console.log(x);
            setApproveLoading(false);
          });
      }
    } else {
      setStakeApproved(true);
    }
  }

  useEffect(() => {
    if (approveLoading && stakeApproved) {
      setUnitConifrm(true);
      setApproveLoading(false);
    }
  }, [approveLoading, stakeApproved]);

  const onSelectUnits = (group, index, chara) => {
    if (chara) {
      setRemoveUnit((prev) => ({
        ...prev,
        remove: true,
        chara,
        group,
        index,
      }));
    } else {
      setUnitSelecting(true);
      setFormationTarget({ group, index });
    }
  };

  function cancelConfirm() {
    sound_effect("negative");
    setUnitConifrm(false);
  }

  async function okConfirm() {
    setUnitConifrm(false);
    if (loading) return;
    if (
      JSON.stringify(normalizedFormation.explore) ===
        JSON.stringify(normalizedSavedFormation.explore) &&
      JSON.stringify(normalizedFormation.attack) !==
        JSON.stringify(normalizedSavedFormation.attack) &&
      JSON.stringify(normalizedFormation.defence) !==
        JSON.stringify(normalizedSavedFormation.defence)
    ) {
      await saveFormation();
      return;
    }
    setLoading(true);
    if (
      JSON.stringify(normalizedFormation.explore.ordina_planes) !==
        JSON.stringify(normalizedSavedFormation.explore.ordina_planes) ||
      JSON.stringify(normalizedFormation.explore.dagur_cave) !==
        JSON.stringify(normalizedSavedFormation.explore.dagur_cave) ||
      JSON.stringify(normalizedFormation.attack) !==
        JSON.stringify(normalizedSavedFormation.attack) ||
      JSON.stringify(normalizedFormation.defence) !==
        JSON.stringify(normalizedSavedFormation.defence)
    ) {
      setStaking((prev) => ({
        ...prev,
        step: 1,
        status: true,
      }));

      const stakeTokenIds = [
        [...formation.dagur_cave.filter((x) => x)],
        [...formation.ordina_planes.filter((x) => x)],
      ];
      const stakes = [];
      for (let i = 0; i < stakeTokenIds.length; i++) {
        stakes.push([i, stakeTokenIds[i]]);
      }

      try {
        const methodData = isekaiBattleStake.methods.stake(
          stakes,
          [...formation.attack.filter((x) => x)],
          [...formation.defence.filter((x) => x)],
        );
        const { executionGasFee, maxPriorityFeePerGas } = await getFeeData();
        const gasLimit = executionGasFee(
          await methodData.estimateGas({
            from: account,
          }),
        );

        await methodData
          .send({
            from: account,
            maxPriorityFeePerGas,
            gasLimit,
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
          })
          .once("confirmation", async () => {
            setStaking((prev) => ({
              ...prev,
              step: 2,
              status: true,
            }));
            setTimeout(() => {
              setStaking((prev) => ({
                ...prev,
                step: 3,
                status: true,
              }));
            }, 5000);
          })
          .catch((x) => {
            setLoading(false);
            console.log(x);
            setStaking((prev) => ({
              ...prev,
              step: 1,
              status: false,
            }));
          });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  }

  const onRemoveUnit = () => {
    setFormationCharacter(removeUnit.group, removeUnit.index, null);
    setRemoveUnit((prev) => ({
      remove: false,
    }));
  };

  return (
    <>
      <Master>
        {staking.status && (
          <UnitsTransaction
            staking={staking}
            onClose={() => {
              setStaking((prev) => ({
                ...prev,
                step: 1,
                status: false,
              }));
              window.location.reload();
            }}
          />
        )}
        {unitConifrm && (
          <UnitsConfirm onCancel={cancelConfirm} okConfirm={okConfirm} />
        )}
        {unitSelecting && (
          <UnitsSelect
            onClose={() => setUnitSelecting(false)}
            formationTarget={formationTarget}
            currentScroll={currentScroll}
            setCurrentScroll={setCurrentScroll}
          />
        )}
        {!unitConifrm && !unitSelecting && !staking.status && (
          <div class="flex flex-1 flex-col overflow-hidden bg-neutral-100">
            {currentTab === "explore" && (
              <div class="bg-gradient-to-tr from-[#25460E] to-[#132704] p-3 text-xl tracking-wider text-white">
                {langValue === "ja" && <>編成変更</>}
                {langValue === "en" && <>Formation</>}
              </div>
            )}
            {currentTab === "attack" && (
              <div class="bg-gradient-to-tr from-[#4D1200] to-[#3D0F00] p-3 text-xl tracking-wider text-white">
                {langValue === "ja" && <>編成変更</>}
                {langValue === "en" && <>Formation</>}
              </div>
            )}
            {currentTab === "defence" && (
              <div class="bg-gradient-to-tr from-[#001737] to-[#001D45] p-3 text-xl tracking-wider text-white">
                {langValue === "ja" && <>編成変更</>}
                {langValue === "en" && <>Formation</>}
              </div>
            )}
            <div class="flex flex-col overflow-hidden">
              <ul class="flex">
                <li class="grow">
                  <ButtonTab
                    name="explore"
                    label="Exploration"
                    setCurrentTab={setCurrentTab}
                    active={currentTab === "explore"}
                  />
                </li>
                <li class="grow">
                  <ButtonTab
                    name="attack"
                    label="Attack"
                    setCurrentTab={setCurrentTab}
                    active={currentTab === "attack"}
                  />
                </li>
                <li class="grow">
                  <ButtonTab
                    name="defence"
                    label="Defence"
                    setCurrentTab={setCurrentTab}
                    active={currentTab === "defence"}
                  />
                </li>
              </ul>

              <div class="overflow-scroll p-4 text-black">
                {currentTab === "explore" && (
                  <ExploreTab onSelectUnits={onSelectUnits} />
                )}
                {currentTab === "attack" && (
                  <AttackTab onSelectUnits={onSelectUnits} />
                )}
                {currentTab === "defence" && (
                  <DefenceTab onSelectUnits={onSelectUnits} />
                )}
                <div class="sticky bottom-0 z-10 mt-10">
                  <button
                    class="mt-2 block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
                    onClick={openClick}
                  >
                    {langValue === "ja" && <>部隊編成を完了する</>}
                    {langValue === "en" && <>Confirm Units</>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {removeUnit.remove && (
          <MdBox
            title={
              langValue === "ja"
                ? `${
                    removeUnit.chara.attributes.find(
                      (o) => o.trait_type === "Name",
                    ).value
                  }を外しますか？`
                : `Do you want to remove ${
                    removeUnit.chara.attributes.find(
                      (o) => o.trait_type === "Name",
                    ).value
                  }?`
            }
            onYes={onRemoveUnit}
            onNo={() => {
              setRemoveUnit((prev) => ({
                remove: false,
              }));
            }}
          />
        )}
        {loading && <Loading />}
      </Master>
    </>
  );
}
