function chainNameToDigit(name) {
  switch (name) {
    case "mainnet":
      return 1;
    case "goerli":
      return 5;
    case "optimism-devnet":
      return 901;
    case "dev":
      return 1337;
    case "hardhat":
      return 31337;
    case "sepolia-optimistic":
      return 11155420;
    default:
      return undefined;
  }
}

function chainHexToName(hex) {
  switch (hex) {
    case "0x1":
      return "mainnet";
    case "0x5":
      return "goerli";
    case "0x385":
      return "optimism-devnet";
    case "0x539":
      return "dev";
    case "0x7a69":
      return "hardhat";
    case "0xaa37dc":
      return "sepolia-optimistic";
    default:
      return "";
  }
}

module.exports = {
  chainNameToDigit,
  chainHexToName,
};
