import {
  useApiContext,
  useCharacterContext,
  useFormationContext,
  useItemContext,
  useLangContext,
} from "../../../../context";
import icoExclamation from "../../../../images/ico_exclamation.svg";
import icoHelmetDark from "../../../../images/ico_helmet_dark.svg";
import icoSwordDark from "../../../../images/ico_sword_dark.svg";
import icoSeedDark from "../../../../images/ico_seed_dark.svg";
import blankImg from "../../../../images/characters/blank.png";
import settings from "../../../../config/settings";
import { useEffect, useState } from "react";

export default function AttackTab(props) {
  const { langValue } = useLangContext();
  const { characters } = useCharacterContext();
  const { formation, getFormationCharacter } = useFormationContext();
  const { weapons, setWeapons } = useItemContext();
  const { account, Web3Api, chainId, chainName } = useApiContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (chainId) {
      fetchNFTsForContract();
      setLoading(true);
    }
  }, [chainId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNFTsForContract = async () => {
    const chain = chainName(chainId);
    const options = {
      chain,
      address: account,
      token_address: settings.addresses.IsekaiBattleWeapon,
    };
    const _weapons = await Web3Api.account.getNFTsForContract(options);
    setWeapons(
      _weapons.result.map((x) => {
        x.category = "weapons";
        x.image = JSON.parse(x.metadata).image;
        x.lv = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.star = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.type = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Type",
        ).value;
        return x;
      }),
    );

    setLoading(false);
  };

  // console.log("formation", formation, "weapons", weapons)
  function currentWeapons() {
    let a = 0;
    let b = 0;

    return weapons
      .sort((x, y) => {
        const a = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(x.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        const b = characters
          .filter((x) => formation.attack.includes(x.id))
          .map((x) => x.weaponType)
          .filter(
            (z) =>
              z ===
              JSON.parse(y.metadata).attributes.find(
                (x) => x.trait_type === "Type",
              ).value,
          ).length;
        if (
          JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value !==
          JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
            .value
        ) {
          return (
            JSON.parse(y.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value -
            JSON.parse(x.metadata).attributes.find((x) => x.trait_type === "Lv")
              .value
          );
        }
        if (a !== b) {
          return b - a;
        }
        return 0;
      })
      .filter((x) => {
        const res =
          a <
          30 +
            10 * formation.attack.filter((x) => x).length +
            formation.attack.filter((x) => x).length;
        a += Number.parseInt(x.amount);
        return res;
      })
      .map((el, index) => {
        b += Number.parseInt(el.amount);
        const res =
          b <
          30 +
            10 * formation.attack.filter((x) => x).length +
            formation.attack.filter((x) => x).length;
        return {
          ...el,
          amount: res
            ? Number.parseInt(el.amount)
            : 30 +
              10 * formation.attack.filter((x) => x).length +
              formation.attack.filter((x) => x).length -
              (b - Number.parseInt(el.amount)),
        };
      });
  }

  function getScore(lv) {
    switch (lv) {
      case 1:
        return 150;
      case 2:
        return 175;
      case 3:
        return 200;
      default:
        break;
    }
  }

  function getATKFromWeaponType(type) {
    const target = characters
      .filter((x) => formation.attack.includes(x.id))
      .filter((x) => x.weaponType === type)
      .map((x) => x.attack);
    return target.length > 0 ? target.reduce((x, y) => x + y) : 0;
  }

  function totalTroops() {
    let total = 30;
    const troops = characters.filter((x) => formation.attack.includes(x.id));
    if (troops.length) {
      total +=
        10 * troops.length +
        troops.map((x) => (x.level === 10 ? 2 : 1))?.reduce((x, y) => x + y);
    }
    return total;
  }

  const totalWeapons = () => {
    if (weapons.length === 0) return 0;
    return weapons.map((x) => Number(x.amount)).reduce((x, y) => x + y);
  };

  return (
    <>
      <div>
        <h2 class="flex justify-between text-xl">
          {langValue === "ja" && <>攻撃編成</>}
          {langValue === "en" && <>Attack Unit</>}
        </h2>
        <ul class="mt-4 grid grid-cols-5 items-start gap-2">
          {Array.from({ length: 5 }, (_, k) => k).map((index) => {
            const chara = getFormationCharacter("attack", index);
            return (
              <li
                class="rounded bg-gradient-to-br from-[#88814B] via-[#CDC986] to-[#88814B] p-0.5"
                key={index}
                onClick={() => props.onSelectUnits("attack", index, chara)}
              >
                <div class="flex items-center justify-center rounded-sm bg-gradient-to-b from-[#8A834F] via-[#DBD499] to-[#EFEDD7]">
                  {chara ? (
                    <img src={chara.image} alt={chara.name} class="w-18" />
                  ) : (
                    <img src={blankImg} alt="" class="w-18" />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div class="mt-10">
        <h2 class="flex justify-between text-xl">
          {langValue === "ja" && <>使用武器</>}
          {langValue === "en" && <>Weapons equipped</>}
        </h2>

        {totalWeapons() < totalTroops() && loading === false && (
          <div class="mt-2 flex items-center text-sm text-red-500">
            <img src={icoExclamation} alt="" class="mr-2" />
            {langValue === "ja" && (
              <>使用している武器数が部隊数よりも少なくなっています。</>
            )}
            {langValue === "en" && (
              <>You do not have enough Weapons for your troops.</>
            )}
          </div>
        )}

        <div class="mt-8 border-t border-gray-200 pt-8">
          <div class="grid grid-cols-2 gap-4">
            <div class="text-center">
              <div class="flex justify-center gap-2">
                <img src={icoHelmetDark} alt="" />
                {langValue === "ja" && <>部隊数</>}
                {langValue === "en" && <>Number of troops</>}
              </div>
              <div class="ftracking-wider mt-4 text-2xl">{totalTroops()}</div>
            </div>
            <div class="text-center">
              <div class="flex justify-center gap-2">
                <img src={icoSeedDark} alt="" />
                {langValue === "ja" && <>ATKボーナス</>}
                {langValue === "en" && <>ATK bonus</>}
              </div>
              <div class="ftracking-wider mt-4 text-2xl text-red-600">
                +
                {currentWeapons().length > 0
                  ? currentWeapons()
                      .map((x) => {
                        const metadata = JSON.parse(x.metadata);
                        const atk = getATKFromWeaponType(
                          metadata.attributes.find(
                            (y) => y.trait_type === "Type",
                          ).value,
                        );
                        const score = getScore(
                          metadata.attributes.find((z) => z.trait_type === "Lv")
                            .value,
                        );
                        return Math.floor(score * atk * 0.005 * x.amount);
                      })
                      .reduce((x, y) => x + y)
                  : 0}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-4">
            <div class="text-center">
              <div class="flex justify-center gap-2">
                <img src={icoSwordDark} alt="" />
                {langValue === "ja" && <>総合攻撃力</>}
                {langValue === "en" && <>Total attack power</>}
              </div>
              <div class="ftracking-wider mt-4 text-2xl">
                {currentWeapons().length > 0
                  ? currentWeapons()
                      .map(
                        (x) =>
                          Number.parseInt(x.amount) *
                          getScore(
                            JSON.parse(x.metadata).attributes.find(
                              (z) => z.trait_type === "Lv",
                            ).value,
                          ),
                      )
                      .reduce((x, y) => x + y)
                  : 0}{" "}
                +{" "}
                {currentWeapons().length > 0 &&
                formation.attack.filter((x) => x).length > 0
                  ? Math.floor(
                      currentWeapons()
                        .map((x) => {
                          const metadata = JSON.parse(x.metadata);
                          const atk = getATKFromWeaponType(
                            metadata.attributes.find(
                              (y) => y.trait_type === "Type",
                            ).value,
                          );
                          const score = getScore(
                            metadata.attributes.find(
                              (z) => z.trait_type === "Lv",
                            ).value,
                          );
                          return Math.floor(score * atk * 0.005 * x.amount);
                        })
                        .reduce((x, y) => x + y),
                    )
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
