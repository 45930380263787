import { useEffect, useMemo, useState } from "react";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../context";
import Master from "../../layouts/master";
import moment from "moment";
import settings from "../../../config/settings";
import { Link, useNavigate, useParams } from "react-router-dom";
import icoMypage from "../../../images/ico_mypage.png";
import ico_cross_sword from "../../../images/ico_cross_sword.svg";
import ico_seed_white from "../../../images/ico_seed_white.svg";
import ico_cross_sword_black from "../../../images/ico_cross_sword_black.svg";
import ico_seed_black from "../../../images/ico_seed_black.svg";
import ico_arrow_right from "../../../images/ico_arrow_right.svg";
import { fragmentImageIds, fragmentInfos } from "../../../data/regionInfo";
import Loading from "../../common/loading";

export default function Battle(props) {
  const { fragmentId } = useParams();
  const { langValue } = useLangContext();
  const { account, Web3Api, targetChainId, chainName, getFeeData } =
    useApiContext();
  const {
    isekaiBattleClash,
    setMatchStatus,
    loadGameInfo,
    nextBattleTimes,
    isekaiBattleStake,
  } = useGameContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [enemies, setEnemies] = useState([]);
  const [myData, setMyData] = useState({});
  const [timer, setTimer] = useState("00:59");
  const [matching, setMatching] = useState(false);
  const [myFragmentData, setMyFragmentData] = useState([]);
  const [defender, setDefender] = useState(null);

  useEffect(() => {
    loadRival();
    let rivalUpdateUntil = moment().add(59, "minutes");
    const interval = setInterval(() => {
      const msLeft = rivalUpdateUntil.diff(new Date());
      const duration = moment.duration(msLeft);
      const mins = duration.minutes().toString();
      setTimer("00:" + mins.padStart(2, "0"));
      if (mins <= 0) {
        rivalUpdateUntil = moment().add(59, "minutes");
        loadRival();
      }
    }, 1000 * 30);

    return () => {
      clearInterval(interval);
    };
  }, [fragmentId]);

  const loadRival = async () => {
    if (!fragmentId && loading) {
      return;
    }
    setLoading(true);
    let data = await Web3Api.game.getListEnemies({
      fragmentId,
      address: settings.addresses.IsekaiBattleFragment,
      chainId: parseInt(targetChainId),
    });
    const enemies = data.map((o) => o.address);
    enemies.push(account);
    const { result: userImages } = await Web3Api.game.getBatchUserImage({
      addresses: enemies,
    });

    data = data.map((obj) => {
      const userInfo = userImages.find((u) => u.address == obj.address);
      let userImage = {};
      if (userInfo) {
        userImage = {
          image: userInfo.metadata.image,
        };
      } else {
        userImage = {
          image: icoMypage,
        };
      }
      return {
        ...obj,
        userImage,
      };
    });
    setEnemies(data);

    // My data
    let myData = await Web3Api.game.getGameMyData({
      account,
      address: settings.addresses.IsekaiBattle,
      chainId: parseInt(targetChainId),
    });
    if (myData) {
      const userInfo = userImages.find((u) => u.address == myData.address);
      let userImage = {};
      if (userInfo) {
        userImage = {
          image: userInfo.metadata.image,
        };
      } else {
        userImage = {
          image: icoMypage,
        };
      }
      myData = {
        ...myData,
        userImage,
      };
      setMyData((prev) => ({
        ...prev,
        ...myData,
      }));
    }

    setLoading(false);
  };

  const fragmentIds = useMemo(() => {
    const fgmTypes = parseInt((Number(fragmentId) + 7) / 7);
    const minId = fgmTypes * 7 - 7;
    const maxId = fgmTypes * 7 - 1;
    return Array.from({ length: maxId - minId + 1 }, (_, i) => minId + i);
  }, [fragmentId]);

  useEffect(() => {
    if (fragmentIds.length) {
      (async () => {
        const fragments = await Web3Api.game.getMyFragment({
          account,
          address: settings.addresses.IsekaiBattleFragment,
          chainId: targetChainId,
          fragmentIds,
        });
        const _data = fragmentIds.map((id) => {
          const fragment = fragments.find((o) => o.id == id);
          return {
            id,
            exists: fragment,
          };
        });
        setMyFragmentData(_data);
      })();
    }
  }, [fragmentIds]);

  useEffect(() => {
    if (isekaiBattleStake && isekaiBattleStake.methods) {
      (async () => {
        const stakingInfoIds = await isekaiBattleStake.methods
          .getTroops(account)
          .call({ from: account });

        const attackIds = stakingInfoIds[0].map((o) => parseInt(o, 10));
        if (attackIds && attackIds.length) {
          if (attackIds.length > 0) {
            let metadatas = await Web3Api.token.getBatchTokenIdMetadata({
              address: settings.addresses.IsekaiBattle,
              chain: chainName(targetChainId),
              token_ids: attackIds,
            });
            metadatas = metadatas.map((o) => {
              return {
                ...JSON.parse(o.metadata),
              };
            });
            setMyData((prev) => ({
              ...prev,
              attack: metadatas,
            }));
          }
        }
      })();
    }
  }, [isekaiBattleStake]);

  const onClickChallenge = async () => {
    if (matching) return;
    setMatching(true);
    let _defender;
    if (defender) {
      _defender = defender;
    } else {
      const _enemies = enemies.map((o) => o.address);
      _defender = _enemies[Math.floor(Math.random() * _enemies.length)];
    }
    try {
      const methodData = isekaiBattleClash.methods.matching(
        fragmentId,
        _defender,
      );
      const { executionGasFee, maxPriorityFeePerGas, maxFeePerGas } =
        await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          maxFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          const { attacker, defender, fragmentId, status } =
            receipt.events.Matched.returnValues;
          Web3Api.game.gameMatched({
            attacker,
            defender,
            fragmentId,
            status,
            transactionHash: receipt.transactionHash,
          });
          setMatchStatus(status);
          loadGameInfo();
          navigate(`/battle/${fragmentId}/${defender}`);
        });
    } catch (error) {
      console.error(error);
    }
    setMatching(false);
  };

  const onClickListEnemy = (fragment) => {
    if (fragment.exists) return;
    navigate(`/battle/${fragment.id}`);
  };

  const gameAvailable = useMemo(() => {
    if (nextBattleTimes > new Date().valueOf() / 1000) {
      return false;
    }
    return enemies.length > 0 && myData.attack?.length > 0;
  }, [nextBattleTimes, enemies, myData]);

  const nextGameTime = useMemo(() => {
    const elapsedTime =
      Number.parseInt(nextBattleTimes) - Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);
    if (elapsedTime < 0) {
      return false;
    }
    return `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`;
  }, [nextBattleTimes]);

  return (
    <Master>
      <div class="relative flex flex-1 flex-col overflow-scroll bg-neutral-100">
        <div class="battle flex h-screen flex-col overflow-hidden">
          <div class="flex flex-1 flex-col overflow-auto">
            <div class="bg-black bg-opacity-20 p-3 text-xl tracking-wider text-white">
              {langValue === "ja" && <>バトル</>}
              {langValue === "en" && <>Battle</>}
            </div>
            <div class="overflow-auto">
              <div class="mx-2 mb-8 mt-4">
                <ul class="mx-2 flex justify-end gap-4 text-xs text-white">
                  <li class="flex gap-2">
                    <Link
                      to={`/battle/history/${fragmentId}`}
                      class="flex gap-2"
                    >
                      {langValue === "ja" && <>戦闘履歴</>}
                      {langValue === "en" && <>Battle Record</>}
                      <img src={ico_arrow_right} alt="" />
                    </Link>
                  </li>
                </ul>

                <ul class="mx-2 mt-2 flex justify-center gap-1">
                  {myFragmentData.map((fragment, key) => {
                    return (
                      <li
                        class={`stone ${fragment.id == fragmentId && "stone--selected"}`}
                        key={key}
                        onClick={() => onClickListEnemy(fragment)}
                      >
                        <span>
                          <img
                            src={fragmentImageIds[fragment.id]}
                            class={`${!fragment.exists && "brightness-75 grayscale"}`}
                            alt=""
                          />
                        </span>
                      </li>
                    );
                  })}
                </ul>

                <div class="battle__steal-target mx-2 mt-4 rounded-lg p-1">
                  <div class="rounded p-2 text-center text-black">
                    <img
                      src={fragmentInfos[fragmentId]?.image}
                      alt=""
                      class="mx-auto w-20"
                    />
                    <p class="text-xs">
                      {langValue === "ja" && <>争奪するフラグメント</>}
                      {langValue === "en" && <>Target Fragment</>}
                    </p>
                    <p class="text-xl">
                      {langValue === "ja" && (
                        <>{fragmentInfos[fragmentId]?.name_ja}</>
                      )}
                      {langValue === "en" && (
                        <>{fragmentInfos[fragmentId]?.name_en}</>
                      )}
                    </p>
                  </div>
                </div>

                <div class="mx-2 mt-8">
                  <div class="flex justify-between border-b border-white border-opacity-70 text-white">
                    <div>
                      {langValue === "ja" && <>ライバル</>}
                      {langValue === "en" && <>Rival</>}
                    </div>
                    <div class="text-xs">
                      {langValue === "ja" && <>ライバルの更新まで</>}
                      {langValue === "en" && <>Update on rivals</>}
                      <span class="ml-2">{timer}</span>
                    </div>
                  </div>

                  {enemies.length === 0 ? (
                    <>
                      <ul class="mt-4">
                        <li class="flex h-12 text-white">
                          {langValue === "ja" && (
                            <>
                              このフラグメントを所持しているライバルがいません
                            </>
                          )}
                          {langValue === "en" && (
                            <>No rivals own this Fragment.</>
                          )}
                        </li>
                        <li className={`flex h-12`}></li>
                        <li className={`flex h-12`}></li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul class="mt-4 grid grid-cols-2">
                        {Array.from({ length: 6 }, (_, i) => {
                          const enemy = enemies[i];
                          if (!enemy) {
                            return <li className={`flex h-12`} key={i}></li>;
                          }
                          return (
                            <li
                              className={`flex ${defender === enemy.address && "bg-neutral-900/75"}`}
                              onClick={() => {
                                if (targetChainId === 10) return;
                                setDefender(
                                  enemy.address === defender
                                    ? null
                                    : enemy.address,
                                );
                              }}
                              key={i}
                            >
                              <div class="flex items-center gap-1">
                                <img
                                  src={enemy.userImage.image}
                                  alt=""
                                  class="w-16 scale-x-[-1]"
                                />
                                <div class="mt-3 text-white">
                                  <div class="flex gap-2">
                                    <img
                                      src={ico_seed_white}
                                      alt=""
                                      class="w-3.5"
                                    />
                                    <span class="text-sm">
                                      {enemy.usedSeed}
                                    </span>
                                  </div>
                                  <div class="flex gap-2">
                                    <img
                                      src={ico_cross_sword}
                                      alt=""
                                      class="w-3.5"
                                    />
                                    <span class="whitespace-nowrap text-sm">
                                      {langValue === "ja" && (
                                        <>
                                          {enemy.wins}勝{enemy.loses}敗
                                        </>
                                      )}
                                      {langValue === "en" && (
                                        <>
                                          {enemy.wins}Wins {enemy.loses}Losses
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </div>

                <div class="mx-2 mt-4">
                  <div class="flex justify-between text-white">
                    <div class="rounded-tl rounded-tr bg-[#8E812D] px-1 pt-0.5 text-xs">
                      {langValue === "ja" && <>マイデータ</>}
                      {langValue === "en" && <>My data</>}
                    </div>
                  </div>
                  <div class="flex items-center justify-between gap-1 rounded rounded-tl-none bg-[#E0DED3] p-2 text-black">
                    <ul class="flex">
                      {myData.attack?.map((item, key) => {
                        return (
                          <li key={key}>
                            <img src={item.image} alt="" class="w-11" />
                          </li>
                        );
                      })}
                    </ul>
                    <div>
                      <div class="flex gap-2">
                        <img src={ico_seed_black} alt="" class="w-4" />
                        <span>{myData.usedSeed}</span>
                      </div>
                      <div class="flex gap-2">
                        <img src={ico_cross_sword_black} alt="" class="w-4" />
                        <span class="whitespace-nowrap">
                          {langValue === "ja" && (
                            <>
                              {myData.wins}勝{myData.loses}敗
                            </>
                          )}
                          {langValue === "en" && (
                            <>
                              {myData.wins}Win {myData.loses}Lose
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sticky bottom-2 z-10 mx-4 mt-6">
                  <h2 className="mt-4 flex justify-center text-base text-white">
                    {nextGameTime && (
                      <>
                        {langValue === "ja" && <>次のバトルまで</>}
                        {langValue === "en" && <>Battle Cooldown </>}
                        <span>{nextGameTime}</span>
                      </>
                    )}
                  </h2>
                  <button
                    class={`block w-full rounded-full p-3 text-center text-lg tracking-widest text-black ${!gameAvailable ? "bg-gray" : "bg-white"}`}
                    onClick={() => {
                      if (!gameAvailable) return;
                      onClickChallenge();
                    }}
                  >
                    {langValue === "ja" && <>ライバルに挑む</>}
                    {langValue === "en" && <>Challenge your rival</>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {matching && <Loading />}
    </Master>
  );
}
