import { useEffect, useMemo, useState } from "react";
import {
  useApiContext,
  useCharacterContext,
  useGameContext,
  useItemContext,
  useLangContext,
} from "../../../../context";
import { sound_effect } from "../../../common/SoundEffect";
import ItemSeedSynthesisConfirm from "./item-seed-synthesis-confirm";
import { toNumber } from "../../../../util";
import Web3 from "web3/dist/web3.min";
import ItemSeedSynthesisFinish from "./item-seed-synthesis-finish";

import btn_close from "../../../../images/btn_close.svg";
import power from "../../../../images/seeds/power.png";
import defence from "../../../../images/seeds/defence.png";
import luck from "../../../../images/seeds/luck.png";
import power_fit from "../../../../images/seeds/power_fit.png";
import defence_fit from "../../../../images/seeds/defence_fit.png";
import luck_fit from "../../../../images/seeds/luck_fit.png";
import ico_magiccircle from "../../../../images/ico_magiccircle.png";
import Loading from "../../../common/loading";

const seedImages = {
  ATK: power,
  DEF: defence,
  LUK: luck,
};

const seedFitImages = {
  ATK: power_fit,
  DEF: defence_fit,
  LUK: luck_fit,
};

export default function ItemSeedSynthesis(props) {
  const {
    setSelectedSeed,
    selectedSeed,
    setSelectedCharacter,
    selectedCharacter,
  } = props;
  const { langValue } = useLangContext();
  const { seeds } = useItemContext();
  const { getAllCharacters } = useCharacterContext();
  const { isekaiBattleMagicCircle, isekaiBattleStatusManager } =
    useGameContext();
  const [selectedCount, setSelectedCount] = useState(1);
  const [openSeedsSynthesisConfirm, setOpenSeedsSynthesisConfirm] =
    useState(false);
  const { account, user } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [fusedSeedSuccess, setFusedSeedSuccess] = useState(false);
  const [mixSeed, setMixSeed] = useState();

  const items = seeds.filter((s) => s.lv === 1);

  const genMap = {
    GEN0: 0,
    "GEN0.5": 0.5,
    GEN1: 1,
  };

  const characterList = getAllCharacters()
    .map((chara) => {
      return {
        id: chara.id,
        name: chara.name.split(" ")[0],
        number: chara.name.split(" ")[1],
        gen: chara.gen,
      };
    })
    .sort((a, b) => {
      const genA = genMap[a.gen];
      const genB = genMap[b.gen];
      const d = genA - genB;
      return d === 0 ? a.id - b.id : d;
    });

  const [magicCircle, setMagicCircle] = useState();
  // [+2, +1, +0]
  const defaultPercentage = [10, 20, 70];
  const [percentage, setPercentage] = useState(defaultPercentage);

  useEffect(
    () => {
      if (
        isekaiBattleMagicCircle.methods &&
        isekaiBattleStatusManager.methods
      ) {
        (async () => {
          const mgcBalance = await isekaiBattleMagicCircle.methods
            .balanceOf(account, 0)
            .call({ from: account });
          setMagicCircle(mgcBalance);

          let ratio = await isekaiBattleStatusManager.methods
            .getMagicCircleExtraRatioForUser(account)
            .call({ from: account });
          ratio = [ratio[0], ratio[1]].map((x) => parseInt(x, 10) / 10000);
          setPercentage([...ratio, 100 - (ratio[1] + ratio[0])]);
        })();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isekaiBattleMagicCircle, isekaiBattleStatusManager],
  );

  const onChangeCharacter = (e) => {
    setSelectedCharacter(
      characterList.find((o) => o.id === Number(e.target.value)),
    );
  };

  const levels = useMemo(() => {
    if (selectedCount > 8) {
      return [8, 9, 10];
    } else if (selectedCount === 1) {
      return [2, 3, 4];
    } else {
      return [selectedCount, selectedCount + 1, selectedCount + 2];
    }
  }, [selectedCount]);

  const canSynthesis = useMemo(() => selectedCount >= 2, [selectedCount]);

  const genEthPrice = {
    GEN0: "0.009",
    "GEN0.5": "0.0095",
    GEN1: "0.01",
  };

  const ethPrice = useMemo(
    () => {
      if (!selectedCharacter) {
        return "";
      }
      return genEthPrice[selectedCharacter.gen];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCharacter],
  );

  const onConfirm = () => {
    if (!selectedCharacter) {
      setSelectedCharacter(characterList[0]);
    }
    setOpenSeedsSynthesisConfirm(true);
  };

  const onSynthesisSeedConfirm = async () => {
    if (loading) return;
    setLoading(true);
    let tokenId = selectedCharacter && selectedCharacter.id;
    if (tokenId == null) {
      setLoading(false);
      throw new Error("Missing tokenId");
    }
    let seedId = selectedSeed && selectedSeed.id;
    if (seedId == null) {
      setLoading(false);
      throw new Error("Missing seedId");
    }
    let count = selectedCount;
    if (!(count && count >= 2)) {
      setLoading(false);
      throw new Error("Invalid selectedCount");
    }

    tokenId = toNumber(tokenId);
    seedId = toNumber(seedId);
    count = toNumber(count);

    if (ethPrice === "") {
      setLoading(false);
      throw new Error("Missing ethPrice");
    }

    const options = {
      from: account,
      value: Web3.utils.toWei(ethPrice, "ether"),
    };

    isekaiBattleStatusManager.once(
      "MixSeed",
      {
        filter: {
          user: account,
        },
        fromBlock: "latest",
      },
      function (err, event) {
        if (err) {
          console.error(err);
          return;
        }
        console.log(event);
        setMixSeed(event.returnValues);
      },
    );

    isekaiBattleStatusManager.methods
      .mixSeed(tokenId, seedId, count)
      .estimateGas(Object.assign({}, options), (err, gas) => {
        if (err) {
          console.error(err);

          user.log(
            Object.assign(
              {
                api: "mixSeed",
                args: [tokenId, seedId, count],
                error: `${err}`,
              },
              options,
            ),
          );

          setLoading(false);
          return;
        }

        user.log(
          Object.assign(
            {
              api: "mixSeed",
              args: [tokenId, seedId, count],
            },
            options,
          ),
        );

        isekaiBattleStatusManager.methods
          .mixSeed(tokenId, seedId, count)
          .send(options)
          .on("receipt", function (receipt) {
            console.log(receipt);
          })
          .on("confirmation", (confirmationNumber) => {
            if (confirmationNumber >= 2) {
              setFusedSeedSuccess(true);
              setLoading(false);
              setOpenSeedsSynthesisConfirm(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
      });
  };

  return (
    <>
      <div className="items-seed-synthesis flex h-screen flex-col">
        <div class="relative flex flex-1 flex-col overflow-auto">
          <div class="flex-1 overflow-hidden">
            <div class="z-5 absolute left-0 top-0 h-full w-full">
              <button class="absolute right-4 top-5" onClick={props.closeSeeds}>
                <img src={btn_close} alt="" />
              </button>
              <div class="px-3 py-5 text-xl tracking-wider text-white">
                {langValue === "ja" && <>種の合成</>}
                {langValue === "en" && <>Fuse Seeds</>}
              </div>

              <div
                class="overflow-auto"
                style={{
                  height: "calc(100% - 68px)",
                }}
              >
                <div class="flex gap-2 px-2">
                  {items.map((item, key) => {
                    return (
                      <div
                        class={`rounded bg-purple-900 p-2 ${selectedSeed && selectedSeed.id === item.id ? "bg-opacity-80" : "bg-opacity-30"} text-center text-white`}
                        key={key}
                        onClick={() => {
                          setSelectedSeed(item);
                          setSelectedCount(1);
                          sound_effect("click");
                        }}
                        style={{
                          maxWidth: "33.33%",
                        }}
                      >
                        <img src={seedImages[item.type]} alt="" class="-my-4" />
                        <p class="text-sm">
                          {langValue === "ja" && (
                            <>
                              {item.type}の種 Lv{item.lv}
                            </>
                          )}
                          {langValue === "en" && (
                            <>
                              {item.type} Seed Lv{item.lv}
                            </>
                          )}
                        </p>
                        <span class="text-2xl">{item.amount}</span>
                      </div>
                    );
                  })}
                </div>

                <div class="mt-6 text-center text-white">
                  <p>
                    {langValue === "ja" && <>合成する数を選択</>}
                    {langValue === "en" && <>Select the number to combine</>}
                  </p>
                  <ul class="mx-4 flex justify-center">
                    {[
                      ...new Array(
                        Number(selectedSeed.amount) > 8
                          ? 8
                          : Number(selectedSeed.amount),
                      ),
                    ].map((_, key) => {
                      const count = key + 1;
                      return (
                        <li onClick={() => setSelectedCount(count)} key={key}>
                          <img
                            src={seedFitImages[selectedSeed.type]}
                            alt=""
                            class={`w-12 ${selectedCount < count && "brightness-200 grayscale"}`}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div class="mt-4 flex justify-center gap-6 text-white">
                  <div class="text-center">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[2]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[2]}
                      </>
                    )}
                    <p class="text-yellow-400">
                      <span class="mr-1 text-2xl">{percentage[0]}</span>%
                    </p>
                  </div>
                  <div class="text-center">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[1]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[1]}
                      </>
                    )}
                    <p class="text-yellow-400">
                      <span class="mr-1 text-2xl">{percentage[1]}</span>%
                    </p>
                  </div>
                  <div class="text-center">
                    {langValue === "ja" && (
                      <>
                        {selectedSeed.type}の種 Lv{levels[0]}
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        {selectedSeed.type} Seed Lv{levels[0]}
                      </>
                    )}
                    <p class="text-yellow-400">
                      <span class="mr-1 text-2xl">{percentage[2]}</span>%
                    </p>
                  </div>
                </div>

                <div class="mt-4 text-center text-white">
                  <p>
                    {langValue === "ja" && <>合成を行うキャラを選択</>}
                    {langValue === "en" && (
                      <>Select the Character who fuses the Seeds</>
                    )}
                  </p>
                  <select
                    class="mx-4 w-10/12 rounded border border-gray-100 bg-transparent p-2"
                    value={selectedCharacter ? selectedCharacter.id : ""}
                    onChange={onChangeCharacter}
                  >
                    {characterList.map((chara, i) => (
                      <option value={chara.id} key={i}>
                        {chara.name} - {chara.gen}
                      </option>
                    ))}
                  </select>
                </div>

                <div class="mx-4 mt-4 flex flex-col items-center rounded-sm bg-indigo-900 bg-opacity-30 p-4 text-white">
                  <p>
                    {langValue === "ja" && <>魔法陣のボーナス効果</>}
                    {langValue === "en" && <>Magic circle bonus effect</>}
                  </p>
                  <div class="flex gap-10">
                    <div class="flex items-center">
                      <img src={ico_magiccircle} alt="" class="mr-1 w-16" />×{" "}
                      <span class="ml-1 text-2xl">{magicCircle}</span>
                    </div>
                    <ul class="text-2xl">
                      <li>
                        +1 <span class="text-yellow-400">{percentage[1]}%</span>
                      </li>
                      <li>
                        +2 <span class="text-yellow-400">{percentage[0]}%</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mx-4 my-6 flex gap-2 pb-[120px]">
                  <button
                    class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
                    onClick={props.closeSeeds}
                  >
                    Cancel
                  </button>
                  <button
                    class={`block w-full rounded-full p-3 text-center text-lg tracking-widest text-black ${canSynthesis ? "bg-white" : "bg-gray"} `}
                    onClick={() => {
                      if (canSynthesis) {
                        onConfirm();
                      }
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openSeedsSynthesisConfirm && (
        <ItemSeedSynthesisConfirm
          selectedCharacter={selectedCharacter}
          selectedSeed={selectedSeed}
          selectedCount={selectedCount}
          levels={levels}
          percentage={percentage}
          ethPrice={ethPrice}
          onClose={() => setOpenSeedsSynthesisConfirm(false)}
          onConfirm={onSynthesisSeedConfirm}
        />
      )}
      {fusedSeedSuccess && (
        <ItemSeedSynthesisFinish
          selectedSeed={selectedSeed}
          onClose={() => props.closeSeeds(true)}
          mixSeed={mixSeed}
        />
      )}

      {loading && <Loading />}
    </>
  );
}
