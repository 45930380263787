import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const VolumeContext = React.createContext();

export function VolumeProvider({ children }) {
  const [bgmVolume, setBgmVolume] = useState(0);
  const [seVolume, setSeVolume] = useState(0);
  const [isMute, setIsMute] = useState(false);
  const [cookies, setCookie] = useCookies(["sound"]); // eslint-disable-line no-unused-vars

  function changeBgmVolume(num) {
    setCookie("bgm", num, { path: "/" });
    setBgmVolume(num);
  }

  function changeSeVolume(num) {
    setCookie("se", num, { path: "/" });
    setSeVolume(num);
  }

  function changeMute(bool) {
    setCookie("mute", bool, { path: "/" });
    setIsMute(bool);
  }

  useEffect(() => {
    if (cookies.bgm) {
      setBgmVolume(cookies.bgm);
      changeBgmVolume(cookies.bgm);
    } else {
      changeBgmVolume(bgmVolume);
    }

    if (cookies.se) {
      setSeVolume(cookies.se);
      changeSeVolume(cookies.se);
    } else {
      changeSeVolume(seVolume);
    }

    // FIXME: cookieから復帰する時なぜか強制的に isMute = true になってしまう。レンダーのタイミングのように見える。
    if (cookies.mute) {
      setIsMute(cookies.mute);
      changeMute(cookies.mute);
    } else {
      changeMute(isMute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VolumeContext.Provider
      value={{
        bgmVolume,
        seVolume,
        isMute,
        changeBgmVolume,
        changeSeVolume,
        changeMute,
      }}
    >
      {children}
    </VolumeContext.Provider>
  );
}

export const VolumeConsumer = VolumeContext.Consumer;
export default VolumeContext;
