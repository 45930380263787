import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useApiContext } from "../../../context";

// 画像
import logo from "../../../images/toppage/logo.svg";
import Term from "./Term";
import Bgm from "./Bgm";
import Footer from "../../layouts/footer";

import * as utils from "../../../util";
import StartPwa from "../../layouts/start-pwa";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

export default function Top() {
  const navigate = useNavigate();
  const { authenticate, isAuthenticated, user } = useApiContext();
  const [cookies] = useCookies(["sound"]);

  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isBgmModal, setIsBgmModal] = useState(false);
  const { isConnected } = useWeb3ModalAccount();
  const [logined, setLogin] = useState(false);

  useEffect(() => {
    if (logined && isConnected) {
      authenticate();
    }
  }, [isConnected]);

  function connectClick() {
    checkAgree();
    setLogin(true);
    authenticate({ signingMessage: "Login to Isekai Battle" });
  }

  function closeClick() {
    setIsConfirmModal(false);
    setIsBgmModal(false);
  }

  function enableSetIsBgmModal() {
    setIsConfirmModal(false);
    setIsBgmModal(true);
  }

  async function checkAgree() {
    if (isAuthenticated && user) {
      const agree = await user.get("agree");

      if (agree) {
        if (cookies.confirmation) {
          navigate("/start");
        } else {
          setIsBgmModal(true);
        }
      } else {
        setIsConfirmModal(true);
      }
    }
  }

  let openLink = false;
  useEffect(() => {
    const _url = new URL(window.location.href);
    let urlToOpen = _url.searchParams.get("url");
    if (urlToOpen) {
      if (openLink) return;
      openLink = true;
      urlToOpen = utils.fixUrl(urlToOpen);
      const parsedUrl = new URL(urlToOpen);
      if (parsedUrl.hostname === window.location.hostname) {
        setTimeout(() => {
          navigate(parsedUrl.pathname);
        }, 500);
      } else {
        setTimeout(() => {
          window.location.href = urlToOpen;
        }, 500);
      }
    } else {
      checkAgree();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (!utils.isStandalone()) {
    return <StartPwa />;
  }

  return (
    <>
      <div className="toppage relative h-full w-full">
        <div className="absolute top-1/3 w-full text-center">
          <p>
            <img
              src={logo}
              alt="Isekai Battle"
              className="mx-auto max-w-full"
            />
          </p>
          <p>
            <button
              className="red-button relative mx-2 px-20 py-4"
              onClick={connectClick}
            >
              <span>Wallet Connect</span>
            </button>
          </p>
        </div>

        <Footer />
      </div>

      {(isConfirmModal || isBgmModal) && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50 p-5">
          {isConfirmModal && (
            <Term
              enableSetIsBgmModal={enableSetIsBgmModal}
              closeClick={closeClick}
            />
          )}
          {isBgmModal && <Bgm closeClick={closeClick} />}
        </div>
      )}
    </>
  );
}
