import { useEffect, useState } from "react";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../../context";
import settings from "../../../../config/settings";
import { fragmentImageIds, regionIdToInfos } from "../../../../data/regionInfo";
import { sound_effect } from "../../../common/SoundEffect";
import img_dragon_light_alpha from "../../../../images/img_dragon_light_alpha.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../../../common/loading";

export default function Fragment(props) {
  const { langValue } = useLangContext();
  const { isekaiBattleSinki } = useGameContext();
  const { Web3Api, chainName, targetChainId, account, getFeeData } =
    useApiContext();
  const [treasures, setTreasures] = useState([]);
  const [claiming, setClaiming] = useState(false);
  const [sinkiClaimed, setSinkiClaimed] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    loadMapRegion();
  }, []);

  useEffect(() => {
    loadSinki();
  }, []);

  const loadSinki = async () => {
    const data = await Web3Api.account.getNFTsForContract({
      token_address: settings.addresses.IsekaiBattleSinki,
      address: account,
      chain: chainName(targetChainId),
    });
    const { result } = data;
    const claimed = {};
    for (const [_, x] of Object.entries(regionIdToInfos)) {
      if (!x.isTreasure) continue;
      claimed[x.treasure.name] = result.filter((obj) => {
        const metadata = JSON.parse(obj.metadata);
        return (
          metadata.attributes.find((o) => o.trait_type === "Name").value ==
          x.name
        );
      }).length;
    }
    setSinkiClaimed(claimed);
  };

  const loadMapRegion = async (claimedRegionId = null) => {
    const data = await Web3Api.account.getNFTsForContract({
      token_address: settings.addresses.IsekaiBattleFragment,
      address: account,
      chain: chainName(targetChainId),
    });
    const { result: fragments } = data;
    const list = [];
    for (const [_, obj] of Object.entries(regionIdToInfos)) {
      if (!obj.isTreasure) continue;
      const items = obj.treasure.fragmentIds.map((fgmId) => {
        return {
          id: fgmId,
          exists: fragments.find((o) => o.id == fgmId),
        };
      });
      obj.fragments = items;
      obj.claimable = items.filter((o) => o.exists).length === 7;
      list.push(obj);
    }
    if (claimedRegionId !== null) {
      const region = list.find((o) => o.regionId === claimedRegionId);
      if (region.claimable) {
        setTimeout(() => {
          loadMapRegion(claimedRegionId);
        }, 3000);
      }
    }
    setTreasures(list);
  };

  const onClickClaim = async (claimable, sinkiId) => {
    if (claiming || claimable === false) return;
    sound_effect("click");
    setClaiming(true);
    try {
      const methodData = isekaiBattleSinki.methods.claimSinki(sinkiId);
      const { executionGasFee, maxPriorityFeePerGas } = await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
        })
        .once("confirmation", async () => {
          sound_effect("positive");
          window.location.reload();
          setClaiming(false);
        })
        .catch((x) => {
          console.error(x);
        });
    } catch (error) {
      console.error(error);
    }

    setClaiming(false);
  };

  const onClickListEnemy = (fragment) => {
    if (fragment.exists) return;
    navigate(`/battle/${fragment.id}`);
  };

  return (
    <>
      {treasures.map((region, key) => {
        const makeSinki = region.fragments.filter((o) => o.exists).length === 7;
        return (
          <div class="mb-4 mt-4" key={key}>
            <div class="relative">
              <img
                src={region.image}
                alt=""
                class="mt-2 w-full rounded-md brightness-50"
              />
              <span class="absolute top-1/2 mt-0.5 w-full -translate-y-1/2 transform px-4 text-center text-lg font-bold leading-5 text-white [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]">
                {langValue === "ja" && (
                  <>
                    {region.id_number} {region.name_ja}
                  </>
                )}
                {langValue === "en" && (
                  <>
                    {region.id_number} {region.name_en}
                  </>
                )}
              </span>
            </div>

            <ul class="mt-4 flex justify-center gap-1">
              {region.fragments.map((value, key) => {
                return (
                  <li
                    class="stone"
                    key={key}
                    onClick={() => onClickListEnemy(value)}
                  >
                    <span>
                      <img
                        src={fragmentImageIds[value.id]}
                        alt=""
                        class={`${!value.exists && "brightness-75 grayscale"}`}
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
            <div
              class={`${!region.claimable && "grayscale"} relative mt-2 flex flex-col items-center justify-center px-8 py-6 pt-8 text-white`}
              style={{
                background: `#1b84e3 url(${img_dragon_light_alpha}) 210% 0 no-repeat`,
                backgroundSize: "70%",
              }}
            >
              <img src={region.treasure.image} alt="" />
              <div class="mt-2 text-xl uppercase tracking-widest">
                {langValue === "ja" && <>{region.treasure.name_ja}</>}
                {langValue === "en" && <>{region.treasure.name_en}</>}
              </div>
              {sinkiClaimed[region.treasure.name_en] ? (
                <div class="triangle absolute bottom-0 right-0">
                  <span class="absolute -top-1 left-0 text-right text-lg text-black">
                    {sinkiClaimed[region.treasure.name_en]}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            {region.treasure.creatable && makeSinki && (
              <button
                class={`mt-4 block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black ${!region.claimable && "bg-gray"} `}
                onClick={() =>
                  onClickClaim(region.claimable, region.treasure.sinkiId)
                }
              >
                {langValue === "ja" && <>SINKIの封印を解</>}
                {langValue === "en" && <>Create a SINKI</>}
              </button>
            )}
          </div>
        );
      })}

      {claiming && <Loading />}
    </>
  );
}
