import { useState } from "react";
import { useLangContext, useVolumeContext } from "../../../context";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ReactComponent as LeafIcon } from "../../../images/ico_leaf.svg";

export default function Bgm(props) {
  const { langValue } = useLangContext();
  const { changeBgmVolume, changeSeVolume } = useVolumeContext();
  const navigate = useNavigate();
  const [isBgm, setIsBgm] = useState(50);
  const [cookies, setCookie] = useCookies(["sound"]); // eslint-disable-line no-unused-vars

  function bgmClick(e) {
    changeBgmVolume(Number(isBgm));
    changeSeVolume(Number(isBgm));
    setCookie("confirmation", true, { path: "/" });
    navigate("/start");
    props.closeClick();
  }

  return (
    <>
      <div
        className="relative w-full rounded-lg p-1"
        style={{ background: "#FFFEF7" }}
      >
        <div
          className="rounded border p-4 py-8"
          style={{ borderColor: "#D1B540" }}
        >
          <h1 class="mx-4 text-center text-3xl leading-normal">
            {langValue === "ja" && <>初期設定</>}
            {langValue === "en" && <>Initial Setup</>}
          </h1>
          <div class="mt-4 grid grid-cols-[max-content_auto] gap-x-4 gap-y-4">
            <div>
              {langValue === "ja" && <>サウンド</>}
              {langValue === "en" && <>Sound</>}
            </div>
            <div>
              <ul class="flex gap-2">
                <li>
                  <label>
                    <input
                      type="radio"
                      name="aaa"
                      class="mr-1"
                      value="50"
                      checked={isBgm === 50}
                      onChange={(e) => setIsBgm(Number(e.target.value))}
                    />
                    ON
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="radio"
                      name="aaa"
                      class="mr-1"
                      value="0"
                      checked={isBgm === 0}
                      onChange={(e) => setIsBgm(Number(e.target.value))}
                    />
                    OFF
                  </label>
                </li>
              </ul>
            </div>
            <div>
              {langValue === "ja" && <>通知</>}
              {langValue === "en" && <>Notifications</>}
            </div>
            <div>
              <p>
                {langValue === "ja" && (
                  <>
                    種やフラグメントの獲得時間や、争奪バトルの再出撃時間をお知らせします。
                  </>
                )}
                {langValue === "en" && (
                  <>
                    We'll notify you when you're ready to claim Seeds or
                    Fragments, and when you can attack other players for
                    Fragments.
                  </>
                )}
              </p>

              <p class="mt-2">
                {langValue === "ja" && (
                  <>
                    Isekai Battleをリアルタイムで、より楽しむために、次の画面で
                    <strong>通知を許可</strong>することをおすすめします。
                  </>
                )}
                {langValue === "en" && (
                  <>
                    We recommend <strong>enabling notifications</strong> on the
                    next screen to fully enjoy real-time gameplay of Isekai
                    Battle.
                  </>
                )}
              </p>
            </div>
          </div>

          <div class="mt-10">
            <button
              class="mt-2 block w-full rounded-full bg-buttonBg p-3 text-center text-xl text-white"
              onClick={bgmClick}
            >
              {langValue === "ja" && <>次へ</>}
              {langValue === "en" && <>Next</>}
            </button>
            <span class="mt-4 inline-block text-sm leading-5 text-neutral-400">
              {langValue === "ja" && (
                <>
                  ゲーム画面右上のユーザーアイコンをタップすると、音（BGM、効果音）、通知の設定はいつでも変更できます。
                </>
              )}
              {langValue === "en" && (
                <>
                  You can change audio (BGM, SFX) and notification settings from
                  'My Page' by tapping the user icon in the upper right corner
                  of the game screen.
                </>
              )}
            </span>
          </div>

          {/* 装飾の葉っぱ */}
          <LeafIcon
            style={{ height: "27px", width: "27px", fill: "#D1B540" }}
            className="absolute -left-3 -top-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(-1, 1)",
            }}
            className="absolute -right-3 -top-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(-1, -1)",
            }}
            className="absolute -bottom-3 -right-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(1, -1)",
            }}
            className="absolute -bottom-3 -left-3"
          />
        </div>
      </div>
    </>
  );
}
