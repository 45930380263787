import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../../context";
import btn_close from "../../../../images/btn_close.svg";
import ico_home from "../../../../images/ico_home.svg";
import img_shadow_white from "../../../../images/img_shadow_white.svg";
import ico_ethereum from "../../../../images/ico_ethereum.svg";
import ItemExtendConfirm from "./item-extend-confirm";
import { useState } from "react";
import Web3 from "web3/dist/web3.min";

export default function ItemExtend(props) {
  const { langValue } = useLangContext();
  const { isekaiBattleStatusManager } = useGameContext();
  const { account } = useApiContext();
  const [upgradeItemBag, setUpgradeItemBag] = useState({
    bag: 160,
    level: 1,
    price: 0.001,
  });
  const [extendConfirm, setExtendConfirm] = useState(false);

  const onExtendBag = (bag, level, price, disabled = false) => {
    if (props.itemBagQty >= bag || disabled === true) {
      return;
    }
    setUpgradeItemBag(bag);
    setExtendConfirm(true);
    setUpgradeItemBag({
      bag,
      level,
      price,
    });
  };

  const onConfirm = async () => {
    const price = Web3.utils.toWei(`${upgradeItemBag.price}`, "ether");
    isekaiBattleStatusManager.methods
      .addonBagLevel(upgradeItemBag.level)
      .send({ from: account, value: price })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .once("confirmation", function () {
        window.location.reload();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const onClose = () => {
    setExtendConfirm(false);
  };

  return (
    <>
      {!extendConfirm && (
        <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
          <div class="h-full overflow-auto bg-gradient-to-b from-[#00192F] via-[#002F58] to-[#00192F]">
            <button
              class="absolute right-4 top-5"
              onClick={() => props.setExtendItem(false)}
            >
              <img src={btn_close} alt="" />
            </button>

            <h1 class="mt-14 text-center text-2xl tracking-widest text-white">
              {langValue === "ja" && <>バッグの拡張</>}
              {langValue === "en" && <>Bag expansion</>}
            </h1>
            <p class="mt-1 text-center text-white">
              {langValue === "ja" && <>武器防具</>}
              {langValue === "en" && <>Weapons and armor</>}
            </p>

            <ul class="m-8 mb-16 flex flex-col gap-8">
              <li class="h-full overflow-auto bg-gradient-to-b from-[#647462] via-[#8DBF06] to-[#F4F39F] p-[1px] text-white opacity-80 brightness-150 grayscale">
                <div class="bg-gradient-to-b from-[#02294C] via-[#02294C] to-[#442A9B]">
                  <div class="pt-4">
                    <img src={ico_home} alt="" class="mx-auto" />
                  </div>
                  <div class="items__linethrough mt-2 text-center text-lg tracking-widest">
                    <span>Level 1</span>
                  </div>
                  <div class="relative text-center text-9xl font-bold">
                    <div class="inline-block bg-gradient-to-b from-white to-[#F2DBFE] bg-clip-text tracking-wider text-transparent">
                      <img
                        src={img_shadow_white}
                        alt=""
                        class="absolute -bottom-5 left-0 right-0 mx-auto"
                      />
                      160
                    </div>
                  </div>
                  <div
                    class={`mt-4 bg-gradient-to-b from-[#BCA528] to-[#5E5314] p-2 text-center text-3xl [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]`}
                  >
                    <div
                      class="flex items-center justify-center gap-2"
                      onClick={() => onExtendBag(160, 1, 0.01, true)}
                    >
                      <span>DONE</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="h-full overflow-auto bg-gradient-to-b from-[#647462] via-[#8DBF06] to-[#F4F39F] p-[1px] text-white opacity-80 brightness-150 grayscale">
                <div class="bg-gradient-to-b from-[#02294C] via-[#02294C] to-[#442A9B]">
                  <div class="pt-4">
                    <img src={ico_home} alt="" class="mx-auto" />
                  </div>
                  <div class="items__linethrough mt-2 text-center text-lg tracking-widest">
                    <span>Level 2</span>
                  </div>
                  <div class="relative text-center text-9xl font-bold">
                    <div class="inline-block bg-gradient-to-b from-white to-[#F2DBFE] bg-clip-text tracking-wider text-transparent">
                      <img
                        src={img_shadow_white}
                        alt=""
                        class="absolute -bottom-5 left-0 right-0 mx-auto"
                      />
                      200
                    </div>
                  </div>
                  <div
                    class={`mt-4 bg-gradient-to-b from-[#BCA528] to-[#5E5314] p-2 text-center text-3xl [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]`}
                  >
                    <div
                      class="flex items-center justify-center gap-2"
                      onClick={() => onExtendBag(200, 2, 0.01, true)}
                    >
                      <span>DONE</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="relative h-full overflow-auto bg-gradient-to-b from-[#647462] via-[#8DBF06] to-[#F4F39F] p-[1px] text-white">
                <div class="bg-gradient-to-b from-[#02294C] via-[#02294C] to-[#442A9B]">
                  <div class="pt-4">
                    <img src={ico_home} alt="" class="mx-auto" />
                  </div>
                  <div class="items__linethrough mt-2 text-center text-lg tracking-widest">
                    <span>Level 3</span>
                  </div>
                  <div class="relative text-center text-9xl font-bold">
                    <div class="inline-block bg-gradient-to-b from-white to-[#F2DBFE] bg-clip-text tracking-wider text-transparent">
                      <img
                        src={img_shadow_white}
                        alt=""
                        class="absolute -bottom-5 left-0 right-0 mx-auto"
                      />
                      240
                    </div>
                  </div>
                  <div
                    class={`mt-4 bg-gradient-to-b from-[#BCA528] to-[#5E5314] p-2 text-center text-3xl [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]`}
                  >
                    <div
                      class="flex items-center justify-center gap-2"
                      onClick={() => onExtendBag(240, 3, 0.01, true)}
                    >
                      <span>
                        <img src={ico_ethereum} alt="" />
                      </span>
                      <span>0.01 ETH</span>
                    </div>
                  </div>
                </div>
                <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                  <span class="whitespace-nowrap bg-black bg-opacity-80 p-2 text-3xl tracking-wider">
                    Coming Soon
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {extendConfirm && (
        <ItemExtendConfirm
          upgradeItemBag={upgradeItemBag}
          itemBagQty={props.itemBagQty}
          onConfirm={onConfirm}
          onClose={onClose}
        />
      )}
    </>
  );
}
