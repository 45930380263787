import { useLangContext } from "../../context";

import { ReactComponent as NotionIcon } from "../../images/ico_notion.svg";
import { ReactComponent as TwitterIcon } from "../../images/ico_x.svg";
import { ReactComponent as DiscordIcon } from "../../images/ico_discord.svg";
import { ReactComponent as OpenseaIcon } from "../../images/ico_opensea.svg";

export default function Footer(props) {
  const { langValue, setMyLanguage } = useLangContext();
  function selectLang(e) {
    setMyLanguage(e.target.dataset.lang);
  }
  return (
    <footer
      className={`absolute bottom-0 h-[80px] w-full bg-lightBrown p-3 px-4 ${props.customClass}`}
    >
      {/* アイコンリンク */}
      <ul>
        <li className="mr-3 inline-block w-5">
          <a
            href="https://isekai-battle.notion.site/isekai-battle/Isekai-Battle-6cb69057aa33489fbcbe82878e8d8734"
            target="_blank"
            rel="noreferrer"
          >
            <NotionIcon
              style={{ height: "20px", width: "20px", fill: "white" }}
            />
          </a>
        </li>
        <li className="mr-3 inline-block w-5">
          <a
            href="https://mobile.twitter.com/isekai_battle"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon
              style={{ height: "20px", width: "20px", fill: "white" }}
            />
          </a>
        </li>
        <li className="mr-3 inline-block w-5">
          <a
            href="https://discord.gg/isekaibattle"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon
              style={{ height: "20px", width: "20px", fill: "white" }}
            />
          </a>
        </li>
        <li className="mr-3 inline-block w-5">
          <a
            href="https://testnets.opensea.io/collection/isekai-battle-goerli"
            target="_blank"
            rel="noreferrer"
          >
            <OpenseaIcon
              style={{ height: "20px", width: "20px", fill: "white" }}
            />
          </a>
        </li>
      </ul>

      {/* テキストリンク */}
      <ul className="mr-16 flex gap-3 text-xs text-white">
        <li>
          {langValue === "ja" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              利用規約
            </a>
          )}
          {langValue === "en" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              Term of Use
            </a>
          )}
        </li>
        <li>
          {langValue === "ja" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
          )}
          {langValue === "en" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          )}
        </li>
        <li>
          {langValue === "ja" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/indication-based-on-the-act-on-specified-commercial-transactions"
              target="_blank"
              rel="noreferrer"
            >
              特定商取引法表記
            </a>
          )}
          {langValue === "en" && (
            <a
              href="https://isekai-battle.gitbook.io/isekai-battle/legal/indication-based-on-the-act-on-specified-commercial-transactions"
              target="_blank"
              rel="noreferrer"
            >
              Act on specified commercial transactions
            </a>
          )}
        </li>
      </ul>

      {/* 言語切替 */}
      <div className=" absolute right-4 top-4 mt-2 text-white">
        <button onClick={selectLang} data-lang="ja">
          JP
        </button>{" "}
        |{" "}
        <button onClick={selectLang} data-lang="en">
          EN
        </button>
      </div>
    </footer>
  );
}
