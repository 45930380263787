import { useEffect, useState } from "react";
import { useApiContext, useLangContext } from "../../context";

export default function NotificationSetting(props) {
  const { langValue } = useLangContext();

  const { user } = useApiContext();
  const [values, setValues] = useState({});

  useEffect(() => {
    (async () => {
      const keys = [
        "notiChanceSeed",
        "notiChanceFragment",
        "notiAttackPossible",
        "notiDefenceSuccess",
        "notiFragmentStolen",
        "notiAmorLessThan",
        "notiAmorLessThanValue",
        "notiNotReceive",
        "notiNotReceiveStart",
        "notiNotReceiveEnd",
      ];
      const values = await user.getValues(keys);
      setValues(values);
    })();
  }, []);

  const onClickCheck = async (e) => {
    const { name, checked } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
    await user.set(name, checked);
  };

  const onChangeSelect = async (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    await user.set(name, value);
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      let label;
      if (langValue === "ja") {
        label = `${i} 時`;
      } else if (langValue === "en") {
        const period = i < 12 ? "AM" : "PM";
        const hour = i % 12 === 0 ? 12 : i % 12;
        label = `${hour} ${period}`;
      }
      options.push(
        <option key={i} value={i}>
          {label}
        </option>,
      );
    }
    return options;
  };

  return (
    <div class="mt-10 border-t border-white border-opacity-50 pt-10">
      <div class="text-xl">
        {langValue === "ja" && <>通知設定</>}
        {langValue === "en" && <>Notification settings</>}
      </div>
      <p class="text-xs">
        {langValue === "ja" && <>大切な通知は選択に関わらず配信されます</>}
        {langValue === "en" && (
          <>Important notifications will be sent regardless of your selection</>
        )}
      </p>
      <ul class="mypage__checkbox mt-8">
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiChanceSeed"
              onClick={onClickCheck}
              checked={values.notiChanceSeed === true}
            />
            {langValue === "ja" && <>種獲得チャンス</>}
            {langValue === "en" && <>Chance to find Seeds!</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiChanceFragment"
              onClick={onClickCheck}
              checked={values.notiChanceFragment === true}
            />
            {langValue === "ja" && <>フラグメント獲得チャンス</>}
            {langValue === "en" && <>Chance to find Fragments!</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiAttackPossible"
              onClick={onClickCheck}
              checked={values.notiAttackPossible === true}
            />

            {langValue === "ja" && <>攻撃可能！</>}
            {langValue === "en" && <>Ready for Attack!</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiDefenceSuccess"
              onClick={onClickCheck}
              checked={values.notiDefenceSuccess === true}
            />
            {langValue === "ja" && <>防御成功！</>}
            {langValue === "en" && <>Succesfully defended your Fragment!</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiFragmentStolen"
              onClick={onClickCheck}
              checked={values.notiFragmentStolen === true}
            />

            {langValue === "ja" && <>フラグメントを奪われた！</>}
            {langValue === "en" && <>Your Fragment was stolen...</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="pl-7">
            <input
              type="checkbox"
              name="notiAmorLessThan"
              onClick={onClickCheck}
              checked={values.notiAmorLessThan === true}
            />
            {langValue === "ja" && <>防具が</>}
            {langValue === "en" && <>Armor supply under </>}
            <select
              class="relative -top-1 mx-1 rounded border border-white bg-transparent p-1 align-sub"
              name="notiAmorLessThanValue"
              onChange={onChangeSelect}
              value={values.notiAmorLessThanValue}
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
              <option>60</option>
              <option>70</option>
              <option>80</option>
              <option>90</option>
              <option>100</option>
              <option>110</option>
              <option>120</option>
              <option>130</option>
              <option>140</option>
              <option>150</option>
              <option>160</option>
              <option>170</option>
              <option>180</option>
              <option>190</option>
            </select>
            {langValue === "ja" && <>個以下になった</>}
          </label>
        </li>
        <li class="mt-3">
          <label class="ml-7 inline-block">
            <input
              type="checkbox"
              name="notiNotReceive"
              onClick={onClickCheck}
              checked={values.notiNotReceive === true}
            />
            {langValue === "ja" && <>通知しない時間を設定</>}
            {langValue === "en" && (
              <>Choose when you don’t want to receive notifications.</>
            )}
            <br />
            <span class="mt-2 inline-block">
              <select
                class="relative -top-1 mr-1 rounded border border-white bg-transparent p-1 align-sub"
                name="notiNotReceiveStart"
                onChange={onChangeSelect}
                value={values.notiNotReceiveStart}
              >
                {generateTimeOptions()}
              </select>
              〜
              <select
                class="relative -top-1 mx-1 rounded border border-white bg-transparent p-1 align-sub"
                name="notiNotReceiveEnd"
                onChange={onChangeSelect}
                value={values.notiNotReceiveEnd}
              >
                {generateTimeOptions()}
              </select>
            </span>
          </label>
        </li>
      </ul>
    </div>
  );
}
