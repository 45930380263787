import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import mapImg from "../../../images/map.png";
import { useLangContext } from "../../../context";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { mapLists } from "../../../data/regionInfo";

const Map = React.forwardRef((props, ref) => {
  const { stakedCharacters, setSelectedMapObject, setSelectedRegion } = props;
  const headerHeight = 64;
  const footerHeight = 57.5;
  const [screenFrameHeight, setScreenFrameHeight] = useState(
    window.innerHeight - headerHeight - footerHeight,
  );
  const [screenFrameWidth, setScreenFrameWidth] = useState(window.innerWidth);
  const orignalMapWidth = 3300;
  const orignalMapHeight = 2100;
  const [maps, setMaps] = useState([]);
  const [scale, setScale] = useState(1);
  const [positionX, setPositionX] = useState(-376.5);

  const zoomInstance = useRef(null);
  const mapRef = useRef(null);
  const screenFrameRef = useRef(null);
  const { langValue } = useLangContext();

  function checkScreenFrameSize() {
    setScreenFrameHeight(screenFrameRef.current.offsetHeight);
    setScreenFrameWidth(screenFrameRef.current.offsetWidth);
  }

  useEffect(() => {
    checkScreenFrameSize();
    window.addEventListener("resize", checkScreenFrameSize);
    return () => window.removeEventListener("resize", checkScreenFrameSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pinMaps = mapLists.filter((o) => o.pinMap === true);
  useEffect(() => {
    if (mapRef?.current?.clientWidth && maps.length === 0) {
      const clientWidth = mapRef?.current?.clientWidth;
      const clientHeight = mapRef?.current?.clientHeight;
      const _maps = [];
      for (let i = 0; i < pinMaps.length; i++) {
        const obj = pinMaps[i];
        const x = obj.x / (orignalMapWidth / clientWidth);
        const y = obj.y / (orignalMapHeight / clientHeight);
        _maps.push({
          ...obj,
          x,
          y,
        });
      }

      setPositionX(-(clientWidth / 2 - screenFrameWidth / 2));
      setMaps(_maps);
    }
  });

  function handleScaleChange(event) {
    setScale(event.instance.transformState.scale);
  }

  useImperativeHandle(ref, () => ({
    center: () => {
      zoomInstance.current.setTransform(positionX, 0, 1);
    },
  }));

  return (
    <>
      <div
        className="h-screen"
        ref={screenFrameRef}
        style={{
          height: "100%",
          zIndex: -1,
          width: "100%",
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
        }}
      >
        <TransformWrapper
          // centerOnInit={true}
          ref={zoomInstance}
          centerZoomedOut={true}
          initialScale={scale}
          initialPositionX={positionX}
          initialPositionY={0}
          disablePadding={true}
          minScale={1}
          maxScale={5}
          onTransformed={(e) => handleScaleChange(e)}
        >
          <TransformComponent>
            <img
              style={{
                height: screenFrameHeight,
                width: "auto",
                maxWidth: "max-content",
                position: "relative",
              }}
              src={mapImg}
              alt="map"
              className="map-img"
              ref={mapRef}
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: mapRef?.current?.clientWidth,
                height: mapRef?.current?.clientHeight,
              }}
            >
              {maps.map((map, key) => {
                return (
                  <div
                    class="map-label"
                    style={{
                      top: map.y,
                      left: map.x,
                      transform: `scale(${1 / scale})`,
                    }}
                    onClick={() => {
                      setSelectedMapObject(map.id);
                      setSelectedRegion(true);
                    }}
                    key={key}
                  >
                    <div class="map-label__title">
                      {langValue === "ja" && <>{map.name_ja}</>}
                      {langValue === "en" && <>{map.name_en}</>}
                    </div>
                    <div class="map-label__characters">
                      {stakedCharacters[map.id]
                        .slice(0, 5)
                        .filter((o) => o)
                        .map((char, key) => {
                          return (
                            <img src={char.image} alt={char.name} key={key} />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </>
  );
});

export default Map;
