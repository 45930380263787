import { Link } from "react-router-dom";
import logo from "../../../images/logo_white.svg";
import notify from "../../../images/ico_annotation.svg";
import { useApiContext, useLangContext } from "../../../context";
import { useEffect, useState } from "react";
import Master from "../../layouts/master";
import { getMessagingToken } from "../../../firebase.service";
import * as utils from "../../../util";

export default function Start(props) {
  const { langValue } = useLangContext();
  const { user, account, Web3Api } = useApiContext();

  const [banners, setBanners] = useState(null);

  useEffect(() => {
    (async () => {
      const { result } = await Web3Api.game.getBanners();
      if (result.length > 0) {
        setBanners(result[0]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const initial = await user.get("notiSetting");
      if (!initial) {
        const keys = [
          "notiSetting",
          "notiChanceSeed",
          "notiChanceFragment",
          "notiAttackPossible",
          "notiDefenceSuccess",
          "notiFragmentStolen",
          "notiAmorLessThan",
          "notiAmorLessThanValue",
          "notiNotReceive",
          "notiNotReceiveStart",
          "notiNotReceiveEnd",
        ];
        const values = [
          true,
          true,
          true,
          true,
          false,
          true,
          false,
          10,
          false,
          0,
          0,
        ];
        await user.setValues(keys, values);
      }
      await user.set("language", langValue);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (window.Notification.permission === "granted") {
          const msgToken = await getMessagingToken();
          if (msgToken) {
            await user.set("firebaseToken", msgToken);
          }
        } else if (window.Notification.permission !== "denied") {
          await window.Notification.requestPermission().then(
            async (permission) => {
              if (permission === "granted") {
                try {
                  const msgToken = await getMessagingToken();
                  await user.set("firebaseToken", msgToken);
                } catch (error) {
                  console.error(error);
                }
              }
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [account]);

  return (
    <Master fullMode={true}>
      <div class="startpage relative h-full w-full">
        <div class="h-full w-full bg-black bg-opacity-50">
          <div class="absolute top-1/3 w-full text-center">
            <p>
              <img
                src={logo}
                alt="Isekai Battle"
                class="mx-auto w-full max-w-full px-4"
              />
            </p>
            <ul class="mx-4">
              <li>
                <Link to="/explore">
                  <button class="red-button relative mt-2 w-full py-4">
                    {langValue === "ja" && <span>探索へ</span>}
                    {langValue === "en" && <span>Explore</span>}
                  </button>
                </Link>
              </li>
              <li>
                <a href="https://x.com/Isekai_Battle" target="_blank">
                  <button class="red-button relative mt-2 w-full py-4">
                    <span>
                      {langValue === "ja" && <span>お知らせ</span>}
                      {langValue === "en" && <span>News</span>}
                      <img src={notify} alt="" class="ico-annotation" />
                    </span>
                  </button>
                </a>
              </li>
              <li>
                <Link to="/battle/ranking">
                  <button class="gray-button relative mt-2 w-full py-4">
                    {langValue === "ja" && <span>ランキング</span>}
                    {langValue === "en" && <span>Ranking</span>}
                  </button>
                </Link>
              </li>
              {banners && (
                <li>
                  <a href={utils.fixUrl(banners.link)} target="_blank">
                    <button class="gray-button relative mt-2 w-full py-4">
                      {langValue === "ja" && <span>{banners.title}</span>}
                      {langValue === "en" && <span>{banners.title}</span>}
                    </button>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Master>
  );
}
