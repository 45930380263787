import { useState, useEffect, useRef } from "react";
import "react-dom";
import Draggable from "react-draggable";

export default function VolumnDrag(props) {
  const volumeCount = 100;

  // for dragging
  const selectedCountBase = 0;
  const [selectedCount, setSelectedCount] = useState(props.volumeNumber);
  const dragNodeRef = useRef(null);
  const dragBarRef = useRef(null);
  const dragAreaRef = useRef(null);
  const [position] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState(0);
  const [progressBarActiveStyle, setProgressBarActiveStyle] = useState("0%");
  const [progressBarDragbarStyle, setProgressBarDragbarStyle] = useState("0%");

  useEffect(() => {
    const ratio =
      (props.volumeNumber - selectedCountBase) /
      (volumeCount - selectedCountBase);
    setProgressBarStyles(ratio);
  }, [props.volumeNumber]);

  function setProgressBarStyles(ratio) {
    let width = ratio * 100;
    width = width > 100 ? 100 : width;
    setProgressBarActiveStyle(`${width}%`);
    setProgressBarDragbarStyle(`${width}%`);
  }

  function updateselectedCountWithRecalc(_selectedCount) {
    if (_selectedCount < 1) _selectedCount = 0;
    setSelectedCount(_selectedCount);
    let ratio;
    if (volumeCount === selectedCountBase) {
      ratio = 1;
    } else {
      ratio =
        (_selectedCount - selectedCountBase) /
        (volumeCount - selectedCountBase);
    }
    setProgressBarStyles(ratio);
    props.setDragCount(
      _selectedCount > volumeCount ? volumeCount : _selectedCount,
    );
  }

  function onDragStart(ev, data) {
    setDragOffset(dragNodeRef.current.offsetLeft);
  }

  function onDrag(ev, data) {
    let width =
      (dragOffset + data.x) / (dragNodeRef.current.parentNode.offsetWidth - 24);
    if (width < 0) {
      width = 0;
    } else if (width > 100) {
      width = 100;
    }
    setSelectedCount(
      Math.round(width * (volumeCount - selectedCountBase)) + selectedCountBase,
    );
    const progress = width * 100 > 100 ? 100 : width * 100;
    setProgressBarActiveStyle(`${progress}%`);
  }

  function onDragStop(ev, data) {
    updateselectedCountWithRecalc(selectedCount);
  }

  function onProgressBarClick(ev) {
    if (ev.target !== dragAreaRef.current) {
      return;
    }

    const offsetWidth = dragBarRef.current.offsetWidth;
    const margin = (ev.target.offsetWidth - dragBarRef.current.offsetWidth) / 2;
    const left = ev.clientX - ev.target.getBoundingClientRect().left;
    const posX = left - margin;

    if (posX < 0) {
      if (posX >= -4) {
        updateselectedCountWithRecalc(0);
      }
      return;
    }
    if (posX > offsetWidth) {
      if (posX <= offsetWidth + 4) {
        updateselectedCountWithRecalc(volumeCount);
      }
      return;
    }

    const ratio = posX / offsetWidth;
    updateselectedCountWithRecalc(Math.round(ratio * volumeCount));
  }

  return (
    <>
      <div
        class="relative h-1 w-full rounded-full bg-gray-400"
        ref={dragBarRef}
      >
        <div
          class={`rounded-s-full w-[${progressBarActiveStyle}] h-1 bg-white`}
        ></div>
        <div ref={dragAreaRef} onClick={onProgressBarClick}>
          <Draggable
            nodeRef={dragNodeRef}
            axis="x"
            bounds="parent"
            position={position}
            onStart={onDragStart}
            onDrag={onDrag}
            onStop={onDragStop}
          >
            <button
              style={{
                width: 28,
                height: 30,
                top: -14,
                paddingTop: 10,
                position: "absolute",
                left: progressBarDragbarStyle,
              }}
              ref={dragNodeRef}
            >
              <div class="-ml-1 -mt-2.5 h-5 w-3 bg-white"></div>
            </button>
          </Draggable>
        </div>
      </div>
    </>
  );
}
