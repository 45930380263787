import ico_pluscircle_dark from "../../../../images/ico_pluscircle_dark.svg";
import ico_opensea_dark from "../../../../images/ico_opensea_dark.svg";
import {
  useApiContext,
  useGameContext,
  useItemContext,
  useLangContext,
} from "../../../../context";
import { useMemo, useState } from "react";
import settings from "../../../../config/settings";
import ModalTrash from "./modal-trash";
import { sound_effect } from "../../../common/SoundEffect";
import ModalTrashConfirm from "./modal-trash-confirm";
import { weaponImages } from "../../../../data/itemInfo";
import ItemLv1 from "./item-levels/item-lv1";
import ItemLv3 from "./item-levels/item-lv3";
import ItemLv2 from "./item-levels/item-lv2";
import Loading from "../../../common/loading";

function getWeaponsImage(type) {
  return weaponImages[type];
}

export default function ItemWeapon(props) {
  const { langValue } = useLangContext();
  const { weapons } = useItemContext();
  const { trashIsekaiBattleItems } = useGameContext();
  const { account, getFeeData } = useApiContext();
  const [selectTrash, setSelectTrash] = useState();
  const [selectedItemTrash, setSelectedItemTrash] = useState({});
  const [confirmTrash, setConfirmTrash] = useState(false);
  const [loading, setLoading] = useState(false);

  const weaponCount = useMemo(() => {
    return weapons.reduce((sum, element) => {
      return sum + Number(element.amount);
    }, 0);
  }, [weapons]);

  const items = useMemo(() => {
    const sortedWeapons = weapons.sort(
      (a, b) => Number(a.token_id) - Number(b.token_id),
    );
    return sortedWeapons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weapons]);

  const onSelectTrash = (item) => {
    setSelectTrash(item);
    sound_effect("click");
  };

  async function submitTrashbox() {
    if (loading) return;
    setLoading(true);
    const ids = [];
    const values = [];
    for (const [id, item] of Object.entries(selectedItemTrash)) {
      if (!item.qty) continue;
      ids.push(id);
      values.push(item.qty);
    }

    try {
      const methodData = trashIsekaiBattleItems.methods.trashItems(
        ids,
        values,
        [],
        [],
      );
      const { executionGasFee, maxPriorityFeePerGas } = await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
        })
        .on("confirmation", async (confirmationNumber) => {
          if (confirmationNumber === 2) {
            sound_effect("positive");
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((x) => {
          console.log(x);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  const cancelTrashbox = () => {
    setSelectedItemTrash((prev) => ({}));
    sound_effect("nagative");
  };

  return (
    <>
      {!selectTrash && (
        <div>
          <h2 class="flex items-center justify-between">
            <span class="flex gap-2">
              {weaponCount}/{props.itemBagQty}{" "}
              <img
                src={ico_pluscircle_dark}
                alt=""
                onClick={() => props.setExtendItem(true)}
              />
            </span>
            <span>
              <a href={settings.opensea.IsekaiBattleWeapon} target="_blank">
                <img src={ico_opensea_dark} alt="" class="w-4 opacity-70" />
              </a>
            </span>
          </h2>
          <ul class="mt-4 grid grid-cols-5 items-start gap-2">
            {items.map((item, key) => {
              switch (item.lv) {
                case 1:
                  return (
                    <ItemLv1
                      key={key}
                      onClick={() => onSelectTrash(item)}
                      image={getWeaponsImage(item.type)}
                      level={item.lv}
                      amount={item.amount}
                      itemTrash={selectedItemTrash[item.id]}
                    />
                  );
                case 2:
                  return (
                    <ItemLv2
                      key={key}
                      onClick={() => onSelectTrash(item)}
                      image={getWeaponsImage(item.type)}
                      level={item.lv}
                      amount={item.amount}
                      itemTrash={selectedItemTrash[item.id]}
                    />
                  );
                case 3:
                  return (
                    <ItemLv3
                      key={key}
                      onClick={() => onSelectTrash(item)}
                      image={getWeaponsImage(item.type)}
                      level={item.lv}
                      amount={item.amount}
                      itemTrash={selectedItemTrash[item.id]}
                    />
                  );
                default:
                  return (
                    <ItemLv1
                      key={key}
                      onClick={() => onSelectTrash(item)}
                      image={getWeaponsImage(item.type)}
                      level={item.lv}
                      amount={item.amount}
                      itemTrash={selectedItemTrash[item.id]}
                    />
                  );
              }
            })}
          </ul>
          {Object.keys(selectedItemTrash).length > 0 && (
            <div class="sticky bottom-0 left-0 z-10 mt-8 w-full">
              <button
                class="mx-auto mt-4 block w-4/5 rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white drop-shadow-sm"
                onClick={() => setConfirmTrash(true)}
              >
                {langValue === "ja" && <>ゴミ箱を空にする</>}
                {langValue === "en" && <>Empty trash</>}
              </button>
              <button
                class="mx-auto mt-4 block w-4/5 rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white drop-shadow-sm"
                onClick={cancelTrashbox}
              >
                {langValue === "ja" && <>キャンセル</>}
                {langValue === "en" && <>Cancel</>}
              </button>
            </div>
          )}
        </div>
      )}

      {selectTrash && (
        <ModalTrash
          itemImage={getWeaponsImage(selectTrash.type)}
          item={selectTrash}
          onClose={() => setSelectTrash(null)}
          selectedItemTrash={selectedItemTrash}
          setSelectedItemTrash={setSelectedItemTrash}
          selectedCategory={props.selectedCategory}
        />
      )}

      {confirmTrash && (
        <ModalTrashConfirm
          selectedItemTrash={selectedItemTrash}
          onConfirm={submitTrashbox}
          onClose={() => setConfirmTrash(false)}
          itemCount={weaponCount}
          itemBag={props.itemBagQty}
          selectedCategory={props.selectedCategory}
        />
      )}

      {loading && <Loading />}
    </>
  );
}
