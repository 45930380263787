import moment from "moment";

export function idSorter(a, b) {
  return Number(a.id) - Number(b.id);
}

export function shortAddress(account) {
  return account.slice(0, 7) + "..." + account.slice(-5);
}

export function chunkArray(arr, chunkSize) {
  const chunks = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    const chunk = arr.slice(i, i + chunkSize);
    chunks.push(chunk);
    i += chunkSize;
  }

  return chunks;
}

export function toNumber(x) {
  switch (typeof x) {
    case "string":
      if (x) {
        return parseInt(x, 10);
      }
      break;
    case "number":
      return x;
    default:
      break;
  }
  return x;
}

function roundNumber(number, decimals) {
  if (!number) return 0;
  number = number.toString();
  number = number.substring(0, number.indexOf(".") + (decimals + 1));
  return Number(number);
}

export function formatBalance(value) {
  return `${roundNumber(value, 2)} ETH`;
}

export function formatDateTime(date) {
  return moment(date).format("YYYY/MM/DD HH:mm");
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function isIos() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

export function isStandalone() {
  return (
    window.navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches
  );
}

export function fixUrl(url) {
  if (!url) {
    return url;
  }
  const protocolRegex = /^(f|ht)tps?:\/\//i;
  if (protocolRegex.test(url)) {
    return url;
  }
  return "https://" + url;
}
