import { useLangContext } from "../../../../context";
import { useEffect, useState } from "react";
import bar_red from "../../../../images/battle/bar_red.svg";
import bar_blue from "../../../../images/battle/bar_blue.svg";
import blur_black from "../../../../images/battle/blur_black.svg";
import blur_red from "../../../../images/battle/blur_red.svg";
import blur_blue from "../../../../images/battle/blur_blue.svg";
import ico_vs from "../../../../images/battle/ico_vs.svg";
import thunder1 from "../../../../images/battle/thunder1.png";
import thunder2 from "../../../../images/battle/thunder2.png";
import { fragmentInfos } from "../../../../data/regionInfo";

export default function GameIntroduction(props) {
  const { langValue } = useLangContext();
  const { fragmentId, attack, defense } = props;
  const [defCharacter, setDefCharacter] = useState();

  useEffect(() => {
    setTimeout(() => {
      props.setStep(props.step + 1);
    }, 4000);
  }, []);

  useEffect(() => {
    if (defense.length) {
      const name = defense[0].attributes.find(
        (o) => o.trait_type === "Name",
      ).value;
      setDefCharacter(`/images/battle/illusts/${name}.png`);
    } else {
      setDefCharacter("/images/battle/enemy_mob.png");
    }
  }, [defense]);

  return (
    <>
      <div class="flex-1 overflow-auto bg-black">
        <div class="flex h-full flex-col justify-center">
          <div class="text-center text-white">
            <img
              src={fragmentInfos[fragmentId]?.image}
              alt=""
              class="mx-auto w-20"
            />
            <p class="text-xs">
              {langValue === "ja" && <>争奪するフラグメント</>}
              {langValue === "en" && <>Target Fragment</>}
            </p>
            <p class="text-xl">
              {langValue === "ja" && <>{fragmentInfos[fragmentId]?.name_ja}</>}
              {langValue === "en" && <>{fragmentInfos[fragmentId]?.name_en}</>}
            </p>
          </div>

          <div class="mt-6 flex h-2/5 items-center justify-center overflow-hidden">
            <div class="absolute overflow-hidden">
              <img
                src={thunder1}
                alt=""
                class="battle__thunder1 relative -top-2"
              />
              <img
                src={thunder2}
                alt=""
                class="battle__thunder2 relative top-2"
              />
            </div>
            <img
              src={defCharacter}
              alt=""
              class="relative mx-auto w-4/5 scale-x-[-1] transform"
            />
          </div>

          <div class="overflow-hidden">
            <div class="relative flex h-[25vh] items-center justify-between">
              <img
                src={blur_blue}
                alt=""
                class="absolute right-1/3 top-1/2 z-0 -translate-y-1/2 transform"
              />
              <img
                src={blur_red}
                alt=""
                class="absolute left-1/3 top-1/2 z-0 -translate-y-1/2 transform"
              />
              <div class="absolute left-1/2 top-1/2 z-10 flex w-full -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
                <img
                  src={blur_black}
                  alt=""
                  class="battle__blur-black w-6/12"
                />
              </div>
              <img
                src={ico_vs}
                alt=""
                class="absolute left-1/2 top-1/2 z-10 w-16 -translate-x-1/2 -translate-y-1/2 transform"
              />
              <div class="battle__blur-blue flex h-full w-1/2 items-center justify-center">
                <div class="absolute z-0 w-1/3">
                  <div class="battle-bars flex justify-between">
                    <img src={bar_blue} alt="" class="battle__bar1" />
                    <img src={bar_blue} alt="" class="battle__bar2" />
                    <img src={bar_blue} alt="" class="battle__bar3" />
                    <img src={bar_blue} alt="" class="battle__bar4" />
                    <img src={bar_blue} alt="" class="battle__bar5" />
                    <img src={bar_blue} alt="" class="battle__bar6" />
                    <img src={bar_blue} alt="" class="battle__bar7" />
                    <img src={bar_blue} alt="" class="battle__bar8" />
                  </div>
                </div>

                <ul class="relative z-10 flex w-40 flex-wrap justify-center">
                  {defense.length > 0 ? (
                    defense.map((chara, key) => {
                      return (
                        <li key={key}>
                          <img
                            src={chara?.image}
                            alt=""
                            class="w-11 scale-x-[-1] transform"
                          />
                        </li>
                      );
                    })
                  ) : (
                    <>
                      <li>
                        <img
                          src="/images/battle/icon_mob.png"
                          alt=""
                          class="scale-x transform"
                        />
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div class="battle__blur-red flex h-full w-1/2 items-center justify-center">
                <div class="absolute z-0 w-1/3">
                  <div class="battle-bars flex justify-between">
                    <img src={bar_red} alt="" class="battle__bar1" />
                    <img src={bar_red} alt="" class="battle__bar2" />
                    <img src={bar_red} alt="" class="battle__bar3" />
                    <img src={bar_red} alt="" class="battle__bar4" />
                    <img src={bar_red} alt="" class="battle__bar5" />
                    <img src={bar_red} alt="" class="battle__bar6" />
                    <img src={bar_red} alt="" class="battle__bar7" />
                    <img src={bar_red} alt="" class="battle__bar8" />
                  </div>
                </div>

                <ul class="relative z-10 flex w-40 flex-wrap justify-center">
                  {attack.length > 0 ? (
                    attack.map((chara, key) => {
                      return (
                        <li key={key}>
                          <img src={chara?.image} alt="" class="w-11" />
                        </li>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
