import ico_trash_dark from "../../../../../images/ico_trash_dark.svg";

export default function ItemLv1({ image, level, amount, itemTrash, onClick }) {
  return (
    <li
      class="relative rounded-lg bg-gradient-to-b from-[#C7C450] to-[#E5E4B4]"
      onClick={onClick}
    >
      <img src={image} alt="" class="w-18 -mt-2 pb-2" />
      <div class="absolute bottom-0 flex w-full justify-between rounded-b-lg bg-[#7C7A2D] px-2 text-xs">
        <span class="text-yellow-300">Lv {level}</span>
        <span class="text-white">{amount}</span>
      </div>
      {itemTrash && (
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <img src={ico_trash_dark} alt="" />
          <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/3 transform text-lg text-white">
            {itemTrash.qty}
          </span>
        </div>
      )}
    </li>
  );
}
