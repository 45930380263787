import React, { useState, useMemo } from "react";
import { idSorter } from "../util";

const CharacterContext = React.createContext();

export function CharacterProvider({ children }) {
  const [characters, _setCharacters] = useState([]);
  const setCharacters = (newCharacters) => {
    _setCharacters(newCharacters.sort(idSorter));
  };

  const characterMap = useMemo(() => {
    const _map = {};
    for (const chara of characters) {
      _map[chara.id] = chara;
    }
    return _map;
  }, [characters]);

  function getAllCharacters() {
    return characters;
  }

  function setCharacterById(id, character) {
    const tmp = new Map(characters);
    tmp[id] = Object.assign({ id, character });
    setCharacters(tmp);
  }

  return (
    <CharacterContext.Provider
      value={{
        characters,
        characterMap,
        getAllCharacters,
        setCharacters,
        setCharacterById,
      }}
    >
      {children}
    </CharacterContext.Provider>
  );
}

export const CharacterConsumer = CharacterContext.Consumer;
export default CharacterContext;
