// version 1 (testnet or mainnet v1) のノアの森の region ID
// TODO smart contract 上で ID が keccak hash 等になっている場合は
// 値を書き換えてください。書き換えた場合 src/data/area.json の id: noa_forest
// も同じ値に変更願います。
export const MapIdV1NoaForest = "noa_forest";
export const MapIdV1DagurCave = "dagur_cave";
export const MapIdV1OrdinaPlanes = "ordina_planes";

export const maxLevel = 10;
export const maxAtk = 110;
export const maxDef = 110;
export const maxLuk = 100;
export const maxSeeds = 10;
