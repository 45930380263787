import { Link } from "react-router-dom";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../../context";
import { useEffect, useMemo, useState } from "react";

export default function GameResultLink(props) {
  const { langValue } = useLangContext();
  const { matchStatus, isekaiBattleFragment } = useGameContext();
  const { account } = useApiContext();
  const { fragmentId } = props;
  const [isGetSinki, setGetSinki] = useState(false);

  const fragmentIds = useMemo(() => {
    const fgmTypes = parseInt((Number(fragmentId) + 7) / 7);
    const minId = fgmTypes * 7 - 7;
    const maxId = fgmTypes * 7 - 1;
    return Array.from({ length: maxId - minId + 1 }, (_, i) => minId + i);
  }, [fragmentId]);

  useEffect(() => {
    if (isekaiBattleFragment && matchStatus === "WIN") {
      (async () => {
        const balances = await isekaiBattleFragment.methods
          .balanceOfBatch(Array(fragmentIds.length).fill(account), fragmentIds)
          .call();
        setGetSinki(balances.reduce((a, b) => Number(a) + Number(b)) >= 7);
      })();
    }
  }, [fragmentIds, matchStatus]);

  return (
    <>
      <div class="absolute bottom-2 z-10 flex w-full flex-col gap-2 p-4">
        {matchStatus === "WIN" && isGetSinki && (
          <Link
            to="/treasures"
            class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
          >
            {langValue === "ja" && <>SINKIを手に入れる</>}
            {langValue === "en" && <>Get SINKI</>}
          </Link>
        )}
        <Link
          to="/explore"
          class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
        >
          {langValue === "ja" && <>探索に戻る</>}
          {langValue === "en" && <>Back to Exploration</>}
        </Link>
        <Link
          to="/battle/history"
          class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
        >
          {langValue === "ja" && <>戦闘履歴</>}
          {langValue === "en" && <>Battle Record</>}
        </Link>
      </div>
    </>
  );
}
