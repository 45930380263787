import { useState, useEffect } from "react";
import settings from "../../config/settings";
import {
  useApiContext,
  useFormationContext,
  useCharacterContext,
  useGameContext,
} from "../../context";
import { MapIdV1DagurCave, MapIdV1OrdinaPlanes } from "../../constant";
import { useNavigate } from "react-router-dom";
import Web3 from "web3/dist/web3.min";

import Header from "./header";
import Navigation from "./havigation";
import SoundEffect from "../common/SoundEffect";

import * as utils from "../../util";
import StartPwa from "./start-pwa";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { armorImages, weaponImages } from "../../data/itemInfo";

function getWeaponsImage(type) {
  return weaponImages[type];
}
function getArmorImage(type) {
  return armorImages[type];
}

export default function Master(props) {
  const { walletProvider } = useWeb3ModalProvider();
  const { loadFormation } = useFormationContext();
  const { characters, setCharacters } = useCharacterContext();
  const {
    setIsekaiBattleClash,
    setIsekaiBattleFragment,
    setIsekaiBattleSinki,
    setIsekaiBattleStake,
    setIsekaiBattle,
    setTrashIsekaiBattleItems,
    setIsekaiBattleBag,
    setIsekaiBattleStatusManager,
    setIsekaiBattleMagicCircle,
    setAccountBalance,
  } = useGameContext();
  const [loading, setLoading] = useState(false);
  const {
    isAuthenticated,
    isWeb3Enabled,
    isWeb3EnableLoading,
    logout,
    account,
    enableWeb3,
    provider,
    Web3Api,
    switchNetwork,
    chainId,
    targetChainId,
    chainName,
    chainLabel,
    refreshAuth,
  } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else {
      refreshAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (isWeb3EnableLoading && !isWeb3Enabled) {
      return;
    }

    if (isWeb3Enabled) {
      fetchNFTsForContract();
      loadBalance();
    } else if (walletProvider) {
      enableWeb3();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb3Enabled, isWeb3EnableLoading, walletProvider]);

  const loadBalance = async () => {
    const _web3 = new Web3(provider);
    const balance = await _web3.eth.getBalance(account);
    setAccountBalance(Web3.utils.fromWei(balance, "ether"));
  };

  const fetchNFTsForContract = async () => {
    if (chainId && chainId !== targetChainId) {
      switchNetwork(targetChainId);
      alert(`${chainLabel(targetChainId)} に接続してください。`);
      await logout();
      return;
    }
    if (loading || characters.length !== 0) {
      return;
    }
    const chain = chainName(targetChainId);
    setLoading(true);
    const _web3 = new Web3(provider);
    _web3.eth.Contract.setProvider(provider);
    _web3.eth.defaultAccount = account;
    const _isekaiBattleStake = new _web3.eth.Contract(
      settings.abis.IsekaiBattleStake.concat([
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "user",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "tokenId",
              type: "uint256",
            },
          ],
          name: "ClaimFragment",
          type: "event",
        },
      ]),
      settings.addresses.IsekaiBattleStake,
    );
    const _isekaiBattleFragment = new _web3.eth.Contract(
      settings.abis.IsekaiBattleFragment,
      settings.addresses.IsekaiBattleFragment,
    );
    const _isekaiBattleClash = new _web3.eth.Contract(
      settings.abis.IsekaiBattleClash,
      settings.addresses.IsekaiBattleClash,
    );

    const _isekaiBattleSinki = new _web3.eth.Contract(
      settings.abis.IsekaiBattleSinki,
      settings.addresses.IsekaiBattleSinki,
    );

    const _isekaiBattle = new _web3.eth.Contract(
      settings.abis.IsekaiBattle,
      settings.addresses.IsekaiBattle,
    );

    const _trashIsekaiBattleItems = new _web3.eth.Contract(
      settings.abis.TrashIsekaiBattleItems,
      settings.addresses.TrashIsekaiBattleItems,
    );

    const _isekaiBattleBag = new _web3.eth.Contract(
      settings.abis.IsekaiBattleBag,
      settings.addresses.IsekaiBattleBag,
    );

    const _isekaiBattleStatusManager = new _web3.eth.Contract(
      settings.abis.IsekaiBattleStatusManager,
      settings.addresses.IsekaiBattleStatusManager,
    );

    const _isekaiBattleMagicCircle = new _web3.eth.Contract(
      settings.abis.IsekaiBattleMagicCircle,
      settings.addresses.IsekaiBattleMagicCircle,
    );

    setIsekaiBattleStake(_isekaiBattleStake);
    setIsekaiBattleFragment(_isekaiBattleFragment);
    setIsekaiBattleClash(_isekaiBattleClash);
    setIsekaiBattleSinki(_isekaiBattleSinki);
    setIsekaiBattle(_isekaiBattle);
    setTrashIsekaiBattleItems(_trashIsekaiBattleItems);
    setIsekaiBattleBag(_isekaiBattleBag);
    setIsekaiBattleStatusManager(_isekaiBattleStatusManager);
    setIsekaiBattleMagicCircle(_isekaiBattleMagicCircle);

    const stakingInfoIds = await _isekaiBattleStake.methods
      .getAllStakingTokenIds(account)
      .call({ from: account });
    let batchTokenIds = [];
    const stakingTokenIds = [];
    for (let i = 0; i < stakingInfoIds.length; i++) {
      const ids = stakingInfoIds[i].map((x) => {
        if (typeof x === "string") {
          return parseInt(x, 10);
        } else {
          return x;
        }
      });
      batchTokenIds = batchTokenIds.concat(ids);

      const remaining = 15;
      stakingTokenIds.push(
        ids.concat(
          new Array(
            remaining - (ids.length > remaining ? remaining : ids.length),
          ).fill(null),
        ),
      );
    }

    const stakingTroops = await _isekaiBattleStake.methods
      .getTroops(account)
      .call({ from: account });
    const troops = [];
    for (let i = 0; i < 2; i++) {
      const ids = stakingTroops[i].map((x) => {
        if (typeof x === "string") {
          return parseInt(x, 10);
        } else {
          return x;
        }
      });
      batchTokenIds = batchTokenIds.concat(ids);
      const remaining = 5;
      troops.push(
        ids.concat(
          new Array(
            remaining - (ids.length > remaining ? remaining : ids.length),
          ).fill(null),
        ),
      );
    }

    let characterResults = [];
    if (batchTokenIds.length > 0) {
      characterResults = await Web3Api.token.getBatchTokenIdMetadata({
        address: settings.addresses.IsekaiBattle,
        token_ids: batchTokenIds,
        chain,
      });
    }

    const charactersRes = await Web3Api.account.getNFTsForContract({
      chain,
      address: account,
      token_address: settings.addresses.IsekaiBattle,
    });
    characterResults.push(...charactersRes.result.filter((x) => x.metadata));

    if (characterResults.length < 3) {
      alert("最低3体必要です");
      await logout();
      return;
    }

    const characterFilter = (x) => {
      const metadata = JSON.parse(x.metadata);
      return {
        id: x.token_id,
        gen: metadata.attributes.find((y) => y.trait_type === "Generation Type")
          .value,
        name: metadata.name,
        level: metadata.attributes.find((y) => y.trait_type === "Level").value,
        attack: metadata.attributes.find((y) => y.trait_type === "ATK").value,
        defence: metadata.attributes.find((y) => y.trait_type === "DEF").value,
        luck: metadata.attributes.find((y) => y.trait_type === "LUK").value,
        image: metadata.image,
        weapon: getWeaponsImage(
          metadata.attributes.find((y) => y.trait_type === "Weapon").value,
        ),
        armor: getArmorImage(
          metadata.attributes.find((y) => y.trait_type === "Armor").value,
        ),
        weaponType: metadata.attributes.find((y) => y.trait_type === "Weapon")
          .value,
        armorType: metadata.attributes.find((y) => y.trait_type === "Armor")
          .value,
        usedSeeds: metadata.attributes.find((y) => y.trait_type === "Used Seed")
          .value,
        attributes: metadata.attributes,
      };
    };
    setCharacters(characterResults.map(characterFilter));

    loadFormation({
      [MapIdV1DagurCave]: stakingTokenIds[0],
      [MapIdV1OrdinaPlanes]: stakingTokenIds[1],
      attack: troops[0],
      defence: troops[1],
    });

    setLoading(false);
  };

  if (!utils.isStandalone()) {
    return <StartPwa />;
  }

  return (
    <>
      {props.fullMode ? (
        props.children
      ) : (
        <>
          <Header />
          {loading ? (
            <div class="flex h-screen flex-col"></div>
          ) : (
            props.children
          )}
          <SoundEffect />
          <Navigation />
        </>
      )}
    </>
  );
}
