import powerIco from "../../../../images/seeds/power.png";
import defenceIco from "../../../../images/seeds/defence.png";
import luckIco from "../../../../images/seeds/luck.png";
import btnClose from "../../../../images/btn_close.svg";
import { useLangContext } from "../../../../context";
import { MAX_ATK, MAX_DEF, MAX_LUK } from "../../../../data/regionInfo";

export default function CharacterDetail(props) {
  const { selectedCharacter } = props;
  const { langValue } = useLangContext();

  const powerProgress = (type, value) => {
    let power = 0;
    switch (type) {
      case "ATK":
        power = MAX_ATK;
        break;
      case "DEF":
        power = MAX_DEF;
        break;
      case "LUK":
        power = MAX_LUK;
        break;
      default:
        break;
    }
    return Math.floor((100 * value) / power);
  };

  return (
    <>
      <div class="absolute left-0 top-0 z-0 h-full w-full bg-black bg-opacity-80 p-6 text-white">
        <div class="relative h-full h-full overflow-auto rounded-lg bg-gradient-to-br from-[#F6B08E] via-[#8C4D78] to-[#261C51] p-4 text-white">
          <button class="absolute right-4 top-5" onClick={props.onClose}>
            <img src={btnClose} alt="" />
          </button>
          <div class="mt-8 text-center text-black">
            <div class="mr-1 text-4xl">{selectedCharacter.attrName}</div>
            <div class="mt-1">
              <span class="mr-1 text-lg">#{selectedCharacter.id}</span>
              <span class="relative -top-1 ml-2 rounded bg-black px-2 py-1 font-sans text-xs font-bold text-white">
                {selectedCharacter.gen}
              </span>
            </div>
          </div>

          <div class="border-t border-black border-opacity-10">
            <img
              src={selectedCharacter.image}
              alt=""
              class="mx-auto mt-4 w-36"
            />
            <div class="mt-4 flex justify-center gap-6 text-center">
              <div class="text-4xl">
                <div class="text-sm">Lv</div>
                {selectedCharacter.level}
              </div>
              <div class="text-4xl">
                <div class="text-sm">ATK</div>
                {selectedCharacter.attack}
              </div>
              <div class="text-4xl">
                <div class="text-sm">DEF</div>
                {selectedCharacter.defence}
              </div>
              <div class="text-4xl">
                <div class="text-sm">LUK</div>
                {selectedCharacter.luck}
              </div>
            </div>
          </div>

          <div class="mt-4 grid grid-cols-2 border-t border-white border-opacity-10 pt-4">
            <div class="text-center text-2xl">
              <div class="text-sm">Species</div>
              {selectedCharacter.species}
            </div>
            <div class="text-center text-2xl">
              <div class="text-sm">Heritage</div>
              {selectedCharacter.heritage}
            </div>
            <div class="mt-4 text-center text-2xl">
              <div class="text-sm">Personality</div>
              {selectedCharacter.personality}
            </div>
            <div class="mt-4 text-center text-2xl">
              <div class="text-sm">Sex</div>
              {selectedCharacter.sex}
            </div>
          </div>

          <div class="mt-6 flex justify-center gap-4">
            <div>
              <span class="block text-sm">Seeds</span>
              <span class="text-2xl">
                {selectedCharacter.usedSeeds}
                <span class="text-sm">/10</span>
              </span>
            </div>
            <div>
              <div class="flex items-center">
                <img src={powerIco} alt="" class="-my-1.5 w-7" />
                <div class="h-0.5 w-14 bg-white bg-opacity-20">
                  <div
                    class={`h-0.5 w-[${powerProgress("ATK", selectedCharacter.attack)}%] bg-gradient-to-r from-[#BA2709] to-[#873A2A]`}
                  ></div>
                </div>
              </div>
              <div class="flex items-center">
                <img src={defenceIco} alt="" class="-my-1.5 w-7" />
                <div class="h-0.5 w-14 bg-white bg-opacity-20">
                  <div
                    class={`h-0.5 w-[${powerProgress("DEF", selectedCharacter.defence)}%] bg-gradient-to-r from-[#B37811] to-[#81632F]`}
                  ></div>
                </div>
              </div>
              <div class="flex items-center">
                <img src={luckIco} alt="" class="-my-1.5 w-7" />
                <div class="h-0.5 w-14 bg-white bg-opacity-20">
                  <div
                    class={`h-0.5 w-[${powerProgress("LUK", selectedCharacter.luck)}%] bg-gradient-to-r from-[#DAB211] to-[#AB9027]`}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="sticky bottom-0 z-10 mt-10 flex flex-col gap-2">
            <button
              class={`block w-full rounded-full  p-3 text-center text-lg tracking-widest text-black ${selectedCharacter.usedSeeds === 10 ? "bg-gray text-gray-700 drop-shadow-sm" : "bg-white"}`}
              onClick={() => {
                if (selectedCharacter.usedSeeds === 10) return;
                props.openSeeds();
              }}
            >
              {langValue === "ja" && <>種を使う</>}
              {langValue === "en" && <>Use seed</>}
            </button>
            <button
              class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
              onClick={props.onClose}
            >
              {langValue === "ja" && <>閉じる</>}
              {langValue === "en" && <>Close</>}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
