// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const UrlFirebaseConfig = new URLSearchParams(firebaseConfig.toString());
const swUrl = `/firebase-messaging-sw.js?${UrlFirebaseConfig}`;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          },
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

onMessage(messaging, (event) => {
  if (event.data?.body) {
    if (Notification.permission == "granted") {
      getOrRegisterServiceWorker().then((registration) => {
        registration.showNotification(event.data?.title ?? "", {
          body: event.data?.body,
          data: {
            fcmOptions: {
              link: event.data?.link,
            },
          },
        });
      });
    }
  }
});

const getMessagingToken = async () => {
  return await getOrRegisterServiceWorker().then(async (registration) => {
    return await getToken(messaging).then((currentToken) => {
      return currentToken;
    });
  });
};

export { getMessagingToken };
