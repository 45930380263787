import { useGameContext, useLangContext } from "../../../../context";
import bar_red from "../../../../images/battle/bar_red.svg";
import bar_blue from "../../../../images/battle/bar_blue.svg";
import blur_black from "../../../../images/battle/blur_black.svg";
import blur_red from "../../../../images/battle/blur_red.svg";
import blur_blue from "../../../../images/battle/blur_blue.svg";
import ico_vs from "../../../../images/battle/ico_vs.svg";
import bg_ordina from "../../../../images/battle/bg_ordina.jpg";

import { useEffect, useState } from "react";
import GameResult from "./GameResult";
import GameResultLink from "./GameResultLink";
import { fragmentInfos } from "../../../../data/regionInfo";

export default function GameBattle1(props) {
  const { langValue } = useLangContext();
  const { fragmentId, attack, defense } = props;
  const { matchStatus } = useGameContext();
  const [result, setResult] = useState(false);

  useEffect(() => {
    if (window && document) {
      function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      }

      // 画面下のパーティメンバー背景
      const bars = document.querySelectorAll(".battle-bars img");
      bars.forEach((bar) => {
        bar.style.animationDelay = `${Math.floor(Math.random() * 5)}s`;
        bar.style.animationDuration = `${Math.floor(Math.random() * 5) + 2}s`;
      });

      // -----------------------------------

      // const walkStart = 0;
      const smokeStart = 900;
      const starStart = 2000;
      const blowAwayStart = 2500;
      const smokeEnd = 6000;
      const starEnd = 6000;

      const blowAwayDelayMax = 3000;

      // キャラクターの配置
      const blueWrapper = document.querySelector(".battle__blue");
      const redWrapper = document.querySelector(".battle__red");

      for (let i = 1; i < 6; i++) {
        const column = document.createElement("div");
        for (let ii = 0; ii < 4; ii++) {
          const div = document.createElement("div");
          const img = document.createElement("img");
          img.src = `/images/battle/mob/blue${i}.gif`;
          img.alt = "";
          img.classList.add("w-8");
          div.appendChild(img);
          column.appendChild(div);
        }
        blueWrapper.appendChild(column);
      }

      for (let i = 1; i < 6; i++) {
        const column = document.createElement("div");
        for (let ii = 0; ii < 4; ii++) {
          const div = document.createElement("div");
          const img = document.createElement("img");
          img.src = `/images/battle/mob/red${i}.gif`;
          img.alt = "";
          img.classList.add("w-8", "scale-x-[-1]");
          div.appendChild(img);
          column.appendChild(div);
        }
        redWrapper.appendChild(column);
      }

      // 歩く
      blueWrapper.classList.add("battle__blue--walk");
      redWrapper.classList.add("battle__red--walk");

      const blues = document.querySelectorAll(".battle__blue img");
      const reds = document.querySelectorAll(".battle__red img");

      // 勝敗判定
      const winner = matchStatus === "WIN" ? "red" : "blue";

      // 間引く対象
      const targets = shuffle([...Array(20).keys()]).slice(
        0,
        Math.random() * 20 - 1,
      );

      // キャラを飛ばす
      setTimeout(blowAway, blowAwayStart);
      function blowAway() {
        blues.forEach((blue, i) => {
          if (winner !== "blue" || targets.includes(i)) {
            const delay = `${Math.floor(Math.random() * blowAwayDelayMax)}ms`;
            const parent = blue.parentElement;

            parent.classList.add("battle__blue--deadBlowAway", "block");
            blue.classList.add("battle__blue--deadRolling");
            parent.style.animationDelay = delay;
            blue.style.animationDelay = delay;
          }
        });

        reds.forEach((red, i) => {
          if (winner !== "red" || targets.includes(i)) {
            const delay = `${Math.floor(Math.random() * blowAwayDelayMax)}ms`;
            const parent = red.parentElement;

            parent.classList.add("battle__red--deadBlowAway", "block");
            red.classList.add("battle__red--deadRolling");
            parent.style.animationDelay = delay;
            red.style.animationDelay = delay;
          }
        });
      }

      // 戦闘中の煙を出す
      setTimeout(setSmoke, smokeStart);
      function setSmoke() {
        for (let i = 0; i < 40; i++) {
          const smoke = document.createElement("img");
          smoke.src = `/images/battle/smoke${(i % 3) + 1}.svg`;
          smoke.alt = "";
          smoke.classList.add("battle__smoke", "absolute");

          const sizes = [12, 14, 16, 20, 24, 28];
          const size = sizes[Math.floor(Math.random() * sizes.length)];
          smoke.classList.add(`w-${size}`, `h-${size}`);

          smoke.style.animationDelay = `${Math.floor(Math.random() * 5000)}ms`;
          const smokeDurationA = Math.floor(Math.random() * 5) + 3;
          const smokeDurationB = Math.floor(Math.random() * 2000) + 500;
          smoke.style.animation = `floatSmoke ${smokeDurationA}s linear infinite, softBounce ${smokeDurationB}ms linear infinite`;
          smoke.style.left = `${Math.floor(Math.random() * 60) + 10}%`;
          document.querySelector("#battle-smoke").appendChild(smoke);
        }
      }

      // 戦闘中の煙を消す
      setTimeout(removeSmoke, smokeEnd);
      function removeSmoke() {
        const smokeWrapper = document.querySelector("#battle-smoke");
        smokeWrapper.classList.add("battle__smoke--remove");
      }

      // -----------------------------------

      // 戦闘中の煙2を出す
      setTimeout(setSmoke2, starStart);
      function setSmoke2() {
        for (let i = 0; i < 15; i++) {
          const div = document.createElement("div");
          const star = document.createElement("img");
          star.src = "/images/battle/smoke3.svg";
          star.alt = "";
          star.classList.add("battle__smoke2", "absolute");

          const rotates = [10, 20, 40, 50, 60, -60, -50, -40, -20, -10];
          const rotate = rotates[Math.floor(Math.random() * rotates.length)];
          const left = Math.floor(Math.random() * 20) + 40;
          div.classList.add("battle__smoke2-wrapper");
          div.style.transform = `rotate(${rotate}deg)`;
          div.style.bottom = `${Math.floor(Math.random() * 12) + 3}vh`;
          div.style.left =
            (rotate > 0 && left < 45) || (rotate < 0 && left > 45)
              ? `${left * -1}vw`
              : `${left}vw`;

          star.style.animationDelay = `${Math.floor(Math.random() * 3000)}ms`;
          star.style.right = `${Math.floor(Math.random() * 10)}%`;
          document
            .querySelector("#battle-smoke2")
            .appendChild(div)
            .appendChild(star);
        }
      }

      // 戦闘中の★を消す
      setTimeout(removeSmoke2, starEnd);
      function removeSmoke2() {
        const starWrapper = document.querySelector("#battle-smoke2");
        starWrapper.classList.add("battle__smoke2--remove");
      }

      // -----------------------------------

      // 戦闘中の★を出す
      setTimeout(setStar, starStart);
      function setStar() {
        for (let i = 0; i < 7; i++) {
          const div = document.createElement("div");
          const star = document.createElement("img");
          star.src = "/images/battle/star.svg";
          star.alt = "";
          star.classList.add("battle__star", "absolute");

          const rotates = [10, 20, 40, 50, -50, -40, -20, -10];
          const rotate = rotates[Math.floor(Math.random() * rotates.length)];
          const left = Math.floor(Math.random() * 70) + 12;
          const size = Math.floor(Math.random() * 50) + 10;
          div.classList.add("battle__star-wrapper");
          div.style.transform = `rotate(${rotate}deg)`;
          div.style.bottom = `${Math.floor(Math.random() * 15) + 3}vh`;
          div.style.left = `${left}vw`;
          div.style.width = `${size}px`;
          div.style.height = `${size}px`;

          star.style.animationDelay = `${Math.floor(Math.random() * 3000)}ms`;
          star.style.right = `${Math.floor(Math.random() * 10)}%`;
          document
            .querySelector("#battle-stars")
            .appendChild(div)
            .appendChild(star);
        }
      }

      // 戦闘中の★を消す
      setTimeout(removeStars, starEnd);
      function removeStars() {
        const starWrapper = document.querySelector("#battle-stars");
        starWrapper.classList.add("battle__star--remove");
      }

      setTimeout(() => {
        setResult(true);
      }, 6000);
      return () => {
        if (blueWrapper) blueWrapper.innerHTML = "";
        if (redWrapper) redWrapper.innerHTML = "";
        setResult(false);
      };
    }
  }, []);

  return (
    <>
      <div class="relative flex-1 overflow-auto bg-black">
        <div class="flex h-full flex-col justify-center">
          <div class="text-center text-white">
            <img
              src={fragmentInfos[fragmentId]?.image}
              alt=""
              class="mx-auto w-20"
            />
            <p class="text-xs">
              {langValue === "ja" && <>争奪するフラグメント</>}
              {langValue === "en" && <>Target Fragment</>}
            </p>
            <p class="text-xl">
              {langValue === "ja" && <>{fragmentInfos[fragmentId]?.name_ja}</>}
              {langValue === "en" && <>{fragmentInfos[fragmentId]?.name_en}</>}
            </p>
          </div>

          <div
            class="relative mt-6 flex h-2/5 items-end justify-center overflow-hidden bg-cover pb-3"
            style={{
              backgroundImage: `url(${bg_ordina})`,
            }}
          >
            {/* キャラクターの配置  */}
            <div class="flex gap-4">
              <div class="battle__blue flex"></div>
              <div class="battle__red flex flex-row-reverse"></div>
            </div>

            <div id="battle-smoke" class="absolute top-0 h-full w-full"></div>
            <div id="battle-smoke2" class="absolute top-0 h-full w-full"></div>
            <div id="battle-stars" class="absolute top-0 h-full w-full"></div>

            {result && <GameResult />}
          </div>

          <div class="overflow-hidden">
            <div class="relative flex h-[25vh] items-center justify-between">
              <img
                src={blur_blue}
                alt=""
                class="absolute right-1/3 top-1/2 z-0 -translate-y-1/2 transform"
              />
              <img
                src={blur_red}
                alt=""
                class="absolute left-1/3 top-1/2 z-0 -translate-y-1/2 transform"
              />
              <div class="absolute left-1/2 top-1/2 z-10 flex w-full -translate-x-1/2 -translate-y-1/2 transform items-center justify-center">
                <img
                  src={blur_black}
                  alt=""
                  class="battle__blur-black w-6/12"
                />
              </div>
              <img
                src={ico_vs}
                alt=""
                class="absolute left-1/2 top-1/2 z-10 w-16 -translate-x-1/2 -translate-y-1/2 transform"
              />
              <div class="battle__blur-blue flex h-full w-1/2 items-center justify-center">
                <div class="absolute z-0 w-1/3">
                  <div class="battle-bars flex justify-between">
                    <img src={bar_blue} alt="" class="battle__bar1" />
                    <img src={bar_blue} alt="" class="battle__bar2" />
                    <img src={bar_blue} alt="" class="battle__bar3" />
                    <img src={bar_blue} alt="" class="battle__bar4" />
                    <img src={bar_blue} alt="" class="battle__bar5" />
                    <img src={bar_blue} alt="" class="battle__bar6" />
                    <img src={bar_blue} alt="" class="battle__bar7" />
                    <img src={bar_blue} alt="" class="battle__bar8" />
                  </div>
                </div>

                <ul class="relative z-10 flex w-40 flex-wrap justify-center">
                  {defense.length > 0 ? (
                    defense.map((chara, key) => {
                      return (
                        <li key={key}>
                          <img
                            src={chara?.image}
                            alt=""
                            class="w-11 scale-x-[-1] transform"
                          />
                        </li>
                      );
                    })
                  ) : (
                    <>
                      <li>
                        <img
                          src="/images/battle/icon_mob.png"
                          alt=""
                          class="scale-x transform"
                        />
                      </li>
                    </>
                  )}
                </ul>
              </div>

              <div class="battle__blur-red flex h-full w-1/2 items-center justify-center">
                <div class="absolute z-0 w-1/3">
                  <div class="battle-bars flex justify-between">
                    <img src={bar_red} alt="" class="battle__bar1" />
                    <img src={bar_red} alt="" class="battle__bar2" />
                    <img src={bar_red} alt="" class="battle__bar3" />
                    <img src={bar_red} alt="" class="battle__bar4" />
                    <img src={bar_red} alt="" class="battle__bar5" />
                    <img src={bar_red} alt="" class="battle__bar6" />
                    <img src={bar_red} alt="" class="battle__bar7" />
                    <img src={bar_red} alt="" class="battle__bar8" />
                  </div>
                </div>

                <ul class="relative z-10 flex w-40 flex-wrap justify-center">
                  {attack.length > 0 ? (
                    attack.map((chara, key) => {
                      return (
                        <li key={key}>
                          <img src={chara?.image} alt="" class="w-11" />
                        </li>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {result && <GameResultLink fragmentId={fragmentId} />}
        </div>
      </div>
    </>
  );
}
