import { useEffect, useState } from "react";
import { useApiContext, useLangContext } from "../../../context";
import settings from "../../../config/settings";
import btnClose from "../../../images/btn_close.svg";
import icoX from "../../../images/ico_x.svg";

export default function ItemFoundClaimed(props) {
  const { langValue } = useLangContext();
  const { Web3Api, chainId, chainName } = useApiContext();
  const [twitterUrl, setTwitterUrl] = useState("#");

  const [items, setItems] = useState([]);

  useEffect(() => {
    props.loadStakeInfo();
  }, []);

  useEffect(() => {
    fetchNFTsForContract(props.claimed);
  }, [props.claimed, chainId]);

  const indexToIds = (arr) => {
    const tokenIds = [];
    const amounts = {};
    for (let i = 0; i < arr.length; i++) {
      const amount = arr[i];
      if (Number(amount) > 0) {
        tokenIds.push(i);
        amounts[i] = amount;
      }
    }
    return {
      tokenIds,
      amounts,
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNFTsForContract = async (claimed) => {
    if (!chainId) return;
    let lists = [];
    const chain = chainName(chainId);

    if (claimed.fragment && claimed.fragment.length > 0) {
      const options = {
        address: settings.addresses.IsekaiBattleFragment,
        token_ids: [...new Set(claimed.fragment)],
        chain,
      };
      const _fragments = await Web3Api.token.getBatchTokenIdMetadata(options);
      if (Array.isArray(_fragments)) {
        lists = lists.concat(
          _fragments.map((x) => {
            x.category = "Fragment";
            x.image = JSON.parse(x.metadata).image;
            x.type = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Type",
            ).value;
            x.qty = 1;
            return x;
          }),
        );
      }
    }

    if (claimed.armor && claimed.armor.length > 0) {
      const { tokenIds, amounts } = indexToIds(claimed.armor);
      const options = {
        address: settings.addresses.IsekaiBattleArmor,
        token_ids: tokenIds,
        chain,
      };
      const _armors = await Web3Api.token.getBatchTokenIdMetadata(options);
      if (Array.isArray(_armors)) {
        lists = lists.concat(
          _armors.map((x) => {
            x.category = "Armor";
            x.image = JSON.parse(x.metadata).image;
            x.lv = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.star = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.type = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Type",
            ).value;
            x.qty = amounts[x.id];
            return x;
          }),
        );
      }
    }

    if (claimed.weapon && claimed.weapon.length > 0) {
      const { tokenIds, amounts } = indexToIds(claimed.weapon);
      const options = {
        address: settings.addresses.IsekaiBattleWeapon,
        token_ids: tokenIds,
        chain,
      };
      const _weapons = await Web3Api.token.getBatchTokenIdMetadata(options);
      if (Array.isArray(_weapons)) {
        lists = lists.concat(
          _weapons.map((x) => {
            x.category = "Weapon";
            x.image = JSON.parse(x.metadata).image;
            x.lv = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.star = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.type = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Type",
            ).value;
            x.qty = amounts[x.id];
            return x;
          }),
        );
      }
    }

    if (claimed.seed && claimed.seed.length > 0) {
      const options = {
        address: settings.addresses.IsekaiBattleSeeds,
        token_ids: [...new Set(claimed.seed)],
        chain,
      };
      const _seeds = await Web3Api.token.getBatchTokenIdMetadata(options);
      if (Array.isArray(_seeds)) {
        lists = lists.concat(
          _seeds.map((x) => {
            x.category = "Seed";
            x.image = JSON.parse(x.metadata).image;
            x.lv = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.star = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Lv",
            ).value;
            x.type = JSON.parse(x.metadata).attributes.find(
              (a) => a.trait_type === "Type",
            ).value;
            x.qty = 1;
            return x;
          }),
        );
      }
    }

    lists = lists.sort((a, b) => {
      if (a.strong && !b.strong) return -1;
      if (!a.strong && b.strong) return 1;
      if (a.category !== "seeds" && b.category === "seeds") return 1;
      if (b.category !== "seeds" && a.category === "seeds") return -1;
      if (a.category !== "weapons" && b.category === "weapons") return 1;
      if (b.category !== "weapons" && a.category === "weapons") return -1;
      if (a.category !== "armor" && b.category === "armor") return 1;
      if (b.category !== "armor" && a.category === "armor") return -1;
      if (Number.parseInt(a.lv) > Number.parseInt(b.lv)) return -1;
      if (Number.parseInt(b.lv) > Number.parseInt(a.lv)) return 1;
      if (Number.parseInt(a.id) > Number.parseInt(b.id)) return 1;
      if (Number.parseInt(b.id) > Number.parseInt(a.id)) return -1;
      return 0;
    });

    let text;
    if (langValue === "ja") {
      text = [
        `⚔フルオンチェーン NFT ゲーム #IsekaiBattle 探索でレアアイテム獲得! ${settings.twitterShareUrl}`,
      ];
    } else if (langValue === "en") {
      text = [
        "⚔Fully on-chain NFT game #IsekaiBattle Explore to find rare items!",
        settings.twitterShareUrl,
      ];
    }
    items.forEach((el) => {
      if (el.category === "Seed") {
        text.push(`${el.type} Seed Lv${el.lv}: +${el.qty}`);
      } else if (Number(el.lv) >= 3) {
        text.push(`${el.type} Lv${el.lv}: +${el.qty}`);
      } else if (el.category === "Fragment") {
        text.push(`${el.category}: +${el.qty}`);
      }
    });
    const encodedText = encodeURIComponent(text.join("\n"));
    setTwitterUrl(`https://twitter.com/intent/tweet?text=${encodedText}`);

    setItems(lists);
  };

  return (
    <div class=" relative flex flex-1 flex-col overflow-auto">
      <div class="map-claim h-full overflow-auto pb-36 pt-20">
        <button class="absolute right-4 top-5" onClick={props.onClose}>
          <img src={btnClose} alt="" />
        </button>

        <h1 class="map-claim-title pb-4 text-center text-2xl tracking-widest text-white">
          {langValue === "ja" && <>獲得</>}
          {langValue === "en" && <>Claimed</>}
        </h1>

        <div class="">
          <ul class="map-claim-list mx-4 mt-4 text-2xl tracking-widest text-white">
            {items.map((item, key) => {
              return (
                <li class="px-4 py-2" key={key}>
                  <span>
                    <img src={item.image} alt="" />
                  </span>
                  <span class="text-yellow-300">
                    {item.category === "Weapon" ? item.type : item.category}
                  </span>
                  {item.category === "Fragment" ? (
                    <span class=""></span>
                  ) : (
                    <span class="text-right text-lg">Lv{item.lv}</span>
                  )}
                  <span class="text-right">+{item.qty}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div class="map-claim-list__shadow"></div>
      <a
        href={twitterUrl}
        target="_blank"
        rel="noreferrer"
        class="fixed bottom-20 left-1/2 flex -translate-x-1/2 transform items-center gap-2 rounded-full border border-white bg-black px-10 py-1 text-lg uppercase text-white"
      >
        <img src={icoX} alt="" width="20" />
        Share
      </a>
    </div>
  );
}
