import { useEffect, useMemo, useState } from "react";
import {
  useApiContext,
  useCharacterContext,
  useGameContext,
  useItemContext,
  useLangContext,
} from "../../../context";
import Master from "../../layouts/master";
import UnitsSelectItem from "./components/units-select-item";
import CharacterDetail from "./components/character-detail";
import { sound_effect } from "../../common/SoundEffect";
import ItemSeedSelectCharacter from "../items/components/item-seed-select-character";
import ItemSeedSelectSeed from "../items/components/item-seed-select-seed";
import ItemSeedSelectConfirm from "../items/components/items-seed-select-confirm";
import ItemSeedSelectFinish from "../items/components/item-seed-select-finish";
import Web3 from "web3/dist/web3.min";
import settings from "../../../config/settings";
import Loading from "../../common/loading";

export default function Characters(props) {
  const { isekaiBattleStatusManager } = useGameContext();
  const { account, Web3Api, chainId, chainName } = useApiContext();
  const { langValue } = useLangContext();
  const { characters } = useCharacterContext();
  const { setSeeds } = useItemContext();
  const [selectedCharacter, setSelectedCharacter] = useState("");
  const [selectedSeed, setSelectedSeed] = useState();
  const [openSeedCategory, setOpenSeedCategory] = useState("seed");
  const [openSeedConfirm, setOpenSeedConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usedSeedSuccess, setUsedSeedSuccess] = useState(false);
  const [isOpenSeeds, setIsOpenSeeds] = useState(false);
  const [sort, setSort] = useState("");
  const [useSeedPriceBN, setUseSeedPriceBN] = useState(null);

  const charas = useMemo(() => {
    let _characters = characters;
    if (sort) {
      _characters = characters.sort((a, b) => b[sort] - a[sort]);
    }
    return _characters.map((x) => {
      x.restrictionReason = "";
      x.restrictionAt = "";
      x.heritage = x.attributes.find((y) => y.trait_type === "Heritage").value;
      x.personality = x.attributes.find(
        (y) => y.trait_type === "Personality",
      ).value;
      x.sex = x.attributes.find((y) => y.trait_type === "Sex").value;
      x.species = x.attributes.find((y) => y.trait_type === "Species").value;
      x.attrName = x.attributes.find((y) => y.trait_type === "Name").value;
      return x;
    });
  }, [characters, sort]);

  const onChangeSort = (e) => {
    setSort(e.target.value);
  };

  const onClose = () => {
    setSelectedCharacter(null);
  };

  const onSelect = (chara) => {
    sound_effect("click");
    setSelectedCharacter(chara);
    setOpenSeedCategory("seed");
  };

  const openSeeds = () => {
    sound_effect("positive");
    setIsOpenSeeds(true);
  };

  const closeSeeds = (reload = false) => {
    if (reload === true) {
      window.location.reload();
      return;
    }
    sound_effect("negative");
    setIsOpenSeeds(false);
    setSelectedSeed(null);
    setOpenSeedConfirm(false);
    setUsedSeedSuccess(false);
    setSelectedCharacter(null);
  };

  useEffect(() => {
    if (!selectedCharacter) {
      setUseSeedPriceBN(null);
      return;
    }
    (async () => {
      const price = await isekaiBattleStatusManager.methods
        .getUseSeedPrice(selectedCharacter.id)
        .call({ from: account });
      setUseSeedPriceBN(price);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCharacter]);

  const ethPrice = useMemo(() => {
    if (useSeedPriceBN) {
      return Web3.utils.fromWei(useSeedPriceBN, "ether");
    } else {
      return "???";
    }
  }, [useSeedPriceBN]);

  const onUsedSeedConfirm = async () => {
    setLoading(true);
    if (loading) return;
    const tokenId = selectedCharacter && selectedCharacter.id;
    if (tokenId == null) {
      setLoading(false);
      throw new Error("Missing tokenId");
    }
    const seedId = selectedSeed && selectedSeed.id;
    if (seedId == null) {
      setLoading(false);
      throw new Error("Missing seedId");
    }
    if (useSeedPriceBN == null) {
      setLoading(false);
      throw new Error("Missing price");
    }

    try {
      await isekaiBattleStatusManager.methods
        .useSeed(tokenId, seedId)
        .send({
          from: account,
          value: useSeedPriceBN,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
        })
        .on("confirmation", async (confirmationNumber) => {
          if (confirmationNumber >= 2) {
            await Web3Api.account.usedSeed({
              account,
              chainId: parseInt(chainId),
            });
            setOpenSeedConfirm(false);
            setUsedSeedSuccess(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (chainId) {
      fetchNFTsForContract();
    }
  }, [chainId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNFTsForContract = async () => {
    const chain = chainName(chainId);
    const options = {
      chain,
      address: account,
      token_address: settings.addresses.IsekaiBattleSeeds,
    };
    const _seeds = await Web3Api.account.getNFTsForContract(options);

    setSeeds(
      _seeds.result.map((x) => {
        x.category = "seeds";
        x.image = JSON.parse(x.metadata).image;
        x.lv = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.star = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.type = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Type",
        ).value;
        return x;
      }),
    );
  };

  return (
    <>
      <Master>
        {!isOpenSeeds && (
          <div class="character flex h-screen flex-col overflow-hidden">
            <div class="relative flex flex-1 flex-col overflow-auto">
              <div class="flex-1 overflow-hidden">
                <div class="z-5 absolute left-0 top-0 h-full w-full">
                  <select
                    class="absolute right-4 top-5 rounded bg-black bg-opacity-70 p-1 px-2 text-sm text-white"
                    onChange={onChangeSort}
                  >
                    <option value="level">Lv</option>
                    <option value="attack">ATK</option>
                    <option value="defence">DEF</option>
                    <option value="luck">LUK</option>
                  </select>
                  <div class="px-3 py-5 text-xl tracking-wider text-white">
                    {langValue === "ja" && <>キャラクター</>}
                    {langValue === "en" && <>Characters</>}
                  </div>

                  <div
                    class="overflow-auto"
                    style={{
                      height: "calc(100% - 68px)",
                    }}
                  >
                    <ul class="m-2 mb-4 flex flex-col gap-1 text-white">
                      {charas.map((chara, index) => {
                        return (
                          <UnitsSelectItem
                            chara={chara}
                            key={index}
                            onSelect={onSelect}
                            selectedCharacter={selectedCharacter}
                          />
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              {selectedCharacter && (
                <CharacterDetail
                  selectedCharacter={selectedCharacter}
                  onClose={onClose}
                  openSeeds={openSeeds}
                />
              )}
            </div>
          </div>
        )}

        {isOpenSeeds && (
          <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
            {openSeedCategory === "character" ? (
              <ItemSeedSelectCharacter
                ethPrice={ethPrice}
                closeSeeds={closeSeeds}
                setSelectedCharacter={setSelectedCharacter}
                selectedCharacter={selectedCharacter}
                selectedSeed={selectedSeed}
                setOpenSeedCategory={setOpenSeedCategory}
                onConfirm={() => setOpenSeedConfirm(true)}
              />
            ) : (
              <ItemSeedSelectSeed
                ethPrice={ethPrice}
                closeSeeds={closeSeeds}
                setSelectedSeed={setSelectedSeed}
                selectedCharacter={selectedCharacter}
                selectedSeed={selectedSeed}
                setOpenSeedCategory={setOpenSeedCategory}
                onConfirm={() => setOpenSeedConfirm(true)}
              />
            )}
            {openSeedConfirm && selectedCharacter && selectedSeed && (
              <ItemSeedSelectConfirm
                ethPrice={ethPrice}
                onClose={() => setOpenSeedConfirm(false)}
                onConfirm={onUsedSeedConfirm}
                selectedCharacter={selectedCharacter}
                selectedSeed={selectedSeed}
              />
            )}
            {usedSeedSuccess && selectedCharacter && selectedSeed && (
              <ItemSeedSelectFinish
                selectedCharacter={selectedCharacter}
                selectedSeed={selectedSeed}
                onClose={() => closeSeeds(true)}
              />
            )}
          </div>
        )}
        {loading && <Loading />}
      </Master>
    </>
  );
}
