import { useMemo, useState } from "react";
import { useCharacterContext, useLangContext } from "../../../../context";
import UnitsSelectItem from "./units-select-item";
import ItemSeedItemInfo from "./item-seed-select-info";

import btn_close from "../../../../images/btn_close.svg";

export default function ItemSeedSelectCharacter(props) {
  const { characters } = useCharacterContext();
  const { langValue } = useLangContext();
  const [sort, setSort] = useState("");

  const charas = useMemo(() => {
    if (sort) {
      return characters.sort((a, b) => b[sort] - a[sort]);
    }
    return characters;
  }, [characters, sort]);

  const onChangeSort = (e) => {
    setSort(e.target.value);
  };

  return (
    <div className="items-seed-select flex h-screen flex-col">
      <div class="relative flex flex-1 flex-col overflow-auto">
        <div class="flex-1 overflow-hidden">
          <div class="z-5 absolute left-0 top-0 h-full w-full">
            <button class="absolute right-4 top-5" onClick={props.closeSeeds}>
              <img src={btn_close} alt="" />
            </button>
            <select
              class="absolute right-14 top-6 bg-transparent text-xs text-white"
              style={{
                background: "none",
                padding: 0,
                appearance: "none",
              }}
              onChange={onChangeSort}
            >
              <option value="level">Lv</option>
              <option value="attack">ATK</option>
              <option value="defence">DEF</option>
              <option value="luck">LUK</option>
            </select>
            <div class="px-3 py-5 text-xl tracking-wider text-white">
              {langValue === "ja" && <>種の使用</>}
              {langValue === "en" && <>Use seed</>}
            </div>

            <div
              class="overflow-auto"
              style={{
                height: "calc(100% - 68px)",
              }}
            >
              <ul class="m-2 mb-72 flex flex-col gap-1 text-white">
                {charas.map((chara, index) => {
                  return (
                    <UnitsSelectItem
                      chara={chara}
                      key={index}
                      setSelectedCharacter={props.setSelectedCharacter}
                      selectedCharacter={props.selectedCharacter}
                      onClose={props.onClose}
                    />
                  );
                })}
              </ul>
            </div>

            <ItemSeedItemInfo
              selectedCharacter={props.selectedCharacter}
              selectedSeed={props.selectedSeed}
              ethPrice={props.ethPrice}
              closeSeeds={props.closeSeeds}
              onConfirm={props.onConfirm}
              setOpenSeedCategory={props.setOpenSeedCategory}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
