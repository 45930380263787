import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApiContext } from "../../../context";

import btn_close from "../../../images/btn_close.svg";
import icoMypage from "../../../images/ico_mypage.png";

export default function Ranking(props) {
  const { fragmentId } = useParams();
  const { Web3Api, account } = useApiContext();
  const [rankings, setRankings] = useState([]);
  const [myRanking, setMyRanking] = useState();
  useEffect(() => {
    (async () => {
      const result = await Web3Api.game.getRanks();
      const addresses = result.map((o) => o.address);
      const { result: userImages } = await Web3Api.game.getBatchUserImage({
        addresses: [...new Set(addresses)],
      });

      const lists = result.map((o) => {
        const info = userImages.find((u) => u.address == o.address);
        if (info) {
          o.image = info.metadata.image;
        } else {
          o.image = icoMypage;
        }
        return o;
      });

      setRankings(lists);
      if (lists.length) {
        setMyRanking(lists.find((o) => o.address === account));
      }
    })();
  }, []);

  useEffect(() => {
    if (window && document) {
      // Set height of ranking list
      const list = document.querySelector(".battle__ranking");
      const listTop = list.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;
      // .p-6 = 24px
      list.style.height = screenHeight - listTop - 24 - 50 + "px";
    }
  }, []);

  return (
    <>
      <div class="battle relative h-full w-full overflow-hidden p-6 pt-20 text-white">
        <div class="text-2xl">SINKI Ranking</div>
        {fragmentId ? (
          <Link to={`/battle/${fragmentId}`} class="fixed right-4 top-20">
            <img src={btn_close} alt="" />
          </Link>
        ) : (
          <Link to={`/start`} class="fixed right-4 top-20">
            <img src={btn_close} alt="" />
          </Link>
        )}
        <div class="battle__ranking -mx-4 mt-8 overflow-auto">
          <table class="w-full">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>SINKI</th>
                <th>ETH</th>
              </tr>
            </thead>
            <tbody>
              {rankings.map((val, key) => {
                return (
                  <tr key={key}>
                    <td class={`battle__ranking-crown${val.ranking}`}>
                      {val.ranking}
                    </td>
                    <td class="flex items-center gap-2">
                      <img src={val.image} alt="" class="w-10" />
                      <span>{val.address}</span>
                    </td>
                    <td class="text-right font-mono text-sm">{val.point}</td>
                    <td class="text-right font-mono text-sm">{val.sold}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {myRanking && (
          <div class="battle__ranking sticky bottom-0 -mx-4 bg-[#9D6418]">
            <table class="w-full">
              <tbody>
                <tr>
                  <td>{myRanking.ranking}</td>
                  <td class="flex items-center gap-2">
                    <img src={myRanking.image} alt="" class="w-10" />
                    <span>{myRanking.address}</span>
                  </td>
                  <td class="text-right font-mono text-sm">
                    {myRanking.point}
                  </td>
                  <td class="text-right font-mono text-sm">{myRanking.sold}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
