import { useMemo } from "react";
import btn_close from "../../../../images/btn_close.svg";
import ico_arrow_blue from "../../../../images/ico_arrow_blue.svg";

export default function ItemSeedSelectFinish(props) {
  const { selectedCharacter, selectedSeed } = props;

  const effectType = useMemo(() => {
    if (!selectedSeed) return "";
    return selectedSeed.type;
  }, [selectedSeed]);

  const charaEffectValue = useMemo(() => {
    if (!selectedCharacter || !effectType) return 0;
    return selectedCharacter.attributes.find((x) => x.trait_type === effectType)
      .value;
  }, [selectedCharacter, effectType]);

  return (
    <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70">
      <button class="absolute right-4 top-5" onClick={props.onClose}>
        <img src={btn_close} alt="" />
      </button>
      <div class="relative w-full overflow-auto">
        <div class="bg-gradient-to-br from-[#FFE7AA] via-[#A77EFF] to-[#78CFFD] p-4 text-center">
          <div class="text-3xl">{effectType} UP</div>
          <div class="text-5xl font-bold [text-shadow:_0_1px_10px_rgb(255_255_255_/_100%)]">
            +{selectedSeed.lv}
          </div>
          <img src={selectedCharacter.image} alt="" class="mx-auto w-24" />
          <div class="text-3xl">
            {
              selectedCharacter.attributes.find((o) => o.trait_type === "Name")
                .value
            }{" "}
            <span class="text-sm">#{selectedCharacter.id}</span>
          </div>
        </div>
        <div class="flex items-center justify-center gap-6 bg-white py-2 text-xl">
          <div>
            {effectType}
            <span class="ml-2 text-2xl">{charaEffectValue}</span>
          </div>
          <div>
            <img src={ico_arrow_blue} alt="" class="h-3" />
          </div>
          <div>
            {effectType}
            <span class="ml-2 text-2xl">
              {charaEffectValue + selectedSeed.lv}
            </span>
          </div>
        </div>

        <div class="sticky bottom-0 z-10 m-6 flex gap-2">
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
