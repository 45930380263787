import React, { useState } from "react";
import TownData from "../data/town.json";
import { regionIdToInfos, mapLists } from "../data/regionInfo";

const MapContext = React.createContext();

export function MapProvider({ children }) {
  const areaData = mapLists;
  const areaDataMap = regionIdToInfos;

  const [townData /* , setTownData */] = useState(TownData);

  // 選択されたマップ上の要
  const [selectedMapObject, setSelectedMapObject] = useState(null);

  return (
    <MapContext.Provider
      value={{
        areaData,
        areaDataMap,
        townData,
        selectedMapObject,
        setSelectedMapObject,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export const MapConsumer = MapContext.Consumer;
export default MapContext;
