import { useEffect, useState } from "react";
import Master from "../../layouts/master";
import {
  useApiContext,
  useGameContext,
  useItemContext,
} from "../../../context";
import settings from "../../../config/settings";
import Weapon from "./weapon";
import Seed from "./seed";
import Armor from "./armor";

export default function Items(props) {
  const { isekaiBattleBag } = useGameContext();
  const { setWeapons, setArmor, setSeeds } = useItemContext();
  const { account, Web3Api, chainId, chainName } = useApiContext();
  const [selectedCategory, setSelectedCategory] = useState("weapons");
  const [itemBagQty, setItemBagQty] = useState(200);

  useEffect(() => {
    if (chainId) {
      fetchNFTsForContract();
    }
  }, [chainId]);

  useEffect(() => {
    const loadItemBagQty = async () => {
      if (isekaiBattleBag.methods) {
        const qty = await isekaiBattleBag.methods
          .getWpnBagCount(account)
          .call();
        setItemBagQty(qty);
      }
    };
    loadItemBagQty();
  }, [isekaiBattleBag]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchNFTsForContract = async () => {
    const chain = chainName(chainId);
    const options = {
      chain,
      address: account,
      token_address: settings.addresses.IsekaiBattleArmor,
    };
    const _armors = await Web3Api.account.getNFTsForContract(options);
    setArmor(
      _armors.result.map((x) => {
        x.category = "armor";
        x.image = JSON.parse(x.metadata).image;
        x.lv = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.star = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.type = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Type",
        ).value;
        return x;
      }),
    );

    options.token_address = settings.addresses.IsekaiBattleWeapon;
    const _weapons = await Web3Api.account.getNFTsForContract(options);
    setWeapons(
      _weapons.result.map((x) => {
        x.category = "weapons";
        x.image = JSON.parse(x.metadata).image;
        x.lv = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.star = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.type = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Type",
        ).value;
        return x;
      }),
    );

    options.token_address = settings.addresses.IsekaiBattleSeeds;
    const _seeds = await Web3Api.account.getNFTsForContract(options);

    setSeeds(
      _seeds.result.map((x) => {
        x.category = "seeds";
        x.image = JSON.parse(x.metadata).image;
        x.lv = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.star = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Lv",
        ).value;
        x.type = JSON.parse(x.metadata).attributes.find(
          (a) => a.trait_type === "Type",
        ).value;
        return x;
      }),
    );
  };

  return (
    <>
      <Master>
        {selectedCategory === "weapons" && (
          <Weapon
            itemBagQty={itemBagQty}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
        {selectedCategory === "armor" && (
          <Armor
            itemBagQty={itemBagQty}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
        {selectedCategory === "seeds" && (
          <Seed
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </Master>
    </>
  );
}
