import ico_ethereum from "../../../../images/ico_ethereum.svg";
import ico_arrow_blue from "../../../../images/ico_arrow_blue.svg";
import { useMemo } from "react";
import { seedImages } from "../../../../data/itemInfo";

function getSeedImae(type) {
  return seedImages[type];
}

export default function ItemSeedItemInfo(props) {
  const { selectedCharacter, selectedSeed } = props;

  const effectType = useMemo(() => {
    if (!selectedSeed) return "";
    return selectedSeed.type;
  }, [selectedSeed]);

  const charaEffectValue = useMemo(() => {
    if (!selectedCharacter || !effectType) return 0;
    return selectedCharacter.attributes.find((x) => x.trait_type === effectType)
      .value;
  }, [selectedCharacter, effectType]);

  const charaName = useMemo(() => {
    if (!selectedCharacter) return "";
    return selectedCharacter.attributes.find((x) => x.trait_type === "Name")
      .value;
  }, [selectedCharacter]);

  return (
    <div
      class="items__select-character-ocherBg fixed bottom-0 h-[19.5rem] w-full"
      style={{
        bottom: 61,
      }}
    >
      <div class="absolute top-20 w-full">
        <div class="flex justify-center gap-7">
          <div class="text-white">
            {selectedCharacter ? (
              <>
                <div
                  class="h-[100px] w-[100px] rounded-full border-4 border-[#5D5D2A] bg-black"
                  onClick={() => props.setOpenSeedCategory("character")}
                >
                  <img src={selectedCharacter.image} alt="" class="ml-1 w-20" />
                </div>
                <div class="-mt-10 text-center">
                  <div class="rounded bg-black bg-opacity-50 px-1 leading-tight">
                    {charaName}
                  </div>
                  <div class="text-sm leading-tight">
                    #{selectedCharacter.id}
                  </div>
                </div>
                <div class="text-center text-textBlue">
                  <div class="inline text-2xl">
                    {effectType}{" "}
                    <span class="text-4xl">{charaEffectValue}</span>
                  </div>
                </div>
              </>
            ) : (
              <div
                class="h-[100px] w-[100px] rounded-full border-4 border-[#5D5D2A] bg-black"
                onClick={() => props.setOpenSeedCategory("character")}
              ></div>
            )}
          </div>
          <div class="mt-5 text-center text-white [text-shadow:_0_1px_1px_rgb(0_0_0_/_80%)]">
            <img src={ico_ethereum} alt="" class="ml-auto mr-auto" />
            <span class="mr-1 text-2xl">{props.ethPrice}</span>
            <span class="text-base">ETH</span>
          </div>
          <div class="text-white">
            {selectedSeed ? (
              <>
                <div
                  class="h-[100px] w-[100px] rounded-full border-4 border-[#5D5D2A] bg-black"
                  onClick={() => props.setOpenSeedCategory("seed")}
                >
                  <img
                    src={getSeedImae(selectedSeed.type)}
                    alt=""
                    class="ml-1 w-20"
                  />
                </div>
                <div class="-mt-10 text-center">
                  <div class="inline text-3xl">
                    {effectType} +{selectedSeed.lv}
                  </div>
                </div>
                <div class="text-center text-textBlue">
                  <div class="inline text-2xl">
                    {effectType}{" "}
                    <span class="text-4xl">
                      {charaEffectValue + selectedSeed.lv}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div
                class="h-[100px] w-[100px] rounded-full border-4 border-[#5D5D2A] bg-black"
                onClick={() => props.setOpenSeedCategory("seed")}
              ></div>
            )}
          </div>
        </div>
        <div class="items__select-character-whitebg -mt-12 flex h-14 items-center justify-center">
          <img src={ico_arrow_blue} alt="" />
        </div>
        <div class="mx-6 mt-4 flex gap-2">
          <button
            class="block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-black"
            onClick={props.closeSeeds}
          >
            Cancel
          </button>
          <button
            class={`block w-full rounded-full p-3 text-center text-lg tracking-widest text-black ${selectedCharacter && selectedSeed ? "bg-white" : "bg-gray"}`}
            onClick={() => {
              if (selectedCharacter && selectedSeed) {
                props.onConfirm();
              }
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
