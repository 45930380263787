import { useState } from "react";
import { useLangContext, useApiContext } from "../../../context";
import { ReactComponent as LeafIcon } from "../../../images/ico_leaf.svg";

export default function Team(props) {
  const [hasError, setHasError] = useState(null);
  const { langValue } = useLangContext();
  const { logout, user } = useApiContext();

  function cancelClick() {
    (async () => {
      await logout();
    })();
    props.closeClick();
  }

  function okClick() {
    const required = [];
    const checkboxes = document.querySelectorAll(".required-checkbox");
    checkboxes.forEach((el) => {
      if (el.checked) {
        required.push(el);
      }
    });

    if (required.length === checkboxes.length) {
      (async () => {
        await user.set("agree", true);
        await user.save();
        props.enableSetIsBgmModal();
      })();
    } else {
      setHasError(true);
    }
  }

  return (
    <>
      <div
        className="relative w-full rounded-lg p-1"
        style={{ background: "#FFFEF7" }}
      >
        <div
          className="rounded border p-4 py-8"
          style={{ borderColor: "#D1B540" }}
        >
          <h1 className=" text-center text-3xl leading-normal">
            {langValue === "ja" && <>利用規約とプライバシーポリシーの確認</>}
            {langValue === "en" && (
              <>Review the Terms of Use and Privacy Policy</>
            )}
          </h1>

          <p className="mt-6 text-lg">
            {langValue === "ja" && (
              <>内容を確認の上、ご理解いただいたあとにご同意ください。</>
            )}
            {langValue === "en" && (
              <>
                Please agree to the terms of the agreement after checking and
                understanding the contents.
              </>
            )}
          </p>

          <ul className="modal-parchment__term-check">
            <li>
              <label>
                <input type="checkbox" className="required-checkbox" />
                {langValue === "ja" && (
                  <>
                    同意する「
                    <a
                      href="https://isekai-battle.notion.site/089ec2846284415198978d1df39332ee"
                      target="_blank"
                      rel="noreferrer"
                    >
                      利用規約
                    </a>
                    」（必須）
                  </>
                )}
                {langValue === "en" && (
                  <>
                    I agree to the "
                    <a
                      href="https://isekai-battle.notion.site/Terms-of-Use-f8452593660c4934baafa7b0e0a34e3d"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>
                    " (required)
                  </>
                )}
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" className="required-checkbox" />
                {langValue === "ja" && (
                  <>
                    同意する 「
                    <a
                      href="https://isekai-battle.notion.site/0ce5f66337324f60bda9ce2a70a38ee1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      プライバシーポリシー
                    </a>
                    」（必須）
                  </>
                )}
                {langValue === "en" && (
                  <>
                    I agree to the "
                    <a
                      href="https://isekai-battle.notion.site/Privacy-Policy-38835b6664e04e0a9a446d899c612394"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    " (required)
                  </>
                )}
              </label>
            </li>
          </ul>

          {hasError && (
            <p className="modal-parchment__error">
              {langValue === "ja" && <>必須項目には同意が必要です</>}
              {langValue === "en" && <>Required fields must be agreed upon</>}
            </p>
          )}

          {/* 決定ボタン */}
          <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
            <button
              class="mt-2 block w-full rounded-full bg-buttonBg p-3 text-center text-xl text-white"
              onClick={cancelClick}
            >
              Cancel
            </button>
            <button
              class="mt-2 block w-full rounded-full bg-buttonBg p-3 text-center text-xl text-white"
              onClick={okClick}
            >
              OK
            </button>
          </div>

          {/* 装飾の葉っぱ */}
          <LeafIcon
            style={{ height: "27px", width: "27px", fill: "#D1B540" }}
            className="absolute -left-3 -top-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(-1, 1)",
            }}
            className="absolute -right-3 -top-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(-1, -1)",
            }}
            className="absolute -bottom-3 -right-3"
          />
          <LeafIcon
            style={{
              height: "27px",
              width: "27px",
              fill: "#D1B540",
              transform: "scale(1, -1)",
            }}
            className="absolute -bottom-3 -left-3"
          />
        </div>
      </div>
    </>
  );
}
