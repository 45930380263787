import React, { useEffect, useState } from "react";

const GameContext = React.createContext();

export function GameProvider({ children }) {
  const [selectedRegionId, setSelectedRegionId] = useState(0);
  const [matchStatus, setMatchStatus] = useState(""); // WIN-LOSE-DRAW
  const [lastGameTime, setLastGameTime] = useState(0);
  const [nextBattleTimes, setNextBattleTimes] = useState(0);
  const [bgmName, setBgmName] = useState("explore");
  const [accountBalance, setAccountBalance] = useState(0);
  const [regionStakeInfos, setRegionStakeInfos] = useState({});

  const [isekaiBattleClash, setIsekaiBattleClash] = useState({});
  const [isekaiBattleFragment, setIsekaiBattleFragment] = useState({});
  const [isekaiBattleSinki, setIsekaiBattleSinki] = useState({});
  const [isekaiBattleStake, setIsekaiBattleStake] = useState({});
  const [isekaiBattle, setIsekaiBattle] = useState({});
  const [trashIsekaiBattleItems, setTrashIsekaiBattleItems] = useState({});
  const [isekaiBattleBag, setIsekaiBattleBag] = useState({});
  const [isekaiBattleStatusManager, setIsekaiBattleStatusManager] = useState(
    {},
  );
  const [isekaiBattleMagicCircle, setIsekaiBattleMagicCircle] = useState({});

  useEffect(() => {
    loadGameInfo();
  }, [isekaiBattleSinki, isekaiBattleClash]);

  const loadGameInfo = async () => {
    if (Object.keys(isekaiBattleClash).length) {
      const lastGameTime = await isekaiBattleClash.methods
        .getLastGameTime()
        .call();
      const nextBattleHour = await isekaiBattleClash.methods
        .getNextBattleHour()
        .call();
      setNextBattleTimes(Number(lastGameTime) + Number(nextBattleHour));
      setLastGameTime(Number(lastGameTime));
    }
  };

  return (
    <GameContext.Provider
      value={{
        setMatchStatus,
        matchStatus,
        lastGameTime,
        nextBattleTimes,
        selectedRegionId,
        setSelectedRegionId,
        loadGameInfo,
        isekaiBattleClash,
        setIsekaiBattleClash,
        isekaiBattleFragment,
        setIsekaiBattleFragment,
        isekaiBattleSinki,
        setIsekaiBattleSinki,
        isekaiBattleStake,
        setIsekaiBattleStake,
        isekaiBattle,
        setIsekaiBattle,
        trashIsekaiBattleItems,
        setTrashIsekaiBattleItems,
        isekaiBattleBag,
        setIsekaiBattleBag,
        isekaiBattleStatusManager,
        setIsekaiBattleStatusManager,
        isekaiBattleMagicCircle,
        setIsekaiBattleMagicCircle,
        bgmName,
        setBgmName,
        accountBalance,
        setAccountBalance,
        regionStakeInfos,
        setRegionStakeInfos,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export const GameConsumer = GameContext.Consumer;
export default GameContext;
