import { useContext } from "react";
import ApiContext, { ApiProvider } from "./ApiContext";
import LangContext, { LangProvider } from "./LangContext";
import MapContext, { MapProvider } from "./MapContext";
import CharacterContext, { CharacterProvider } from "./CharacterContext";
import FormationContext, { FormationProvider } from "./FormationContext";
import ItemContext, { ItemProvider } from "./ItemContext";
import VolumeContext, { VolumeProvider } from "./VolumeContext";
import GameContext, { GameProvider } from "./GameContext";

export function useApiContext() {
  return useContext(ApiContext);
}

export function useLangContext() {
  return useContext(LangContext);
}

export function useMapContext() {
  return useContext(MapContext);
}

export function useCharacterContext() {
  return useContext(CharacterContext);
}

export function useFormationContext() {
  return useContext(FormationContext);
}

export function useItemContext() {
  return useContext(ItemContext);
}

export function useVolumeContext() {
  return useContext(VolumeContext);
}

export function useGameContext() {
  return useContext(GameContext);
}

export function ContextProvider({ children, apiUrl }) {
  return (
    <ApiProvider url={apiUrl}>
      <LangProvider>
        <MapProvider>
          <CharacterProvider>
            <FormationProvider>
              <ItemProvider>
                <VolumeProvider>
                  <GameProvider>{children}</GameProvider>
                </VolumeProvider>
              </ItemProvider>
            </FormationProvider>
          </CharacterProvider>
        </MapProvider>
      </LangProvider>
    </ApiProvider>
  );
}
