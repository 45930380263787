import { useEffect, useMemo, useState } from "react";
import { useApiContext, useLangContext } from "../../../context";

import { ReactComponent as Stars } from "../../../images/h1_stars.svg";
import { ReactComponent as Sword } from "../../../images/ico_sword.svg";
import { ReactComponent as Shield } from "../../../images/ico_shield.svg";
import { ReactComponent as Treasure } from "../../../images/ico_crystal.svg";
import { ReactComponent as TreasureLarge } from "../../../images/ico_crystal_large.svg";
import { ReactComponent as Seed } from "../../../images/ico_seed.svg";
import { ReactComponent as Dragon } from "../../../images/img_dragon.svg";
import icoTreasureWhite from "../../../images/ico_treasure_white.svg";
import { sound_effect } from "../../common/SoundEffect";
import { mapLists, regionIdToInfos } from "../../../data/regionInfo";
import settings from "../../../config/settings";
import Loading from "../../common/loading";

export default function ItemFoundModal(props) {
  const { activeEvent } = props;
  const { account, chainId, Web3Api, getFeeData } = useApiContext();
  const [loading, setLoading] = useState(false);
  const { langValue } = useLangContext();
  const [isOpen, setIsOpen] = useState(false);
  const [myFragmentData, setMyFragmentData] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState([
    {
      weapon: false,
      armor: false,
      seed: false,
    },
    {
      weapon: false,
      armor: false,
      seed: false,
    },
  ]);

  const fitScale = useMemo(() => {
    const width = window.innerWidth;
    const height = window.innerHeight - 120;
    if (height < 690) {
      return height / 690;
    }
    if (width < 340) {
      return (width - 20) / 340;
    }
    return 1;
  }, []);

  useEffect(() => {
    if (!Array.isArray(props?.regionStakeInfos)) {
      return;
    }
    const claimsStatus = props.regionStakeInfos?.map((info) => {
      const isWeaponAndArmorEnabled = getWeaponTime(info) === "00:00";
      const isSeedEnabled = getSeedTime(info) === "Chance";
      return {
        weapon: isWeaponAndArmorEnabled,
        armor: isWeaponAndArmorEnabled,
        seed: isSeedEnabled,
      };
    });
    setSelectedClaim(claimsStatus);
  }, [props.regionStakeInfos]);

  const disableClaimButton = (regionId) => {
    return (
      !selectedClaim[regionId].weapon &&
      !selectedClaim[regionId].armor &&
      !selectedClaim[regionId].seed
    );
  };

  const treasureInfo = useMemo(() => {
    const infos = mapLists.filter((o) => o.isTreasure);
    if (infos.length) {
      return infos[0];
    }
    return null;
  }, []);

  const fragmentIds = useMemo(() => {
    if (treasureInfo) {
      return treasureInfo.treasure.fragmentIds;
    }
    return [];
  }, [treasureInfo]);

  useEffect(() => {
    if (fragmentIds.length) {
      (async () => {
        const fragments = await Web3Api.game.getMyFragment({
          account,
          address: settings.addresses.IsekaiBattleFragment,
          chainId: chainId,
          fragmentIds,
        });
        const _data = fragmentIds.map((id) => {
          const fragment = fragments.find((o) => o.id == id);
          return {
            id,
            exists: fragment,
          };
        });
        setMyFragmentData(_data);
      })();
    }
  }, [fragmentIds]);

  async function onOkClick(claimedRegionId, info) {
    if (loading) return;
    sound_effect("click");
    setLoading(true);
    const isWeapon =
      info.estimate[0] > 0 && selectedClaim[claimedRegionId].weapon;
    const isArmor =
      info.estimate[1] > 0 && selectedClaim[claimedRegionId].armor;
    const isSeed =
      getSeedTime(info) === "Chance" && selectedClaim[claimedRegionId].seed;

    try {
      const methodData = props.isekaiBattleStake.methods.claimRegion(
        claimedRegionId,
        isWeapon,
        isArmor,
        isSeed,
        false,
        0,
      );
      const { executionGasFee, maxPriorityFeePerGas, maxFeePerGas } =
        await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );

      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          maxFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          const seed = [];
          let armor = [];
          let weapon = [];
          for (const [name, log] of Object.entries(receipt.events)) {
            if (name === "ClaimArmors") {
              armor = log.returnValues.detailsCount;
            } else if (name === "ClaimWeapons") {
              weapon = log.returnValues.detailsCount;
            } else if (name === "ClaimSeed") {
              seed.push(log.returnValues.tokenId);
            }
          }
          props.setClaimed((prev) => ({
            ...prev,
            seed,
            armor,
            weapon,
            status: true,
          }));
        })
        .once("confirmation", async (txn) => {
          sound_effect("claim");
          props.switchBgm("claim");
          setIsOpen(false);
        });
    } catch (error) {
      props.setClaimed((prev) => ({
        ...prev,
        status: false,
      }));
      console.error(error);
    }
    setLoading(false);
  }

  async function chanceFragmentId(claimedRegionId) {
    const supplies = await Web3Api.game.totalSupplies({
      address: settings.addresses.IsekaiBattleFragment,
      tokenIds: regionIdToInfos[claimedRegionId].treasure.fragmentIds,
    });
    const fragments1 = myFragmentData
      .map((o) => {
        const supply = supplies.find((x) => x.token_id === o.id);
        if (supply) {
          o.total = supply.total;
        } else {
          o.total = 0;
        }
        return o;
      })
      .sort((a, b) => a.total - b.total);
    const fragments2 = fragments1.filter((o) => !o.exists);
    if (fragments2.length === 0) {
      return fragments1[0]?.id;
    }
    return fragments2[0]?.id;
  }

  async function onClaimFragment(claimedRegionId) {
    if (loading) return;
    sound_effect("click");
    setLoading(true);

    const fragmentId = await chanceFragmentId(claimedRegionId);

    try {
      const methodData = props.isekaiBattleStake.methods.claimRegion(
        claimedRegionId,
        false,
        false,
        false,
        true,
        fragmentId,
      );
      const { executionGasFee, maxPriorityFeePerGas, maxFeePerGas } =
        await getFeeData();
      const gasLimit = executionGasFee(
        await methodData.estimateGas({
          from: account,
        }),
      );
      await methodData
        .send({
          from: account,
          maxPriorityFeePerGas,
          maxFeePerGas,
          gasLimit,
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          const fragment = [];
          for (const [name, log] of Object.entries(receipt.events)) {
            if (name === "ClaimFragment") {
              fragment.push(log.returnValues.tokenId);
            }
          }
          props.setClaimed((prev) => ({
            ...prev,
            fragment: fragment,
            status: true,
          }));
        })
        .once("confirmation", async () => {
          sound_effect("claim");
          props.switchBgm("claim");
          setLoading(false);
          setIsOpen(false);
          props.setClaimed((prev) => ({
            ...prev,
            status: true,
          }));
        })
        .catch((x) => {
          setLoading(false);
          console.log(x);
          props.setClaimed((prev) => ({
            ...prev,
            status: false,
          }));
        });
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }

  function cancelClick() {
    setLoading(false);
    sound_effect("negative");
    setIsOpen(false);
    props.switchBgm("explore");
  }

  let stakingCharactersCount = 0;
  if (props.regionStakeInfos && props.regionStakeInfos.length) {
    for (const info of props.regionStakeInfos) {
      stakingCharactersCount += info.stakingCharacterCounts;
    }
  }
  if (!stakingCharactersCount) {
    return <></>;
  }

  function onToggleSelectedClaim(claimedRegionId, event) {
    let enabled = false;
    const info = props.regionStakeInfos[claimedRegionId];
    if (event.target.name === "seed") {
      enabled = getSeedTime(info) === "Chance" && event.target.checked;
    } else {
      enabled = getWeaponTime(info) === "00:00" && event.target.checked;
    }

    const selected = {
      ...selectedClaim[claimedRegionId],
      [event.target.name]: enabled,
    };
    selectedClaim[claimedRegionId] = selected;
    setSelectedClaim((prevState) => [...selectedClaim]);
  }

  function getWeaponTime(info) {
    if (
      !info ||
      !info.weaponInterval ||
      Number.parseInt(info.stakingCharacterCounts) < 1
    ) {
      return "--:--";
    }
    const elapsedTime =
      Number.parseInt(info.claimTimes) +
      Number.parseInt(info.weaponInterval) -
      Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);
    if (elapsedTime < 0) {
      return "00:00";
    }

    return `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`;
  }

  function getSeedTime(info, text = false) {
    if (
      !info ||
      !info.seedInterval ||
      Number.parseInt(info.stakingCharacterCounts) < 3
    ) {
      return "--:--";
    }
    const elapsedTime =
      Number.parseInt(info.claimTimes) +
      Number.parseInt(info.seedInterval) -
      Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);

    if (elapsedTime < 0) {
      if (text) {
        return <div className="text-xs">Chance</div>;
      }
      return "Chance";
    }

    return `${("000" + hour).slice(-3)}:${("00" + min).slice(-2)}`;
  }

  function getFragmentTime(info, text = false) {
    if (
      !info.fragmentInterval ||
      Number.parseInt(info.stakingCharacterCounts) == 0
    ) {
      return "--:--";
    }

    const elapsedTime =
      Number.parseInt(info.fragmentTimes) +
      Number.parseInt(info.fragmentInterval) -
      Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);

    if (elapsedTime < 0) {
      if (text) {
        return <div className="text-xs">Chance</div>;
      }
      return "Chance";
    }

    return `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`;
  }

  function chanceFramgent() {
    return (
      myFragmentData.filter((o) => o.exists).length < 3 &&
      getFragmentTime(props.regionStakeInfos[treasureInfo.regionId]) ===
        "Chance"
    );
  }

  function getItemTag(info, regionId) {
    return (
      <>
        <ul class="flex gap-1">
          <li>
            <div class="flex items-center text-sm">
              <Sword
                style={{ height: "15px", width: "15px", fill: "#452000" }}
                class="mr-1"
              />
              <Shield
                style={{ height: "15px", width: "15px", fill: "#452000" }}
                class="mr-1"
              />
              {getWeaponTime(info)}
            </div>
            <ul class="mt-1 flex gap-1">
              <li>
                <label class="checkbox-button">
                  <div class="inline-block rounded-md bg-gradient-to-br from-[#B0A296] to-[#150A00] p-0.5">
                    <div class="relative rounded bg-darkbrown p-1.5">
                      <Sword
                        style={{ height: "35px", width: "35px", fill: "white" }}
                      />
                      {info.estimate[2] && (
                        <div class="absolute bottom-3 left-0 w-full bg-[#420000] bg-opacity-80 text-center text-xs text-white">
                          MAX
                        </div>
                      )}
                      <div class="absolute bottom-0 left-0 w-full rounded-b bg-gradient-to-br from-[#8D8523] to-[#4D4912] text-center text-xs font-bold text-white">
                        {info.estimate[0]}
                      </div>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    name="weapon"
                    checked={selectedClaim[regionId].weapon}
                    onChange={(e) => onToggleSelectedClaim(regionId, e)}
                  />
                </label>
              </li>
              <li>
                <label class="checkbox-button">
                  <div class="inline-block rounded-md bg-gradient-to-br from-[#B0A296] to-[#150A00] p-0.5">
                    <div class="relative rounded bg-darkbrown p-1.5">
                      <Shield
                        style={{ height: "35px", width: "35px", fill: "white" }}
                      />
                      {info.estimate[3] && (
                        <div class="absolute bottom-3 left-0 w-full bg-[#420000] bg-opacity-80 text-center text-xs text-white">
                          MAX
                        </div>
                      )}
                      <div class="absolute bottom-0 left-0 w-full rounded-b bg-gradient-to-br from-[#8D8523] to-[#4D4912] text-center text-xs font-bold text-white">
                        {info.estimate[1]}
                      </div>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    name="armor"
                    checked={selectedClaim[regionId].armor}
                    onClick={(e) => onToggleSelectedClaim(regionId, e)}
                  />
                </label>
              </li>
            </ul>
          </li>
          <li>
            <div class="flex items-center text-sm">
              <Seed
                style={{ height: "15px", width: "15px", fill: "#452000" }}
                class="mr-1"
              />
              {getSeedTime(info, true)}
            </div>
            <ul class="mt-1">
              <li>
                <label class="checkbox-button">
                  <div class="inline-block rounded-md bg-gradient-to-br from-[#B0A296] to-[#150A00] p-0.5">
                    <div class="relative rounded bg-darkbrown p-1.5">
                      <Seed
                        style={{ height: "35px", width: "35px", fill: "white" }}
                        fill=""
                      />
                      <div class="absolute bottom-0 left-0 w-full rounded-b bg-gradient-to-br from-[#8D8523] to-[#4D4912] text-center text-xs font-bold text-white">
                        {!info ||
                        !info.seedInterval ||
                        info.stakingCharacterCounts < 3
                          ? "0"
                          : "0~?"}
                      </div>
                    </div>
                  </div>
                  <input
                    type="checkbox"
                    name="seed"
                    checked={selectedClaim[regionId].seed}
                    onClick={(e) => onToggleSelectedClaim(regionId, e)}
                  />
                </label>
              </li>
            </ul>
          </li>
        </ul>
      </>
    );
  }

  function getFragmentTag(info) {
    return (
      <>
        <div class="flex items-center justify-center text-sm">
          <Treasure
            style={{ height: "15px", width: "21px", fill: "#452000" }}
            class="mr-1"
          />
          {getFragmentTime(info, true)}
        </div>
        <ul class="mt-1">
          <li>
            <label class="checkbox-button">
              <div class="inline-block rounded-md bg-gradient-to-br from-[#B0A296] to-[#150A00] p-0.5">
                <div class="relative rounded bg-darkbrown p-1.5">
                  <TreasureLarge
                    style={{ height: "35px", width: "35px", fill: "white" }}
                  />
                  <div class="absolute bottom-0 left-0 w-full rounded-b bg-gradient-to-br from-[#8D8523] to-[#4D4912] text-center text-xs font-bold text-white">
                    {!info ||
                    !info.seedInterval ||
                    info.stakingCharacterCounts < 3
                      ? "0"
                      : "0~?"}
                  </div>
                </div>
              </div>
              <input type="checkbox" checked={chanceFramgent()} />
            </label>
          </li>
        </ul>
      </>
    );
  }

  return (
    <>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          props.loadStakeInfo();
        }}
        class="absolute left-2 top-2 z-10 w-[44%] w-[44px] rounded-full bg-black bg-opacity-80 p-2 text-white"
      >
        <img src={icoTreasureWhite} alt="" className="w-full" />
      </button>
      {isOpen && (
        <div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
          <div
            className="relative h-full min-h-[690px] w-full min-w-[340px] p-5"
            style={{ background: "#eaeaea", transform: `scale(${fitScale})` }}
          >
            <h1 className="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-xl font-bold">
              <Stars
                className="mx-auto"
                style={{ height: "26px", width: "82px", fill: "#E2DB4B" }}
              />
              New Item Found
            </h1>

            <div class="mt-6">
              <h2 class="text-lg font-bold">
                {langValue === "ja" && <>オルディナ平原</>}
                {langValue === "en" && <>Ordina Planes</>}
              </h2>
              <ul class="mt-2 flex gap-8 text-base">
                <li>
                  {getItemTag(props.regionStakeInfos[1], 1)}
                  <button
                    class={`relative mt-2 block w-full overflow-hidden bg-red-900 bg-gradient-to-r from-[#7F7B43] to-[#9D963D] p-1 text-white ${disableClaimButton(1) ? "grayscale" : ""} `}
                    onClick={() => {
                      if (disableClaimButton(1)) return;
                      onOkClick(1, props.regionStakeInfos[1]);
                    }}
                  >
                    <div class="border border-white border-opacity-70 px-2 py-4 text-base tracking-widest">
                      <Dragon
                        style={{ fill: "#BBAE6C" }}
                        class="absolute -top-20 left-32 w-11/12 opacity-40"
                      />
                      <span class="relative z-10">Claim</span>
                    </div>
                  </button>
                </li>
                {activeEvent && regionIdToInfos[1].isTreasure && (
                  <li class="text-center">
                    {getFragmentTag(props.regionStakeInfos[1])}
                    <button
                      class={`relative mt-2 block w-full overflow-hidden bg-red-900 bg-gradient-to-r from-[#7F7B43] to-[#9D963D] p-1 text-white ${!chanceFramgent() ? "grayscale" : ""}`}
                      onClick={() => {
                        if (!chanceFramgent()) return;
                        onClaimFragment(1);
                      }}
                    >
                      <div class="border border-white border-opacity-70 px-2 py-4 text-base tracking-widest">
                        <Dragon
                          style={{ fill: "#BBAE6C" }}
                          class="absolute -top-5 left-10 w-11/12 opacity-40"
                        />
                        <span class="relative z-10">Claim</span>
                      </div>
                    </button>
                  </li>
                )}
              </ul>
              <div className="mt-2 text-sm text-red-600">
                {langValue === "ja" && (
                  <>フラグメントを3個以上所持している場合、Claimできません</>
                )}
                {langValue === "en" && (
                  <>You cannot Claim when you own 3 or more Fragments</>
                )}
              </div>
            </div>

            <div class="mt-6">
              <h2 class="text-lg font-bold">
                {langValue === "ja" && <>ダグー洞窟</>}
                {langValue === "en" && <>Dagur Cave</>}
              </h2>
              <ul class="mt-2 flex gap-8 text-base">
                <li>
                  {getItemTag(props.regionStakeInfos[0], 0)}
                  <button
                    class={`relative mt-2 block w-full overflow-hidden bg-red-900 bg-gradient-to-r from-[#7F7B43] to-[#9D963D] p-1 text-white ${disableClaimButton(0) ? "grayscale" : ""} `}
                    onClick={() => {
                      if (disableClaimButton(0)) return;
                      onOkClick(0, props.regionStakeInfos[0]);
                    }}
                  >
                    <div class="border border-white border-opacity-70 px-2 py-4 text-base tracking-widest">
                      <Dragon
                        style={{ fill: "#BBAE6C" }}
                        class="absolute -top-20 left-32 w-11/12 opacity-40"
                      />
                      <span class="relative z-10">Claim</span>
                    </div>
                  </button>
                </li>
                {activeEvent && regionIdToInfos[0].isTreasure && (
                  <li class="text-center">
                    {getFragmentTag(props.regionStakeInfos[0])}
                    <button
                      class={`relative mt-2 block w-full overflow-hidden bg-red-900 bg-gradient-to-r from-[#7F7B43] to-[#9D963D] p-1 text-white ${chanceFramgent() ? "" : "grayscale"}`}
                      onClick={() => {
                        if (!chanceFramgent()) return;
                        onClaimFragment(0);
                      }}
                    >
                      <div class="border border-white border-opacity-70 px-2 py-4 text-base tracking-widest">
                        <Dragon
                          style={{ fill: "#BBAE6C" }}
                          class="absolute -top-5 left-10 w-11/12 opacity-40"
                        />
                        <span class="relative z-10">Claim</span>
                      </div>
                    </button>
                  </li>
                )}
              </ul>
            </div>

            {/* 決定ボタン */}
            <div className="bottom-0 z-10">
              <button
                className="mt-2 block w-full rounded-full p-3 text-center text-white"
                style={{ background: "#3A3B49" }}
                onClick={cancelClick}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </>
  );
}
