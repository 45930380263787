import powerIco from "../../../../images/seeds/power.png";
import defenceIco from "../../../../images/seeds/defence.png";
import luckIco from "../../../../images/seeds/luck.png";
import { useFormationContext, useLangContext } from "../../../../context";
import { useMemo, useState } from "react";
import {
  MAX_ATK,
  MAX_DEF,
  MAX_LUK,
  mapIdToExploreNumbers,
} from "../../../../data/regionInfo";

export default function UnitsSelectItem(props) {
  const { chara } = props;
  const { langValue } = useLangContext();
  const { formation } = useFormationContext();
  const [region, setRegion] = useState();

  const unitType = useMemo(() => {
    for (const [key, value] of Object.entries(formation)) {
      if (value.includes(chara.id)) {
        if (key === "attack" || key === "defence") {
          return key;
        } else {
          setRegion(key);
          return "explore";
        }
      }
    }
    return null;
  }, [formation, chara.id]);

  const powerProgress = (type, value) => {
    let power = 0;
    switch (type) {
      case "ATK":
        power = MAX_ATK;
        break;
      case "DEF":
        power = MAX_DEF;
        break;
      case "LUK":
        power = MAX_LUK;
        break;
      default:
        break;
    }
    return Math.floor((100 * value) / power);
  };

  return (
    <li
      class={`units__${unitType} flex items-center gap-2 bg-black ${props.selectedCharacter && props.selectedCharacter.id == chara.id ? "bg-indigo-950 bg-opacity-90" : ""} bg-opacity-70 py-2`}
      onClick={() => {
        props.setSelectedCharacter(chara);
      }}
    >
      <div class="basis-18 flex-shrink-0 flex-grow-0">
        <img src={chara.image} alt={chara.name} class="ml-1 w-[70px]" />
      </div>
      <div class="-ml-2 flex-auto">
        <div class="flex items-center">
          <span class="units__name ml-2 text-xl">{chara.name}</span>
          <span class="ml-1 whitespace-nowrap rounded bg-white px-2 py-1 font-sans text-[0.5rem] font-bold text-black">
            {chara.gen}
          </span>
          <span
            class={`ml-1 whitespace-nowrap rounded px-2 py-1 text-[0.5rem] font-bold text-white ${unitType === "explore" && "bg-exploreGreen"} ${unitType === "attack" && "bg-attackRed"} ${unitType === "defence" && "bg-defenceBlue"}`}
          >
            {unitType && unitType === "explore"
              ? mapIdToExploreNumbers[langValue][region]
              : mapIdToExploreNumbers[langValue][unitType]}
          </span>
        </div>
        <div class="mt-1 grid grid-cols-5 gap-2">
          <p class="text-center">
            <span class="block text-sm text-gray-300">Lv</span>
            <span class="text-xl">{chara.level}</span>
          </p>
          <p class="text-center">
            <span class="block text-sm text-gray-300">ATK</span>
            <span class="text-xl">{chara.attack}</span>
          </p>
          <p class="text-center">
            <span class="block text-sm text-gray-300">DEF</span>
            <span class="text-xl">{chara.defence}</span>
          </p>
          <p class="text-center">
            <span class="block text-sm text-gray-300">LUK</span>
            <span class="text-xl">{chara.luck}</span>
          </p>
          <p class="text-center">
            <span class="block text-sm text-gray-300">Seeds</span>
            <span class="text-xl">
              {chara.usedSeeds}
              <span class="text-sm">/10</span>
            </span>
          </p>
        </div>
      </div>
      <div class="flex-shrink-0 flex-grow-0 basis-16">
        <div class="flex">
          <img src={chara.weapon} alt="" class="max-w-10" />
          <img src={chara.armor} alt="" class="-ml-3 max-w-10" />
        </div>
        <div>
          <div class="flex items-center">
            <img src={powerIco} alt="" class="-my-1.5 w-7" />
            <div class="h-0.5 w-8 bg-gray-500">
              <div
                class={`h-0.5 w-[${powerProgress("ATK", chara.attack)}%] bg-gradient-to-r from-[#BA2709] to-[#873A2A]`}
              ></div>
            </div>
          </div>
          <div class="flex items-center">
            <img src={defenceIco} alt="" class="-my-1.5 w-7" />
            <div class="h-0.5 w-8 bg-gray-500">
              <div
                class={`h-0.5 w-[${powerProgress("DEF", chara.defence)}%] bg-gradient-to-r from-[#B37811] to-[#81632F]`}
              ></div>
            </div>
          </div>
          <div class="flex items-center">
            <img src={luckIco} alt="" class="-my-1.5 w-7" />
            <div class="h-0.5 w-8 bg-gray-500">
              <div
                class={`h-0.5 w-[${powerProgress("LUK", chara.luck)}%] bg-gradient-to-r from-[#DAB211] to-[#AB9027]`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
