import { useMemo, useState } from "react";
import { useItemContext, useLangContext } from "../../../../context";
import btnClose from "../../../../images/btn_close.svg";
import imgDragonDarkAlpha from "../../../../images/img_dragon_dark_alpha.svg";
import TrashDrag from "./trash-drag";
import { sound_effect } from "../../../common/SoundEffect";

export default function ModalTrash(props) {
  const { langValue } = useLangContext();
  const {
    item,
    selectedItemTrash,
    setSelectedItemTrash,
    selectedCategory,
    itemImage,
  } = props;
  const { getGoodCompatibilityCharacters } = useItemContext();
  const goodCompatibilities = useMemo(() => {
    return getGoodCompatibilityCharacters(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  function getEffect(lv) {
    switch (lv) {
      case 1:
        return "100～200";
      case 2:
        return "125～225";
      case 3:
        return "150～250";
      default:
        return "";
    }
  }

  const [countItem, setCountItem] = useState(1);

  const onConfirm = () => {
    setSelectedItemTrash((prev) => ({
      ...prev,
      [item.id]: {
        qty: countItem,
        ...item,
      },
    }));
    props.onClose();
    sound_effect("positive");
  };

  const onClose = () => {
    const _items = selectedItemTrash;
    delete _items[item.id];
    setSelectedItemTrash(_items);
    props.onClose();
    sound_effect("negative");
  };

  return (
    <div class="absolute left-0 top-0 z-0 h-full w-full bg-black bg-opacity-80 p-6 text-white">
      <div class="w-full bg-gradient-to-b from-[#AFAC3A] to-[#D3D184] text-white">
        <div class="w-full bg-gradient-to-tr from-[#B0AD26] to-[#585710] p-0.5 text-white">
          <div class="flex justify-between border border-neutral-300 px-2 py-1 text-sm">
            <span>
              {item.type} Lv{item.lv}
            </span>
            <button onClick={props.onClose}>
              <img src={btnClose} alt="" class="w-3" />
            </button>
          </div>
        </div>
        <div
          class="p-3"
          style={{
            background: `url(${imgDragonDarkAlpha}) 120% 0 no-repeat`,
            backgroundSize: "50%",
          }}
        >
          <div class="flex">
            <div class="flex-1">
              <div>
                {selectedCategory === "weapons" && (
                  <>
                    {langValue === "ja" && <span>攻撃力</span>}
                    {langValue === "en" && <span>Attack power</span>}
                  </>
                )}
                {selectedCategory === "armor" && (
                  <>
                    {langValue === "ja" && <span>防御力</span>}
                    {langValue === "en" && <span>Defence power</span>}
                  </>
                )}
              </div>
              <div class="text-3xl text-yellow-200">{getEffect(item.lv)}</div>
              <div class="mt-6 text-sm">
                {langValue === "ja" && <>好相性キャラクター</>}
                {langValue === "en" && (
                  <>Characters that have matching favorites</>
                )}
              </div>
              <div class="flex">
                {goodCompatibilities.map((characterId, index) => {
                  return (
                    <img
                      src={`/images/characters/${characterId}.png`}
                      alt=""
                      class="w-8"
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
            <div class="flex-1">
              <img
                src={itemImage}
                alt=""
                class="-mt-2 ml-auto mr-0 max-w-[80%]"
              />
            </div>
          </div>
          <div class="mt-4 border-t border-white pt-4">
            <div class="text-center text-sm">
              {langValue === "ja" && <span>捨てる数</span>}
              {langValue === "en" && <span>Number to discard</span>}
            </div>
            <div class="text-center text-3xl">
              {countItem}/{item.amount}
            </div>
          </div>
          <div>
            <TrashDrag
              totalCountItem={item.amount}
              countItem={countItem}
              setDragCount={(val) => setCountItem(val)}
            />
          </div>
          <button
            class="mx-auto mt-6 block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-gray-700 drop-shadow-sm"
            onClick={onConfirm}
          >
            {langValue === "ja" && <span>ゴミ箱に入れる</span>}
            {langValue === "en" && <span>Put in trash</span>}
          </button>
          <button
            class="mx-auto mt-2 block w-full rounded-full bg-white p-3 text-center text-lg tracking-widest text-gray-700 drop-shadow-sm"
            onClick={onClose}
          >
            {langValue === "ja" && <span>閉じる</span>}
            {langValue === "en" && <span>Close</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
