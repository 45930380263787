import { useState } from "react";
import { useLangContext, useMapContext } from "../../../context";
import btn_close from "../../../images/btn_close.svg";

export default function RegionList(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { langValue } = useLangContext();
  const { setSelectedMapObject, areaData } = useMapContext();
  const onClickRegion = (region) => {
    if (region.status === "private") {
      return;
    }
    setIsOpen(false);
    setSelectedMapObject(region.id);
    props.setSelectedRegion(true);
  };

  return (
    <>
      <button
        className="absolute right-2 top-2 z-10 rounded-md bg-black bg-opacity-80 px-3 py-1 text-sm text-white"
        onClick={() => setIsOpen(true)}
      >
        {langValue == "en" && "Region list"}
        {langValue == "ja" && "リージョン一覧"}
      </button>
      {isOpen && (
        <>
          <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-80 text-white">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute right-4 top-5"
            >
              <img src={btn_close} alt="" />
            </button>

            <div className="p-4">
              <h1 className="mt-1">
                {langValue == "en" && "Region list"}
                {langValue == "ja" && "リージョン一覧"}
              </h1>
              <ul class="mt-4">
                {areaData
                  .filter((area) => area.status === "public")
                  .reverse()
                  .map((region, index) => {
                    if (region.status === "private") return;
                    return (
                      <li class="mt-4" key={index}>
                        <a
                          href="#"
                          onClick={() => onClickRegion(region)}
                          className="relative block text-xl"
                        >
                          <img
                            src={region.image}
                            alt=""
                            class="w-full rounded-sm brightness-75"
                          />
                          <span class="absolute top-1/2 mt-0.5 w-full -translate-y-1/2 transform text-center text-lg font-bold text-white [text-shadow:_0_1px_10px_rgb(0_0_0_/_80%)]">
                            {langValue == "en" && region.name_en}
                            {langValue == "ja" && region.name_ja}
                          </span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  );
}
