import { useEffect } from "react";
import { useVolumeContext } from "../../context";
import * as utils from "../../util";
import bgm8 from "../../bgm/8.mp3";
import bgm9 from "../../bgm/9.mp3";
import bgm10 from "../../bgm/10.mp3";
import bgm11 from "../../bgm/11.mp3";
import bgm12 from "../../bgm/12.mp3";
import bgm13 from "../../bgm/13.mp3";

export default function Bgm(props) {
  const { bgmVolume, isMute } = useVolumeContext();

  const bgm_obj = {
    bgm1: bgm8,
    explore: bgm9,
    claim: bgm10,
    formation: bgm11,
    units: bgm11,
    items: bgm12,
    characters: bgm13,
    treasures: bgm13,
    battle: bgm13,
  };

  let bgmFile = bgm_obj[Object.keys(bgm_obj).find((el) => el === props.bgm)];
  if (!bgmFile) {
    bgmFile = "";
  }

  useEffect(() => {
    const vol = bgmVolume / 100;
    const vid = document.getElementById("bgm");
    vid.volume = vol;
    vid.loop = true;

    if (vol === 0) {
      vid.src = "";
    } else if (!/.mp3/.test(vid.src)) {
      vid.src = bgmFile;
    }

    // Wait until the audio is ready to be played
    const handleCanPlay = () => {
      vid.play().catch((error) => {
        console.log("Autoplay was prevented:", error);
      });
    };
    vid.addEventListener("canplay", handleCanPlay);
    return () => {
      vid.removeEventListener("canplay", handleCanPlay);
    };
  }, [bgmVolume, isMute]);

  return (
    <>
      <div className="bgm">
        {isMute && <audio src={bgmFile} id="bgm"></audio>}
        {!isMute && <audio src={bgmFile} autoPlay id="bgm"></audio>}
      </div>
    </>
  );
}
