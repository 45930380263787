import { useCharacterContext, useLangContext } from "../../../../context";
import btnClose from "../../../../images/btn_close.svg";
import UnitsSelectItem from "./units-select-item";
import { useEffect, useMemo, useState } from "react";
import MdBox from "./md-box";
import useScrollPosition from "../../../common/scrollPosition";

export default function UnitsSelect(props) {
  const { characters } = useCharacterContext();
  const { langValue } = useLangContext();
  const [sort, setSort] = useState("");
  const [isClose, setClose] = useState(false);
  const { scrollRef, scrollPosition } = useScrollPosition();
  const { currentScroll, setCurrentScroll } = props;

  useEffect(() => {
    if (scrollRef.current && currentScroll) {
      scrollRef.current.scrollTop = currentScroll;
    }
  }, []);

  useEffect(() => {
    if (scrollPosition) {
      setCurrentScroll(scrollPosition);
    }
  }, [scrollPosition]);

  const charas = useMemo(() => {
    if (sort) {
      return characters.sort((a, b) => b[sort] - a[sort]);
    }
    return characters;
  }, [characters, sort]);

  const onChangeSort = (e) => {
    setSort(e.target.value);
  };

  return (
    <>
      <div class="relative flex flex-1 flex-col overflow-auto">
        <div class="flex-1 overflow-hidden">
          <div class="z-5 absolute left-0 top-0 h-full w-full bg-black bg-opacity-90">
            <button
              class="absolute right-4 top-5"
              onClick={() => setClose(true)}
            >
              <img src={btnClose} alt="" />
            </button>
            <select
              class="absolute right-14 top-6 bg-transparent text-xs text-white"
              style={{
                background: "none",
                padding: 0,
                appearance: "none",
              }}
              onChange={onChangeSort}
            >
              <option value="level">Lv</option>
              <option value="attack">ATK</option>
              <option value="defence">DEF</option>
              <option value="luck">LUK</option>
            </select>
            <div class="px-3 py-5 text-xl tracking-wider text-white">
              {langValue === "ja" && <>キャラクター選択</>}
              {langValue === "en" && <>Character selection</>}
            </div>

            <div
              class="overflow-auto"
              style={{
                height: "calc(100% - 68px)",
              }}
              ref={scrollRef}
            >
              <ul class="m-2 mb-8 flex flex-col gap-5 text-white">
                {charas.map((chara, index) => {
                  return (
                    <UnitsSelectItem
                      chara={chara}
                      key={index}
                      formationTarget={props.formationTarget}
                      onClose={props.onClose}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {isClose && (
        <MdBox
          title={
            langValue === "en"
              ? "Would you like to cancel?"
              : "キャンセルしますか？"
          }
          onYes={() => {
            setClose(false);
            props.onClose();
          }}
          onNo={() => setClose(false)}
        />
      )}
    </>
  );
}
