import { useEffect, useMemo, useState } from "react";
import {
  useApiContext,
  useGameContext,
  useLangContext,
} from "../../../context";
import { fragmentImageIds, mapLists } from "../../../data/regionInfo";
import settings from "../../../config/settings";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ico_battle_result from "../../../images/ico_battle_result.svg";

export default function Fragment(props) {
  const { fragmentStolen } = props;
  const { account, Web3Api, chainId } = useApiContext();
  const { nextBattleTimes } = useGameContext();
  const { langValue } = useLangContext();
  const [myFragmentData, setMyFragmentData] = useState([]);
  const navigate = useNavigate();

  const regionInfo = useMemo(() => {
    const infos = mapLists.filter((o) => o.isTreasure);
    if (infos.length) {
      return infos[0];
    }
    return null;
  }, []);

  const fragmentIds = useMemo(() => {
    if (regionInfo) {
      return regionInfo.treasure.fragmentIds;
    }
    return [];
  }, [regionInfo]);

  const nextGameTime = useMemo(() => {
    const elapsedTime =
      Number.parseInt(nextBattleTimes) - Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);
    if (elapsedTime < 0) {
      return "00:00";
    }
    return `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`;
  }, [nextBattleTimes]);

  const gameAvailable = useMemo(() => {
    const endTime = moment.unix(nextBattleTimes);
    if (endTime.isAfter(new Date())) {
      return false;
    }
    return true;
  }, [nextBattleTimes]);

  useEffect(() => {
    if (fragmentIds.length) {
      (async () => {
        const fragments = await Web3Api.game.getMyFragment({
          account,
          address: settings.addresses.IsekaiBattleFragment,
          chainId: chainId,
          fragmentIds,
        });
        const _data = fragmentIds.map((id) => {
          const fragment = fragments.find((o) => o.id == id);
          return {
            id,
            exists: fragment,
          };
        });
        setMyFragmentData(_data);
      })();
    }
  }, [fragmentIds]);

  const onClickListEnemy = (fragment) => {
    if (fragment.exists) return;
    if (fragmentStolen.includes(fragment.id)) {
      props.removeFragmentStolen(fragment.id);
      navigate(`/battle/history/${fragment.id}`);
      return;
    }
    navigate(`/battle/${fragment.id}`);
  };

  return (
    <>
      <div class="map-claim-list__shadow"></div>
      <div class="absolute bottom-0 left-0 right-0 mx-auto border-opacity-50 p-4 text-white">
        <h2 class="mt-4 flex justify-between text-base">
          {gameAvailable === false && (
            <>
              <div>
                {langValue === "ja" && <>{regionInfo.name_ja}</>}
                {langValue === "en" && <>{regionInfo.name_en}</>}
              </div>
              <span>
                {langValue === "ja" && <>次のバトルまで</>}
                {langValue === "en" && <>Battle Cooldown </>}
                {nextGameTime}
              </span>
            </>
          )}
        </h2>
        <ul class="mt-2 flex justify-center gap-1">
          {myFragmentData.map((fragment, key) => {
            return (
              <li
                className={`stone ${fragmentStolen.includes(fragment.id) && !fragment.exists && "stone--battle-result"}`}
                onClick={() => onClickListEnemy(fragment)}
                key={key}
              >
                <span>
                  {fragment.exists ? (
                    <img src={fragmentImageIds[fragment.id]} alt="" />
                  ) : (
                    <>
                      <img
                        src={fragmentImageIds[fragment.id]}
                        alt=""
                        class={`brightness-75 grayscale ${fragmentStolen.includes(fragment.id) && "absolute"}`}
                      />
                      {fragmentStolen.includes(fragment.id) && (
                        <img
                          src={ico_battle_result}
                          alt=""
                          class="z-1 relative"
                        />
                      )}
                    </>
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
