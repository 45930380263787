import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const LangContext = React.createContext();

export function LangProvider({ children }) {
  const [lang, setLang] = useState("ja");
  const [cookies, setCookie] = useCookies(["lang"]);

  const setMyLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
    setLang(lang);
    document.body.classList = "";
    document.body.classList.add(lang);
  };

  useEffect(() => {
    let lang = cookies.lang;
    if (!lang) {
      lang = navigator.language?.split("-")[0];
    }
    if (!["ja", "en"].includes(lang)) {
      lang = "ja";
    }
    setLang(lang);
    setMyLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LangContext.Provider value={{ langValue: lang, setMyLanguage }}>
      {children}
    </LangContext.Provider>
  );
}

export const LangConsumer = LangContext.Consumer;
export default LangContext;
