import { useEffect, useMemo, useRef, useState } from "react";
import { useApiContext, useLangContext } from "../../../context";
import Master from "../../layouts/master";
import { formatDateTime, shortAddress } from "../../../util";
import settings from "../../../config/settings";
import icoMypage from "../../../images/ico_mypage.png";
import ico_sword_white from "../../../images/ico_sword_white.svg";
// import ico_sword_black from "../../../images/ico_sword_black.svg";
import ico_shield_white from "../../../images/ico_shield_white.svg";
// import ico_shield_black from "../../../images/ico_shield_black.svg";
import ico_arrow_right from "../../../images/ico_arrow_right.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fragmentImageIds,
  fragmentInfos,
  mapLists,
} from "../../../data/regionInfo";

const actionsIcons = {
  "DEF:LOSE": ico_shield_white,
  "DEF:WIN": ico_shield_white,
  "ATK:LOSE": ico_sword_white,
  "ATK:WIN": ico_sword_white,
};

const actionsClass = {
  "DEF:LOSE": "bg-blue-900 text-xs border-b border-black border-opacity-50",
  "DEF:WIN": "bg-blue-900 text-xs border-b border-black border-opacity-50",
  "ATK:LOSE": "bg-red-900 text-xs border-b border-black border-opacity-50",
  "ATK:WIN":
    "bg-red-900 text-xs border-b border-black border-opacity-50 border-b border-black border-opacity-50",
};

const actionsTextJa = {
  "DEF:LOSE": "【敗北】{STONE_NAME}を奪われた...",
  "DEF:WIN": "【勝利】{STONE_NAME}を守った！",
  "ATK:LOSE": "【敗北】{STONE_NAME}を奪えなかった...",
  "ATK:WIN": "【勝利】{STONE_NAME}を奪った！",
};

const actionsTextEn = {
  "DEF:LOSE": "[Defeat] Your {STONE_NAME} was taken…",
  "DEF:WIN": "[Victory] You protected your {STONE_NAME}!",
  "ATK:LOSE": "[Defeat] You failed to take the {STONE_NAME}...",
  "ATK:WIN": "[Victory] You took the {STONE_NAME}!",
};

export default function BattleHistory(props) {
  const { langValue } = useLangContext();
  const { Web3Api, account, targetChainId } = useApiContext();
  const [records, setRecords] = useState([]);
  const [myFragmentData, setMyFragmentData] = useState([]);
  const scrollRef = useRef();
  const { fragmentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    loadGameRecord();
  }, []);

  let currentPage = 1;
  let nextPage = true;
  let loading = false;
  const loadGameRecord = async () => {
    if (loading || !nextPage) return;
    loading = true;
    const params = {
      limit: 20,
      page: currentPage,
      account: account,
    };
    let { result } = await Web3Api.game.getGameRecord(params);
    if (result.length > 0) {
      let fragmentIds = result.map((o) => o.fragment_id);
      fragmentIds = fragmentIds.filter(
        (value, index) => fragmentIds.findIndex((v) => v === value) === index,
      );
      let enemies = result.map((o) => o.enemy);
      enemies = enemies.filter(
        (value, index) => enemies.findIndex((v) => v === value) === index,
      );
      const { result: userImages } = await Web3Api.game.getBatchUserImage({
        addresses: enemies,
      });

      result = result.map((o) => {
        const armyInfo = userImages.find((u) => u.address == o.enemy);
        let army = {};
        if (armyInfo) {
          const armyName = armyInfo.metadata.attributes.find(
            (o) => o.trait_type == "Name",
          );
          army = {
            image: armyInfo.metadata.image,
            name: armyName ? armyName.value : shortAddress(o.enemy),
          };
        } else {
          army = {
            image: icoMypage,
            name: shortAddress(o.enemy),
          };
        }
        o.army = army;
        return o;
      });
    }
    if (currentPage === 1) {
      setRecords((prev) => result);
    } else {
      setRecords((prev) => prev.concat(result));
    }
    currentPage = currentPage + 1;
    nextPage = result.length > 0;
    loading = false;
  };

  const loadMoreData = () => {
    if (!nextPage) return;
    loadGameRecord();
  };

  let scrollBefore = 0;
  useEffect(() => {
    const onScroll = () => {
      try {
        const scrolled = Math.ceil(
          scrollRef.current.clientHeight + scrollRef.current.scrollTop,
        );
        if (
          scrolled > scrollBefore &&
          scrolled >= Math.floor(scrollRef.current.scrollHeight)
        ) {
          scrollBefore = scrolled;
          loadMoreData();
        }
      } catch (error) {
        console.error("error", error);
      }
    };
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  }, []);

  const replaceText = (str, params = {}) => {
    for (const [key, text] of Object.entries(params)) {
      str = str.replace(`{${key}}`, text);
    }
    return str;
  };

  const fragmentIds = useMemo(() => {
    const infos = mapLists.filter((o) => o.isTreasure);
    if (infos.length) {
      return infos[0].treasure.fragmentIds;
    }
    return [];
  }, []);

  useEffect(() => {
    if (fragmentIds.length) {
      (async () => {
        const fragments = await Web3Api.game.getMyFragment({
          account,
          address: settings.addresses.IsekaiBattleFragment,
          chainId: targetChainId,
          fragmentIds,
        });
        const _data = fragmentIds.map((id) => {
          const fragment = fragments.find((o) => o.id == id);
          return {
            id,
            exists: fragment,
          };
        });
        setMyFragmentData(_data);
      })();
    }
  }, [fragmentIds]);

  const onClickListEnemy = (fragment) => {
    if (fragment.exists) return;
    navigate(`/battle/${fragment.id}`);
  };

  return (
    <Master>
      <div class="relative flex flex-1 flex-col overflow-scroll bg-neutral-100">
        <div class="battle flex h-screen flex-col overflow-hidden">
          <div class="flex flex-1 flex-col overflow-hidden">
            <div class="bg-black bg-opacity-20 p-3 text-xl tracking-wider text-white">
              {langValue === "ja" && <>戦闘履歴</>}
              {langValue === "en" && <>Battle Record</>}
            </div>

            <ul class="mx-2 my-2 flex justify-center gap-1">
              {myFragmentData.map((fragment, key) => {
                return (
                  <li
                    class={`stone`}
                    key={key}
                    onClick={() => onClickListEnemy(fragment)}
                  >
                    <span>
                      <img
                        src={fragmentImageIds[fragment.id]}
                        class={`${!fragment.exists && "brightness-75 grayscale"}`}
                        alt=""
                      />
                    </span>
                  </li>
                );
              })}
            </ul>

            <div className="overflow-auto" ref={scrollRef}>
              <div class="mx-2 mb-8">
                {fragmentId && (
                  <ul class="mx-2 mt-4 flex gap-4 text-xs text-white">
                    <li>
                      <Link to={`/battle/${fragmentId}`} class="flex gap-2">
                        <img
                          src={ico_arrow_right}
                          alt=""
                          class="scale-x-[-1] transform"
                        />
                        {langValue === "ja" && <>バトル</>}
                        {langValue === "en" && <>Battle</>}
                      </Link>
                    </li>
                  </ul>
                )}

                <div class="mx-2 mt-4">
                  <table class="w-full text-white">
                    <thead class="border-b border-white border-opacity-70 text-sm">
                      <tr>
                        <th class="whitespace-nowrap py-1">
                          {langValue === "ja" && <>勝敗</>}
                          {langValue === "en" && <>Result</>}
                        </th>
                        <th class="whitespace-nowrap py-1">
                          {langValue === "ja" && <>フラグメント</>}
                          {langValue === "en" && <>Fragment</>}
                        </th>
                        <th class="whitespace-nowrap py-1">
                          {langValue === "ja" && <>アクション</>}
                          {langValue === "en" && <>Action</>}
                        </th>
                        <th class="whitespace-nowrap py-1">
                          {langValue === "ja" && <>詳細</>}
                          {langValue === "en" && <>Details</>}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((value, key) => {
                        return (
                          <tr
                            class={
                              actionsClass[`${value.type}:${value.status}`]
                            }
                            key={key}
                          >
                            <td class={`px-1 py-2 pl-2 text-center text-[9px]`}>
                              <img
                                src={
                                  actionsIcons[`${value.type}:${value.status}`]
                                }
                                alt=""
                                class="mx-auto w-4"
                              />
                              {value.status}
                            </td>
                            <td class="py-2">
                              <img
                                src={fragmentInfos[value.fragment_id]?.image}
                                alt=""
                                class="mx-auto w-10"
                              />
                            </td>
                            <td class="px-1 py-2">
                              <div class="flex items-center gap-1">
                                <img
                                  src={value.army.image}
                                  alt=""
                                  class="h-10 w-10"
                                />
                                <div>
                                  {langValue === "ja" && (
                                    <>
                                      {replaceText(
                                        actionsTextJa[
                                          `${value.type}:${value.status}`
                                        ],
                                        {
                                          STONE_NAME:
                                            fragmentInfos[value.fragment_id]
                                              ?.name_ja,
                                        },
                                      )}
                                    </>
                                  )}
                                  {langValue === "en" && (
                                    <>
                                      {replaceText(
                                        actionsTextEn[
                                          `${value.type}:${value.status}`
                                        ],
                                        {
                                          STONE_NAME:
                                            fragmentInfos[value.fragment_id]
                                              ?.name_en,
                                        },
                                      )}
                                    </>
                                  )}
                                  <div
                                    className={`font-mono text-[9px] ${langValue == "ja" ? "pl-1" : ""}`}
                                  >
                                    {formatDateTime(value.created_at)}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-1 py-2 pr-2">
                              <a
                                href={`${settings.blockExplorerUrl}tx/${value.txn}`}
                                target="_blank"
                                class="rounded-full bg-gray-100 p-2 px-4 text-xs text-black"
                              >
                                TX
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
