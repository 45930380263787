import { useState, useEffect, useRef } from "react";
import "react-dom";
import Draggable from "react-draggable";

import icoMinusWhite from "../../../../images/ico_minus_white.svg";
import icoPlusWhite from "../../../../images/ico_plus_white.svg";

export default function TrashDrag(props) {
  const totalCountItem = props.totalCountItem;
  const countItem = props.countItem;
  // for dragging
  const selectedCountBase = 1;
  const [selectedCount, setSelectedCount] = useState(selectedCountBase);
  const dragNodeRef = useRef(null);
  const dragBarRef = useRef(null);
  const dragAreaRef = useRef(null);
  const [position] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState(0);
  const [progressBarActiveStyle, setProgressBarActiveStyle] = useState("0%");
  const [progressBarDragbarStyle, setProgressBarDragbarStyle] = useState("0%");

  useEffect(() => {
    const ratio = countItem / totalCountItem;
    setProgressBarStyles(ratio);
  }, [countItem]);

  function setProgressBarStyles(ratio) {
    let width = ratio * 100;
    width = width > 100 ? 100 : width;
    setProgressBarActiveStyle(`${width}%`);
    setProgressBarDragbarStyle(`${width}%`);
  }

  function updateselectedCountWithRecalc(_selectedCount) {
    if (_selectedCount > totalCountItem) {
      _selectedCount = totalCountItem;
    }
    if (_selectedCount < 1) _selectedCount = 0;
    setSelectedCount(_selectedCount);
    let ratio;
    if (totalCountItem === selectedCountBase) {
      ratio = 1;
    } else {
      ratio =
        (_selectedCount - selectedCountBase) /
        (totalCountItem - selectedCountBase);
    }
    setProgressBarStyles(ratio);
    props.setDragCount(_selectedCount);
  }

  function onDragStart(ev, data) {
    setDragOffset(dragNodeRef.current.offsetLeft);
  }

  function onDrag(ev, data) {
    let width =
      (dragOffset + data.x) / (dragNodeRef.current.parentNode.offsetWidth - 24);
    if (width < 0) {
      width = 0;
    } else if (width > 100) {
      width = 100;
    }
    setSelectedCount(
      Math.round(width * (totalCountItem - selectedCountBase)) +
        selectedCountBase,
    );
    const progress = width * 100 > 100 ? 100 : width * 100;
    setProgressBarActiveStyle(`${progress}%`);
  }

  function onDragStop(ev, data) {
    updateselectedCountWithRecalc(selectedCount);
  }

  function onProgressBarClick(ev) {
    if (ev.target !== dragAreaRef.current) {
      return;
    }

    const offsetWidth = dragBarRef.current.offsetWidth;
    const margin = (ev.target.offsetWidth - dragBarRef.current.offsetWidth) / 2;
    const left = ev.clientX - ev.target.getBoundingClientRect().left;
    const posX = left - margin;

    if (posX < 0) {
      if (posX >= -4) {
        updateselectedCountWithRecalc(0);
      }
      return;
    }
    if (posX > offsetWidth) {
      if (posX <= offsetWidth + 4) {
        updateselectedCountWithRecalc(totalCountItem);
      }
      return;
    }

    const ratio = posX / offsetWidth;
    updateselectedCountWithRecalc(Math.round(ratio * totalCountItem));
  }

  return (
    <>
      <div class="mt-2 flex items-center gap-2 px-2" ref={dragBarRef}>
        <img
          src={icoMinusWhite}
          alt=""
          onClick={() => {
            if (countItem > 1) props.setDragCount(countItem - 1);
          }}
        />
        <div class="relative h-1 w-full rounded-full bg-white">
          <div
            class={`h-1 w-[${progressBarActiveStyle}] rounded-s-full bg-black`}
          ></div>
          <div ref={dragAreaRef} onClick={onProgressBarClick}>
            <Draggable
              nodeRef={dragNodeRef}
              axis="x"
              bounds="parent"
              position={position}
              onStart={onDragStart}
              onDrag={onDrag}
              onStop={onDragStop}
            >
              <button
                style={{
                  width: "auto",
                  height: 30,
                  top: -14,
                  paddingTop: 10,
                  position: "absolute",
                  left: progressBarDragbarStyle,
                }}
                ref={dragNodeRef}
              >
                <div class="-ml-1 -mt-2.5 h-5 w-3 bg-black"></div>
              </button>
            </Draggable>
          </div>
        </div>
        <img
          src={icoPlusWhite}
          alt=""
          onClick={() => {
            if (countItem < totalCountItem) props.setDragCount(countItem + 1);
          }}
        />
      </div>
    </>
  );
}
