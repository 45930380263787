import React, { useState, useMemo } from "react";
import { idSorter } from "../util";

const ItemContext = React.createContext();

export function ItemProvider({ children }) {
  const [weapons, _setWeapons] = useState([]);
  const [armor, _setArmor] = useState([]);
  const [seeds, _setSeeds] = useState([]);

  const setWeapons = (newWeapons) => {
    _setWeapons(newWeapons.sort(idSorter));
  };
  const setArmor = (newArmor) => {
    _setArmor(newArmor.sort(idSorter));
  };
  const setSeeds = (newSeeds) => {
    _setSeeds(newSeeds.sort(idSorter));
  };

  const allItems = useMemo(() => {
    return [
      ...weapons.map((x) => {
        x.category = "weapons";
        return x;
      }),
      ...armor.map((x) => {
        x.category = "armor";
        return x;
      }),
      ...seeds.map((x) => {
        x.category = "seeds";
        return x;
      }),
    ];
  }, [weapons, armor, seeds]);

  const allItemsMap = useMemo(() => {
    const map = {};
    for (const item of allItems) {
      if (!map[item.category]) map[item.category] = {};
      map[item.category][item.token_id ? item.token_id : item.id] = item;
    }
    return map;
  }, [allItems]);

  function getItemById(id, category) {
    if (!category || id == null) return {};
    return allItemsMap[category][id];
  }

  const disposableItems = useMemo(() => {
    return [
      ...weapons.map((x) => {
        x.category = "weapons";
        return x;
      }),
      ...armor.map((x) => {
        x.category = "armor";
        return x;
      }),
    ];
  }, [weapons, armor]);

  // TODO test data
  function getGoodCompatibilityCharacters(item) {
    if (item.type === "Sword") return ["001", "005", "010"];
    if (item.type === "TwoHand") return ["004", "007", "011", "014", "015"];
    if (item.type === "Fists") return ["008", "012"];
    if (item.type === "Bow") return ["003", "009", "013"];
    if (item.type === "Staff") return ["002", "006"];
    if (item.type === "HeavyArmor")
      return ["001", "004", "007", "010", "012", "014"];
    if (item.type === "LightArmor")
      return ["003", "005", "008", "009", "011", "013", "015"];
    if (item.type === "Robe") return ["002", "006"];
    return [];
  }

  // [{itemId,count},...] 配列を元にアイテムを捨てる
  function disposeItemsByList(disposeItems) {
    const dispose = (array, itemId, count) => {
      for (const item of array) {
        if (item.id === itemId) {
          item.count -= count;
          return true;
        }
      }
      return false;
    };

    const cutoff = (item) => item.count > 0;

    const newWeapons = Array.from(weapons);
    const newArmor = Array.from(armor);
    const newSeeds = Array.from(seeds);
    const modified = {};

    for (const inst of disposeItems) {
      const item = getItemById(inst.itemId);
      switch (item.category) {
        case "weapons":
          if (dispose(newWeapons, inst.itemId, inst.count)) {
            modified.weapons = true;
          }
          break;
        case "armor":
          if (dispose(newArmor, inst.itemId, inst.count)) {
            modified.armor = true;
          }
          break;
        case "seeds":
          if (dispose(newSeeds, inst.itemId, inst.count)) {
            modified.seeds = true;
          }
          break;
        default:
          break;
      }
    }

    if (modified.weapons) {
      setWeapons(newWeapons.filter(cutoff));
    }
    if (modified.armor) {
      setArmor(newArmor.filter(cutoff));
    }
    if (modified.seeds) {
      setSeeds(newSeeds.filter(cutoff));
    }
  }

  return (
    <ItemContext.Provider
      value={{
        weapons,
        setWeapons,
        armor,
        setArmor,
        seeds,
        setSeeds,
        allItems,
        allItemsMap,
        disposableItems,
        getItemById,
        getGoodCompatibilityCharacters,
        disposeItemsByList,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}

export const ItemConsumer = ItemContext.Consumer;
export default ItemContext;
