import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  useApiContext,
  useFormationContext,
  useGameContext,
  useLangContext,
  useMapContext,
} from "../../../context";

import btn_close from "../../../images/btn_close.svg";
import seedIcon from "../../../images/item/seed.svg";
import shieldIcon from "../../../images/item/shield.svg";
import swordIcon from "../../../images/item/sword.svg";
import { ReactComponent as TreasureIcon } from "../../../images/ico_crystal_large.svg";

import { fragmentImageIds, mapIdToInfos } from "../../../data/regionInfo";
import settings from "../../../config/settings";
import ico_battle_result from "../../../images/ico_battle_result.svg";

export default function MapExplanation(props) {
  const { fragmentStolen } = props;
  const { langValue } = useLangContext();
  const { selectedMapObject } = useMapContext();
  const { normalizedFormation } = useFormationContext();
  const { nextBattleTimes } = useGameContext();
  const { account, Web3Api, chainId } = useApiContext();
  const navigate = useNavigate();

  const [myFragmentData, setMyFragmentData] = useState([]);

  const exploring = useMemo(() => {
    const characters = normalizedFormation.explore[selectedMapObject] ?? [];
    return characters.slice(0, 5);
  }, [normalizedFormation, selectedMapObject]);

  const nextGameTime = useMemo(() => {
    const elapsedTime =
      Number.parseInt(nextBattleTimes) - Math.round(Date.now() / 1000);
    const hour = Math.floor(elapsedTime / 3600);
    const min = Math.ceil((elapsedTime / 3600) * 60);
    if (elapsedTime < 0) {
      return "00:00";
    }
    return `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`;
  }, [nextBattleTimes]);

  const gameAvailable = useMemo(() => {
    const endTime = moment.unix(nextBattleTimes);
    if (endTime.isAfter(new Date())) {
      return false;
    }
    return true;
  }, [nextBattleTimes]);

  const mydata = useMemo(() => {
    return mapIdToInfos[selectedMapObject] ?? {};
  }, [selectedMapObject]);

  const fragmentIds = useMemo(() => {
    if (!mydata?.isTreasure) {
      return [];
    }
    return mydata.treasure.fragmentIds;
  }, [mydata]);

  useEffect(() => {
    if (fragmentIds.length) {
      (async () => {
        const fragments = await Web3Api.game.getMyFragment({
          account,
          address: settings.addresses.IsekaiBattleFragment,
          chainId: chainId,
          fragmentIds,
        });
        const _data = fragmentIds.map((id) => {
          const fragment = fragments.find((o) => o.id == id);
          return {
            id,
            exists: fragment,
          };
        });
        setMyFragmentData(_data);
      })();
    } else {
      setMyFragmentData([]);
    }
  }, [fragmentIds]);

  const onClickListEnemy = (fragment) => {
    if (fragment.exists) return;
    if (fragmentStolen.includes(fragment.id)) {
      props.removeFragmentStolen(fragment.id);
      navigate(`/battle/history/${fragment.id}`);
      return;
    }
    navigate(`/battle/${fragment.id}`);
  };

  return (
    <>
      <div className="z-index-2 absolute bottom-0  w-full rounded-t-2xl bg-regionModalBg px-3 py-6 text-white">
        <button
          onClick={() => props.setSelectedRegion(false)}
          className="absolute right-4 top-5"
        >
          <img src={btn_close} alt="" />
        </button>

        <h1 className="border-b border-white border-opacity-50 pb-1 text-xl">
          {langValue === "ja" && <>{mydata.name_ja}</>}
          {langValue === "en" && <>{mydata.name_en}</>}
        </h1>

        <div className="mt-4 text-sm leading-relaxed">
          {langValue === "ja" && <>{mydata.text_ja}</>}
          {langValue === "en" && <>{mydata.text_en}</>}
        </div>

        <div className="mt-4 border-t border-white border-opacity-50">
          <h2 className="mt-4 text-base">
            {langValue === "ja" && <>限定ドロップアイテム</>}
            {langValue === "en" && <>Exclusive Drop Item</>}
          </h2>
          <ul className="mt-2 flex gap-1">
            {mydata.rewards.sword && (
              <li class="bg-black p-2">
                <img src={swordIcon} alt="" />
              </li>
            )}
            {mydata.rewards.shield && (
              <li class="bg-black p-2">
                <img src={shieldIcon} alt="" />
              </li>
            )}
            {mydata.rewards.seed && (
              <li class="bg-black p-2">
                <img src={seedIcon} alt="" />
              </li>
            )}
            {mydata.rewards.treasure && (
              <li class="bg-black p-2">
                <TreasureIcon
                  style={{ height: "30px", width: "30px", fill: "white" }}
                />
              </li>
            )}
          </ul>
          <p className="mt-2 text-sm">
            {langValue === "ja" && (
              <>
                種を見つけるには3人以上のキャラクターを探索に出す必要があります。
              </>
            )}
            {langValue === "en" && (
              <>
                To find the item, you must have at least 3 Characters set for
                Exploration.
              </>
            )}
          </p>
        </div>

        <div className="mt-4 h-[90px] border-t border-white border-opacity-50">
          <h2 className="mt-4 text-base">
            {exploring.length > 0 ? (
              <>
                {langValue === "ja" && <>探索中</>}
                {langValue === "en" && <>Explore</>}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </h2>
          <div className="flex items-center justify-between">
            <ul className="flex">
              {exploring.map((char, key) => {
                return (
                  <li key={key}>
                    <img src={char.image} alt={char.name} className="w-14" />
                  </li>
                );
              })}
            </ul>
            <Link
              to={`/units#${mydata.id}`}
              className="rounded-full bg-black bg-opacity-20 p-2 px-6 text-sm"
            >
              {langValue === "ja" && <>編成</>}
              {langValue === "en" && <>Organization</>}
            </Link>
          </div>
        </div>

        <div className="mt-4 h-[90px] border-t border-white border-opacity-50">
          <h2 className="mt-4 flex justify-end text-base">
            {gameAvailable === false && (
              <>
                {langValue === "ja" && <>次のバトルまで</>}
                {langValue === "en" && <>Battle Cooldown </>}
                <span>{nextGameTime}</span>
              </>
            )}
          </h2>
          <ul className="mt-2 flex justify-center gap-1">
            {myFragmentData.map((fragment, key) => {
              return (
                <li
                  className={`stone ${fragmentStolen.includes(fragment.id) && !fragment.exists && "stone--battle-result"}`}
                  onClick={() => onClickListEnemy(fragment)}
                  key={key}
                >
                  <span>
                    {fragment.exists ? (
                      <img src={fragmentImageIds[fragment.id]} alt="" />
                    ) : (
                      <>
                        <img
                          src={fragmentImageIds[fragment.id]}
                          alt=""
                          class={`brightness-75 grayscale ${fragmentStolen.includes(fragment.id) && "absolute"}`}
                        />
                        {fragmentStolen.includes(fragment.id) && (
                          <img
                            src={ico_battle_result}
                            alt=""
                            class="z-1 relative"
                          />
                        )}
                      </>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
