import Footer from "./footer";
import * as utils from "../../util";

import logo from "../../images/toppage/logo.svg";
import ico_lock_white from "../../images/ico_lock_white.svg";
import ico_share_ios from "../../images/ico_share_ios.svg";
import ico_add_home_android from "../../images/ico_add_home_android.svg";
import { useLangContext } from "../../context";

export default function StartPwa() {
  const { langValue } = useLangContext();

  return (
    <div class="toppage relative h-full w-full">
      <div class="absolute top-1/3 w-full text-center">
        <p>
          <img src={logo} alt="Isekai Battle" class="mx-auto max-w-full" />
        </p>
      </div>
      <div class="absolute bottom-0">
        <div id="pwa-notification" className="relative">
          <div class="h-1/2 w-full rounded-tl-xl rounded-tr-xl bg-gray-800 p-4 text-center text-white">
            <img src="/images/characters/001.png" alt="" class="mx-auto w-14" />
            <p class="text-xs">
              <img
                src={ico_lock_white}
                alt=""
                class="-mt-0.5 mr-1 inline-block"
              />
              isekai-battle.xyz
            </p>

            <p class="mx-4 mt-4 text-sm">
              {langValue === "ja" && (
                <>
                  Isekai
                  Battleをプレイするには、下記の手順でホーム画面に追加してください
                </>
              )}
              {langValue === "en" && (
                <>
                  To play Isekai Battle, please add the app to your home screen
                  by following the steps below
                </>
              )}
            </p>

            <ol class="mx-auto ml-5 mt-4 inline-block list-decimal text-left text-sm">
              <li>
                {utils.isIos() ? (
                  <>
                    {langValue === "ja" && <>Safari でこのページを開く</>}
                    {langValue === "en" && <>Open this page in safari</>}
                  </>
                ) : (
                  <>
                    {langValue === "ja" && <>Chrome でこのページを開く</>}
                    {langValue === "en" && <>Open this page in chrome</>}
                  </>
                )}
              </li>
              <li>
                {utils.isIos() ? (
                  <>
                    {langValue === "ja" && <>下部のシェアボタン</>}
                    {langValue === "en" && <>Share button at the bottom Tap </>}
                    <img
                      src={ico_share_ios}
                      alt="share"
                      class="relative -top-1 inline-block w-4"
                    />
                    {langValue === "ja" && <>をタップ</>}
                  </>
                ) : (
                  <>
                    {langValue === "ja" && (
                      <>
                        Androidは右上の「︙」メニューから
                        <img
                          src={ico_add_home_android}
                          alt="share"
                          class="relative -top-1 inline-block w-4"
                        />
                        を使用してください
                      </>
                    )}
                    {langValue === "en" && (
                      <>
                        For Androids, pick
                        <img
                          src={ico_add_home_android}
                          alt="share"
                          class="relative -top-1 inline-block w-4"
                        />
                        from the top right 「︙」 menu
                      </>
                    )}
                  </>
                )}
              </li>
              <li>
                {langValue === "ja" && <>「ホーム画面に追加」を選択</>}
                {langValue === "en" && <>Select "Add to home screen"</>}
              </li>
              <li>
                {langValue === "ja" && <>ホーム画面からIsekai Battleを開く</>}
                {langValue === "en" && (
                  <>Open Isekai Battle from the home screen</>
                )}
              </li>
            </ol>
          </div>
        </div>

        <Footer customClass="relative" />
      </div>
    </div>
  );
}
