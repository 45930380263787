import { useMemo } from "react";
import { useLangContext } from "../../../../context";
import icoArrowBrown from "../../../../images/ico_arrow_brown.svg";

export default function ModalTrashConfirm(props) {
  const { langValue } = useLangContext();
  const { selectedItemTrash, itemCount, itemBag, selectedCategory } = props;

  const trashCount = useMemo(() => {
    return Object.entries(selectedItemTrash).reduce((sum, [, element]) => {
      return sum + Number(element.qty);
    }, 0);
  }, [selectedItemTrash]);

  return (
    <div class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-black bg-opacity-70 p-5">
      <div
        class="relative h-full w-full overflow-auto p-5"
        style={{
          background: "#eaeaea",
        }}
      >
        <h1 class="border-b border-black border-opacity-10 pb-3 pt-1 text-center text-2xl font-bold">
          {langValue === "ja" && <>確認</>}
          {langValue === "en" && <>Confirmation</>}
        </h1>
        <p class="mt-4 text-center">
          {langValue === "ja" && <>廃棄してよろしいですか？</>}
          {langValue === "en" && (
            <>Are you sure you want to discard these items?</>
          )}
        </p>

        <div class="mt-6">
          <ul class="mt-4 grid grid-cols-5 items-start gap-2">
            {Object.entries(selectedItemTrash).map(([, item], key) => {
              return (
                <li
                  class="relative rounded-lg bg-gradient-to-b from-[#C7C450] to-[#E5E4B4]"
                  key={key}
                >
                  <img src={item.image} alt="" class="w-18 -mt-2 pb-2" />
                  <div class="absolute bottom-0 right-0 rounded-full bg-black bg-opacity-80 px-2 text-xs text-white">
                    {item.qty}
                  </div>
                </li>
              );
            })}
          </ul>

          <div class="mt-6 border border-neutral-300 bg-cardBg">
            <div class="bg-itemBlue p-1 text-center text-sm text-white">
              {selectedCategory === "weapons" && (
                <>
                  {langValue === "ja" && <span>廃棄後の武器バッグ容量</span>}
                  {langValue === "en" && (
                    <span>Weapon bag capacity after discarding</span>
                  )}
                </>
              )}
              {selectedCategory === "armor" && (
                <>
                  {langValue === "ja" && <span>廃棄後の防具バッグ容量</span>}
                  {langValue === "en" && (
                    <span>Armor bag capacity after discarding</span>
                  )}
                </>
              )}
            </div>
            <div class="p-6 pt-4">
              <div class="mt-4 flex gap-2">
                <div class="flex-1 text-center">
                  <div class="text-4xl">
                    {Number(itemBag) - Number(itemCount)}
                  </div>
                </div>
                <div class="flex-shrink-0 flex-grow-0 self-center">
                  <img src={icoArrowBrown} alt="" class="mx-auto" />
                </div>
                <div class="flex-1 text-center">
                  <div class="text-4xl">
                    {Number(itemBag) - Number(itemCount) + trashCount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sticky bottom-0 z-10 mt-6 flex gap-2">
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onClose}
          >
            Cancel
          </button>
          <button
            class="block w-full rounded-full bg-buttonBg p-3 text-center text-lg tracking-widest text-white"
            onClick={props.onConfirm}
          >
            {langValue === "ja" && <>廃棄する</>}
            {langValue === "en" && <>Discard</>}
          </button>
        </div>
      </div>
    </div>
  );
}
