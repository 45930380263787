import React, { useState, useContext, useMemo, useCallback } from "react";
import MapContext from "./MapContext";
import ApiContext from "./ApiContext";
import CharacterContext from "./CharacterContext";
import { MapIdV1DagurCave, MapIdV1NoaForest } from "../constant";

const FormationContext = React.createContext();

function setupFormation(areaData) {
  const _formation = {
    attack: [null, null, null, null, null],
    defence: [null, null, null, null, null],
  };
  areaData.forEach((area) => {
    if (area.id === MapIdV1NoaForest || area.id === MapIdV1DagurCave) {
      // ノアの森 (v1) 、ダグー洞窟 (v1) は 15 体出撃可能だが、v1 と v2 で出撃ルールが違う
      _formation[area.id] = [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ];
    } else {
      // 他の region は 5 体出撃可能
      _formation[area.id] = [null, null, null, null, null];
    }
  });
  return _formation;
}

function _normalizeFormation(formation, characterMap) {
  const rv = {
    explore: {},
    attack: [],
    defence: [],
  };

  for (const [key, value] of Object.entries(formation)) {
    for (const id of value) {
      if (id === null) {
        continue;
      }
      const chara = characterMap[id];
      if (key === "attack" || key === "defence") {
        rv[key].push(chara);
      } else {
        if (!rv.explore[key]) {
          rv.explore[key] = [];
        }
        rv.explore[key].push(chara);
      }
    }
  }

  return rv;
}

function compareAttackDefenceBeforeAfter(array1, array2) {
  const result = false;
  const [long, short] =
    array1.length > array2.length ? [array1, array2] : [array2, array1];

  for (let i = 0; i < long.length; i++) {
    if (long[i] !== short[i]) {
      return true;
    }
  }

  return result;
}

function compareExploreBeforeAfter(areaData, beforeArray, afterArray) {
  const result = {};

  areaData.forEach((region) => {
    // 誰も探索していない場合
    if (
      beforeArray[region.id] === undefined &&
      afterArray[region.id] === undefined
    ) {
      return;
    }

    // 比較用配列を作成
    const beforeChars = [];
    const afterChars = [];

    if (beforeArray[region.id]?.length > 0) {
      beforeArray[region.id]?.forEach((char) => beforeChars.push(char.id));
    }

    if (afterArray[region.id]?.length > 0) {
      afterArray[region.id]?.forEach((char) => afterChars.push(char.id));
    }

    if (beforeChars?.sort().join() === afterChars?.sort().join()) {
      result[region.id] = false;
    } else {
      result[region.id] = true;
    }
  });

  return result;
}

export function FormationProvider({ children }) {
  const { areaData } = useContext(MapContext);
  const { user } = useContext(ApiContext);
  const { characterMap } = useContext(CharacterContext);

  const [formation, setFormation] = useState(() => setupFormation(areaData));
  const [savedFormation, setSavedFormation] = useState(() =>
    setupFormation(areaData),
  );

  const normalizedFormation = useMemo(() => {
    return _normalizeFormation(formation, characterMap);
  }, [formation, characterMap]);

  const normalizedSavedFormation = useMemo(() => {
    return _normalizeFormation(savedFormation, characterMap);
  }, [savedFormation, characterMap]);

  function loadFormation(_formation) {
    if (!_formation) {
      _formation = setupFormation(areaData);
    }
    setFormation(_formation);
    setSavedFormation(_formation);
  }

  function resetFormation() {
    loadFormation(null);
  }

  async function saveFormation() {
    await user.set("formation", formation);
    await user.save();
    setSavedFormation(formation);
  }

  function getFormationCharacter(group, index) {
    const characterId = formation[group][index];
    if (characterId === null) {
      return null;
    }
    return characterMap[characterId];
  }

  function setFormationCharacter(group, index, characterId) {
    if (characterId === undefined) {
      characterId = null;
    }
    const newFormation = {};
    for (const [key, value] of Object.entries(formation)) {
      newFormation[key] = value.map((value, i) => {
        if (key === group.toString() && i === index) {
          return characterId;
        } else if (value === characterId && value !== null) {
          return null;
        } else {
          return value;
        }
      });
    }
    setFormation(newFormation);
  }

  const getSavedFormationCharactersByAreaId = useCallback(
    (areaId) => {
      return savedFormation[areaId]
        ? savedFormation[areaId].map((characterId) => {
            if (characterId === null) {
              return null;
            } else {
              return characterMap[characterId];
            }
          })
        : null;
    },
    [savedFormation, characterMap],
  );

  const changedFormations = useMemo(() => {
    const comparedFormation = {};

    for (const key in normalizedFormation) {
      if (key === "explore") {
        const result = compareExploreBeforeAfter(
          areaData,
          normalizedSavedFormation[key],
          normalizedFormation[key],
        );
        comparedFormation[key] = result;
      } else {
        const result = compareAttackDefenceBeforeAfter(
          normalizedSavedFormation[key],
          normalizedFormation[key],
        );
        comparedFormation[key] = result;
      }
    }
    return comparedFormation;
  }, [areaData, normalizedFormation, normalizedSavedFormation]);

  return (
    <FormationContext.Provider
      value={{
        formation,
        setFormation,
        normalizedFormation,
        savedFormation,
        setSavedFormation,
        normalizedSavedFormation,
        loadFormation,
        resetFormation,
        saveFormation,
        getFormationCharacter,
        setFormationCharacter,
        getSavedFormationCharactersByAreaId,
        changedFormations,
      }}
    >
      {children}
    </FormationContext.Provider>
  );
}

export const FormationConsumer = FormationContext.Consumer;
export default FormationContext;
