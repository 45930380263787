import { useState } from "react";
import ItemWeapon from "./components/item-weapon";
import { useLangContext } from "../../../context";
import ItemExtend from "./components/item-extend";

export default function Weapon(props) {
  const { selectedCategory, itemBagQty } = props;
  const { langValue } = useLangContext();

  const [extendItem, setExtendItem] = useState(false);

  return (
    <>
      {!extendItem && (
        <div class="relative flex flex-1 flex-col overflow-hidden bg-neutral-100">
          <div class="bg-gradient-to-r from-[#648B93] to-[#6F8A93] p-3 text-xl tracking-wider text-white">
            {langValue === "ja" && <>アイテム</>}
            {langValue === "en" && <>Items</>}
          </div>

          <div class="flex flex-col overflow-hidden">
            <ul class="flex">
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "weapons" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("weapons")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>武器</>}
                    {langValue === "en" && <>Weapons</>}
                  </div>
                </button>
              </li>
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "armor" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("armor")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>防具</>}
                    {langValue === "en" && <>Armor</>}
                  </div>
                </button>
              </li>
              <li class="grow">
                <button
                  class={`w-full p-3 ${selectedCategory !== "seeds" && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
                  onClick={() => props.setSelectedCategory("seeds")}
                >
                  <div class="text-base">
                    {langValue === "ja" && <>種</>}
                    {langValue === "en" && <>Seeds</>}
                  </div>
                </button>
              </li>
            </ul>
            <div class="overflow-scroll p-4 text-black">
              <ItemWeapon
                selectedCategory={selectedCategory}
                setExtendItem={setExtendItem}
                itemBagQty={itemBagQty}
              />
            </div>
          </div>
        </div>
      )}
      {extendItem && (
        <ItemExtend setExtendItem={setExtendItem} itemBagQty={itemBagQty} />
      )}
    </>
  );
}
