import React, { useState, useMemo } from "react";
import { ethers } from "ethers";
import { SiweMessage } from "siwe";
import { useCookies } from "react-cookie";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3Modal,
  useDisconnect,
} from "@web3modal/ethers5/react";
import settings from "../config/settings";
import Web3 from "web3/dist/web3.min";

const ApiContext = React.createContext();

export function ApiProvider({ children, url }) {
  const apiUrl = useMemo(() => url || "/api", [url]);

  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);
  const [account, setAccount] = useState(cookies.address);
  const [ens, setEns] = useState();
  const [username, setUsername] = useState();

  const [provider, setProvider] = useState();
  const [isWeb3Enabled, setIsWeb3Enabled] = useState(false);
  const [isWeb3EnableLoading, setIsWeb3EnableLoading] = useState(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!cookies.address);
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();

  function _setAddress(address, ens) {
    setAccount(address);
    setEns(ens);
    setUsername(ens || address);
    setCookie("address", address);
    setCookie("ens", ens || "");
    setIsAuthenticated(true);
    setIsAuthInitialized(true);
  }

  function _clearAddress() {
    removeCookie("address");
    removeCookie("ens");
    setAccount(undefined);
    setEns(undefined);
    setUsername(undefined);
    setIsAuthenticated(false);
    setIsAuthInitialized(true);
  }

  if (!process.env.REACT_APP_CHAIN_ID) {
    throw new Error("missing target chain id");
  }
  const targetChainId = Number(process.env.REACT_APP_CHAIN_ID);

  async function jsonResponse(res) {
    if (!res) {
      throw new Error("missing response");
    }
    if (res.status === 200) {
      return await res.json();
    } else if (res.status === 401) {
      _clearAddress();
      throw new Error("unauthorized");
    } else {
      try {
        const err = await res.json();
        throw new Error(err);
      } catch (err) {
        throw new Error(`http: ${res.status}`);
      }
    }
  }

  async function switchNetwork(_chainId) {
    const web3provider = new ethers.providers.Web3Provider(walletProvider);
    _chainId = `0x${Number(_chainId).toString(16)}`;
    await web3provider.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: _chainId }],
    });
  }

  async function enableWeb3() {
    if (!walletProvider) return;
    setIsWeb3EnableLoading(true);
    try {
      await switchNetwork(targetChainId);
      const web3provider = new ethers.providers.Web3Provider(walletProvider);
      const provider = web3provider.provider;

      setProvider(provider);
      setIsWeb3Enabled(true);

      setIsWeb3EnableLoading(false);
      return { web3provider, provider };
    } catch (err) {
      setIsWeb3EnableLoading(false);
      throw err;
    }
  }

  async function authenticate() {
    if (isConnected) {
      let status = false;
      try {
        status = await _authenticate();
      } catch (error) {
        console.error(error);
      }
      if (!status) {
        open();
      }
      return;
    }
    open();
  }

  async function _authenticate() {
    if (!walletProvider) return;
    const { web3provider, provider } = await enableWeb3();
    const { chainId } = await web3provider.getNetwork();
    const checksumAddress = ethers.utils.getAddress(address);

    let ens;
    try {
      ens = await provider.lookupAddress(checksumAddress);
    } catch (err) {
      // console.error(err);
    }

    let res = await fetch(`${apiUrl}/auth/refresh`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chainId,
        address: checksumAddress,
        ens,
      }),
    });

    if (res.status === 200) {
      _setAddress(checksumAddress, ens);
      return true;
    }

    res = await fetch(`${apiUrl}/auth/nonce`, {
      credentials: "include",
    });
    if (res.status !== 200) {
      throw new Error(`http: ${res.status}`);
    }
    const nonce = await res.text();

    const message = new SiweMessage({
      domain: document.location.host,
      address: checksumAddress,
      chainId,
      uri: document.location.origin,
      version: "1",
      statement: "Login to Isekai Battle",
      nonce,
    });

    const signature = await web3provider
      .getSigner()
      .signMessage(message.prepareMessage());

    res = await fetch(`${apiUrl}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ message, ens, signature }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const { address: _address, ens: _ens } = await jsonResponse(res);
    _setAddress(_address, _ens);
    return true;
  }

  async function logout() {
    await fetch(`${apiUrl}/auth/logout`, {
      method: "POST",
      credentials: "include",
    });
    _clearAddress();
    try {
      await disconnect();
    } catch (error) {
      console.error(error);
    }
    window.location = "/";
  }

  async function refreshAuth() {
    if (!cookies.address) return;
    const checksumAddress = ethers.utils.getAddress(cookies.address);
    const res = await fetch(`${apiUrl}/auth/refresh`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chainId: Number(settings.defaultChainId),
        address: checksumAddress,
      }),
    });

    if (res.status === 401) {
      logout();
    }
  }

  const user = useMemo(() => {
    if (!account) {
      return null;
    }

    return {
      account,
      ens,
      name: username,
      async get(key) {
        const res = await fetch(`${apiUrl}/auth/getvalue`, {
          method: "POST",
          body: JSON.stringify({ key }),
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        const json = await jsonResponse(res);
        const { value } = json;
        return value;
      },
      async getValues(keys) {
        const res = await fetch(`${apiUrl}/auth/getvalues`, {
          method: "POST",
          body: JSON.stringify({ keys }),
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        const json = await jsonResponse(res);
        const { values } = json;
        return values;
      },
      async set(key, value) {
        const res = await fetch(`${apiUrl}/auth/setvalue`, {
          method: "POST",
          body: JSON.stringify({ key, value }),
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        await jsonResponse(res);
      },
      async setValues(keys, values) {
        const res = await fetch(`${apiUrl}/auth/setvalues`, {
          method: "POST",
          body: JSON.stringify({ keys, values }),
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        await jsonResponse(res);
      },
      save() {},
      async log(data) {
        if (!data) {
          data = {};
        }
        try {
          const res = await fetch(`${apiUrl}/auth/log`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          });
          await jsonResponse(res);
        } catch (err) {
          // skip errors
          console.error(err);
        }
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, ens, username]);

  async function getNFTsForContract(params) {
    const res = await fetch(`${apiUrl}/nft/getNFTsForContract`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getTokenIdMetadata(params) {
    const res = await fetch(`${apiUrl}/nft/getTokenIdMetadata`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getBatchTokenIdMetadata(params) {
    const res = await fetch(`${apiUrl}/nft/getBatchTokenIdMetadata`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function reSyncMetadata(params) {
    const res = await fetch(`${apiUrl}/nft/reSyncMetadata`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getListEnemies(params) {
    const res = await fetch(`${apiUrl}/game/getListEnemies`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getGameMyData(params) {
    const res = await fetch(`${apiUrl}/game/getGameMyData`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getMyFragment(params) {
    const res = await fetch(`${apiUrl}/game/getMyFragment`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function usedSeed(params) {
    const res = await fetch(`${apiUrl}/game/usedSeed`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function gameMatched(params) {
    const res = await fetch(`${apiUrl}/game/gameMatched`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getGameRecord(params) {
    const res = await fetch(`${apiUrl}/game/getGameRecord`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getBatchUserImage(params) {
    const res = await fetch(`${apiUrl}/game/getBatchUserImage`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getSoldSinkies(payload) {
    const res = await fetch(`${apiUrl}/game/getSoldSinkies`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function createSoldSinki(payload) {
    const res = await fetch(`${apiUrl}/game/createSoldSinki`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getEvents() {
    const res = await fetch(`${apiUrl}/game/getEvents`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getRanks() {
    const res = await fetch(`${apiUrl}/game/getRanks`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function getBanners() {
    const res = await fetch(`${apiUrl}/game/getBanners`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  async function totalSupplies(payload) {
    const res = await fetch(`${apiUrl}/game/totalSupplies`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const json = await jsonResponse(res);
    return json;
  }

  const Web3Api = {
    token: {
      getTokenIdMetadata,
      getBatchTokenIdMetadata,
      reSyncMetadata,
    },
    account: {
      getNFTsForContract,
      usedSeed,
    },
    game: {
      getListEnemies,
      getGameMyData,
      getMyFragment,
      gameMatched,
      getGameRecord,
      getBatchUserImage,
      getSoldSinkies,
      createSoldSinki,
      getEvents,
      getRanks,
      getBanners,
      totalSupplies,
    },
  };

  function chainName(chainId) {
    switch (chainId) {
      case 1:
        return "mainnet";
      case 5:
        return "goerli";
      case 1337:
        return "dev";
      case 31337:
        return "hardhat";
      case 10:
        return "OP Mainnet";
      case 11155420:
        return "sepolia-optimistic";
      default:
        return "";
    }
  }

  function chainLabel(chainId) {
    switch (chainId) {
      case 1:
        return "イーサリアムメインネット";
      default:
        return chainName(chainId);
    }
  }

  async function getFeeData() {
    const baseFeePerGas = 1;
    const maxPriorityFeePerGas = 0.1;
    const maxFeePerGas = (baseFeePerGas + maxPriorityFeePerGas).toFixed(2);
    function executionGasFee(estimateGas) {
      return Math.round(estimateGas + estimateGas * maxFeePerGas);
    }

    return {
      maxFeePerGas: Web3.utils.toWei(`${maxFeePerGas}`, "gwei"),
      maxPriorityFeePerGas: Web3.utils.toWei(`${maxPriorityFeePerGas}`, "gwei"),
      executionGasFee,
    };
  }

  return (
    <ApiContext.Provider
      value={{
        account,
        ens,
        user,
        username,
        isWeb3Enabled,
        isWeb3EnableLoading,
        provider,
        enableWeb3,
        isAuthInitialized,
        isAuthenticated,
        authenticate,
        logout,
        Web3Api,
        chainId,
        switchNetwork,
        targetChainId,
        chainName,
        chainLabel,
        refreshAuth,
        getFeeData,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}

export const ApiConsumer = ApiContext.Consumer;
export default ApiContext;
