import emptyDarkStar from "../../../../images/ico_star_empty_dark.svg";
import darkStar from "../../../../images/ico_star_fill_dark.svg";
import emptyLightStar from "../../../../images/ico_star_empty_light.svg";
import lightStar from "../../../../images/ico_star_fill_light.svg";
import { useFormationContext } from "../../../../context";

export default function ButtonTab(props) {
  const { formation } = useFormationContext();

  function stars() {
    let stared = 0;
    if (props.name === "attack") {
      const attacks = [...formation.attack.filter((x) => x)];
      stared = attacks.length;
    } else if (props.name === "defence") {
      const defences = [...formation.defence.filter((x) => x)];
      stared = defences.length;
    } else {
      const explores = [
        ...formation.dagur_cave.filter((x) => x),
        ...formation.ordina_planes.filter((x) => x),
      ];
      stared = explores.length > 5 ? 5 : explores.length;
    }
    return Array.from({ length: 5 }, (_, k) => k).map((index) => {
      return {
        active: Math.floor(stared) > index,
      };
    });
  }

  return (
    <button
      class={`w-full p-3 ${!props.active && "bg-gradient-to-b from-[#4F4F4F] to-[#2b2b2b] text-white"}`}
      onClick={() => props.setCurrentTab(props.name)}
    >
      <div class="text-xl">{props.label}</div>
      {props.active ? (
        <div class="mt-1 flex justify-center gap-0.5">
          {stars().map((o, key) => {
            return o.active ? (
              <img src={darkStar} alt="" width="5" key={key} />
            ) : (
              <img src={emptyDarkStar} alt="" width="5" key={key} />
            );
          })}
        </div>
      ) : (
        <div class="mt-1 flex justify-center gap-0.5">
          {stars().map((o, key) => {
            return o.active ? (
              <img src={lightStar} alt="" width="5" key={key} />
            ) : (
              <img src={emptyLightStar} alt="" width="5" key={key} />
            );
          })}
        </div>
      )}
    </button>
  );
}
