import { useState } from "react";
import {
  useApiContext,
  useLangContext,
  useCharacterContext,
} from "../../context";
import { sound_effect } from "../common/SoundEffect";
import settings from "../../config/settings";

import btnClose from "../../images/btn_close.svg";
import icoMypage from "../../images/ico_mypage.png";

export default function MyPageChangeProfile(props) {
  const { langValue } = useLangContext();
  const { characters } = useCharacterContext();
  const [loading, setLoading] = useState(false);
  const { account, user } = useApiContext();

  let characterList = [];
  characterList = makeList(true);

  function makeList() {
    characterList = [];
    characterList.push(
      <li
        class="rounded bg-gradient-to-br from-[#88814B] via-[#CDC986] to-[#88814B] p-0.5"
        onClick={() =>
          acceptClick({
            id: 0,
            image: icoMypage,
            contract: "0x",
          })
        }
        key={"default"}
      >
        <div class="flex items-center justify-center rounded-sm bg-gradient-to-b from-[#8A834F] via-[#DBD499] to-[#EFEDD7]">
          <img src={icoMypage} alt="" class="h-62 w-18 object-cover" />
        </div>
      </li>,
    );

    for (let i = 0; i < characters.length; i++) {
      characterList.push(
        <li
          class="rounded bg-gradient-to-br from-[#88814B] via-[#CDC986] to-[#88814B] p-0.5"
          onClick={() =>
            acceptClick({
              ...characters[i],
              contract: settings.addresses.IsekaiBattle,
            })
          }
          key={i}
        >
          <div class="flex items-center justify-center rounded-sm bg-gradient-to-b from-[#8A834F] via-[#DBD499] to-[#EFEDD7]">
            <img
              src={characters[i].image}
              alt=""
              class="h-62 w-18 object-cover"
            />
          </div>
        </li>,
      );
    }
    return characterList;
  }

  function cancelClick() {
    sound_effect("negative");
    props.onClose();
  }

  async function acceptClick(chara) {
    if (loading) return;
    setLoading(true);
    sound_effect("positive");
    props.setUserImage(chara.image);
    await user.set("userImage", {
      user: account,
      contractAddress: chara.contract,
      tokenId: chara.id,
    });
    await user.save();
    setLoading(false);
    props.onClose();
  }

  return (
    <>
      <div class="absolute z-[101] flex h-[100%] w-[100%] flex-1 flex-col">
        <div class="z-5 left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-70">
          <div class="mypage relative h-full w-full p-6 pt-20 text-white">
            <div className="flex">
              <div class="text-2xl">
                {langValue === "ja" && <>アイコンを変更する</>}
                {langValue === "en" && <>Change Icon</>}
              </div>
              <button class="absolute right-4" onClick={cancelClick}>
                <img src={btnClose} alt="" />
              </button>
            </div>
            <div class="relative h-full w-full overflow-auto pb-5 pt-5">
              <div class="flex flex-col gap-10">
                <ul class="grid grid-cols-5 items-start gap-2">
                  {characterList}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
