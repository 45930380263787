import { useState } from "react";
import { useApiContext, useLangContext, useVolumeContext } from "../../context";
import VolumnDrag from "../common/VolumnDrag";
import MyPageChangeProfile from "./my-page-change-profile";

import btnClose from "../../images/btn_close.svg";
import icoVolume from "../../images/ico_volume.svg";
import NotificationSetting from "./notification-setting";

export default function MyPage(props) {
  const {
    bgmVolume,
    seVolume,
    changeBgmVolume,
    changeSeVolume,
    isMute,
    changeMute,
  } = useVolumeContext();
  const { user } = useApiContext();
  const { langValue, setMyLanguage } = useLangContext();
  const [changeProfile, setChangeProfile] = useState(false);

  function setBgmVolumeFunc(num) {
    try {
      changeBgmVolume(num);
      if (isMute) {
        changeMute(false);
      }
    } catch (error) {
      console.error(error);
      alert("update failed");
    }
  }

  function setSeVolumeFunc(num) {
    try {
      changeSeVolume(num);
      if (isMute) {
        changeMute(false);
      }
    } catch (error) {
      console.error(error);
      alert("update failed");
    }
  }

  function selectLang(e) {
    setMyLanguage(e.target.value);
    user.set("language", e.target.value);
  }

  return (
    <>
      <div class="mypage z-[100] h-full w-full overflow-auto p-6 pt-20 text-white">
        <div class="text-2xl">
          {langValue === "ja" && <>マイページ</>}
          {langValue === "en" && <>My Page</>}
        </div>
        <button class="absolute right-4 top-20" onClick={props.onClose}>
          <img src={btnClose} alt="" />
        </button>

        <div class="mt-10 flex items-center gap-6">
          <div>
            <img
              src={props.userImage}
              alt=""
              class="w-20"
              onClick={() => setChangeProfile(true)}
            />
          </div>
          <div>
            <div class="text-2xl">{props.userName}</div>
            <div class="mt-1 w-60 break-all text-sm">{props.account}</div>
          </div>
        </div>

        <div class="mt-10 border-t border-gray-300 pt-10">
          <div class="grid grid-cols-[min-content_auto] gap-4 gap-y-10">
            <div class="whitespace-nowrap">Language</div>
            <div class="text-right">
              <select
                onChange={selectLang}
                defaultValue={langValue}
                class="bg-transparent"
              >
                <option value="ja">日本語</option>
                <option value="en">English</option>
              </select>
            </div>
            <div class="whitespace-nowrap">BGM</div>
            <div>
              <div class="mt-2 flex items-center gap-2 px-2">
                <img src={icoVolume} alt="" />
                <VolumnDrag
                  setDragCount={setBgmVolumeFunc}
                  volumeNumber={bgmVolume}
                />
              </div>
            </div>
            <div class="whitespace-nowrap">
              {langValue === "ja" && <>効果音</>}
              {langValue === "en" && <>SFX</>}
            </div>
            <div>
              <div class="mt-2 flex items-center gap-2 px-2">
                <img src={icoVolume} alt="" />
                <VolumnDrag
                  setDragCount={setSeVolumeFunc}
                  volumeNumber={seVolume}
                />
              </div>
            </div>
          </div>
        </div>
        <NotificationSetting />
      </div>
      {changeProfile && (
        <MyPageChangeProfile
          setUserImage={props.setUserImage}
          onClose={() => setChangeProfile(false)}
        />
      )}
    </>
  );
}
